import { Checkbox } from 'antd';
import { useState, useEffect } from 'react';

const CustomizationCheckbox = ({ team_permission }) => {
  const [checked, setChecked] = useState(team_permission);

  useEffect(() => {
    setChecked(team_permission);
  }, [team_permission]);

  return (
    <div className="text-left">
      <Checkbox checked={checked} onChange={() => setChecked(!checked)} />
    </div>
  );
};

export default CustomizationCheckbox;

