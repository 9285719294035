
const config = {
    // Pro:

    apiUrl: process.env.REACT_APP_API_URL || 'http://127.0.0.1:8000/api/', // Default to production API if not set
    clientId: '660970705625-l3vva2ba4e4sot6v3pjoui16sje5hjhj.apps.googleusercontent.com',
    mapKey:'AIzaSyAxFqSrDHfOa3Fitprtj6mjyenQj7U_WAs',

    debugMode: true,
  };
  console.log(`Running in ${config.environment} mode.`);

  export default config;


