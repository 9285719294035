import React, { Component } from 'react';
import { UserOutlined, LockOutlined, BellOutlined, WechatWorkOutlined, CreditCardOutlined,BookOutlined } from '@ant-design/icons';
import { Menu, Row, Col } from 'antd';
import EditProfile from './User/EditProfile';
import Sidebar from './components/Sidebar';
import Navbar from './components/Navbar';
import CustomLayout from '../layouts/Customlayout';
import './User/Css/AccountSettings.css'; // Import the CSS file with responsive styles
import { withTranslation, useTranslation } from 'react-i18next';
import SettingOption from './User/components/MenuItems';
import EmailLogo from './User/EmailLogo';
import './../translations/translations.js';

export class EmailChatSummaryLogo extends Component {
  render() {
    const { t } = this.props;    
    // Retrieve the UserObject from localStorage and parse it
    const UserInfo = JSON.parse(localStorage.getItem('UserObject'));
    // Determine if the mode is 'shopify'
    const shopify = UserInfo?.mode === 'shopify';


    return (
      <CustomLayout>
        <div className="container">
          <Row gutter={[16, 16]} className="flex-container">
            <Col xs={24} sm={24} md={8} className="menu">
              <SettingOption shopify={shopify} />
            </Col>
            <Col xs={24} sm={24} md={16} className="content">
              <h1 style={{ fontSize: '20px', fontWeight: 'bold' }}>{ t('accountSetting.emailSummary.heading')}</h1>
              <p>{ t('accountSetting.emailSummary.subHeading')}</p>
              <EmailLogo />
            </Col>
          </Row>
        </div>
      </CustomLayout>
    );
  }
}

export default withTranslation()(EmailChatSummaryLogo);
