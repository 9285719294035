import React, { Component } from 'react';
import { Avatar, Drawer, Divider, Select } from 'antd';
import Spinner from './components/Spinner';
import { useTranslation } from 'react-i18next';
import dayjs from 'dayjs';
import config from '../config';

const { Option } = Select;

export class ChatView extends Component {
	state = {
		spinAssign: false,
		spinSession: false,
		data: {}
	};

	// Function to format the date to a readable format
	formatDate = (date) => {
		return date ? dayjs(date).format('MMMM D, YYYY, h:mm A') : 'N/A';
	};

	handleUpdateAssignee = async (selectedUser, session_id) => {
		this.setState({ spinAssign: true });

		try {
			const response = await fetch(`${config.apiUrl}update-assignee/`, {
				method: 'POST',
				headers: { 'Content-Type': 'application/json' },
				body: JSON.stringify({ user_session_id: session_id, assignee: selectedUser })
			});

			if (response.ok) {
				const newData = await response.json();
				this.props.onUpdateData(newData);
			} else {
				console.error('Failed to update assignee');
			}
		} catch (error) {
			console.error('Error during API request:', error);
		} finally {
			this.setState({ spinAssign: false });
		}
	};

	handleUpdateSession = async (selectedAnswer, session_id) => {
		if (selectedAnswer === "Yes") {
			this.setState({ spinSession: true });

			try {
				const response = await fetch(`${config.apiUrl}update-session/`, {
					method: 'POST',
					headers: { 'Content-Type': 'application/json' },
					body: JSON.stringify({ user_session_id: session_id })
				});

				if (response.ok) {
					const newData = await response.json();
					this.props.onUpdateData(newData);
				}
			} catch (error) {
				console.error('Error during API request:', error);
			} finally {
				this.setState({ spinSession: false });
			}
		}
	};

	render() {
		const { data, visible, close, team, i18n } = this.props;
		const placement = i18n.language === 'ar' ? 'left' : 'right';

		return (
			<Drawer
				width={350}
				placement={placement}
				onClose={close}
				closable={false}
				open={visible}
			>
				{/* Customer Info */}
				<div className="flex flex-col items-center justify-center mt-6">
					<Avatar size={80} src={data?.avatar || "data:image/png;base64,..."} />
					<h2 className="mt-3 text-lg font-semibold">{data?.name || 'Guest'}</h2>
				</div>

				<Divider dashed />

				{/* Contact Details */}
				<ul className='w-full px-6'>
					<li className='flex justify-between mb-4'>
						<h3 className='font-medium'>Contact No:</h3>
						<span>{data?.phone || 'N/A'}</span>
					</li>
					<li className='flex justify-between mb-4'>
						<h3 className='font-medium'>Email:</h3>
						<span>{data?.mail || 'N/A'}</span>
					</li>
					<li className='flex justify-between mb-4'>
						<h3 className='font-medium'>Location:</h3>
						<span>{data?.country || 'N/A'}</span>
					</li>
					<li className='flex justify-between mb-4'>
						<h3 className='font-medium'>Opened:</h3>
						<span>{this.formatDate(data?.date)}</span>
					</li>
				</ul>

				<Divider dashed />

				{/* Assignee Section */}
				<div className='flex flex-col items-center mb-6 px-6'>
					<h3 className="font-semibold mb-3">{data.assigned ? 'Assigned to Team' : 'Assign to Team'}</h3>
					{this.state.spinAssign ? (
						<Spinner />
					) : (
						<Select
							placeholder="Select User"
							style={{ width: '100%' }}
							onChange={(value) => this.handleUpdateAssignee(value, data?.user_session_id)}
							disabled={data.assigned}
						>
							{team.filter(member => member.created).map((member, id) => (
								<Option value={member.email} key={id}>
									{member.email}
								</Option>
							))}
						</Select>
					)}
				</div>

				{/* Session Status Section */}
				<div className='flex flex-col items-center px-6'>
					<h3 className="font-semibold mb-3">{data.closed ? 'Session Closed' : 'Close Session'}</h3>
					{this.state.spinSession ? (
						<Spinner />
					) : (
						<Select
							placeholder="Choose status"
							style={{ width: '100%' }}
							onChange={(value) => this.handleUpdateSession(value, data?.user_session_id)}
							disabled={data.closed}
						>
							<Option value="No">No</Option>
							<Option value="Yes">Yes</Option>
						</Select>
					)}
				</div>
			</Drawer>
		);
	}
}

export default function ChatViewWrapper(props) {
	const { t, i18n } = useTranslation();
	return <ChatView {...props} t={t} i18n={i18n} />;
}
