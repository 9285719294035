import React, { useState, useEffect, useRef } from 'react';
import './Css/Messaging.css'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faSearch } from '@fortawesome/free-solid-svg-icons';
import { Link, useNavigate, useParams } from 'react-router-dom';
import { FiPhone } from "react-icons/fi";
import { FiVideo } from "react-icons/fi";
import { HiOutlineDotsVertical } from "react-icons/hi";
import { FaFilter } from "react-icons/fa";
import { IoMdAdd } from "react-icons/io";
import { RiVerifiedBadgeFill } from "react-icons/ri";
import { MdEmojiEmotions } from "react-icons/md";
import Dropdown from 'react-bootstrap/Dropdown';
import { Modal, Button, InputNumber } from 'antd'
import axios from 'axios';
import config from '../config';




export default function Chatsidebar({ handleScreen , onStateChange, messageList }) {
    const [isModalOpen, setIsModalOpen] = useState(false);
    const [numberValue, setNumberValue] = useState(0);
    const [chatLogs, setChatLogs] = useState([])
    const [noChats, setNoChats] = useState(true)

    const navigate = useNavigate();

    const chatLogsManaged = () =>{
        const headers = {
            headers: {
                'Content-Type': 'application/json',
                'Authorization': `JWT ${JSON.parse(localStorage.getItem('Userdata')).token}`,
                'Accept': 'application/json'
            }
        };

        axios.get(`${config.apiUrl}whatsapp-chatlogs/`, headers).then((res)=>{
            setChatLogs(res.data)
            console.log(res.data);
            setNoChats(!chatLogs)
        })
    }

    useEffect(() => {
        console.log('herererere');
        chatLogsManaged()
    }, [messageList])


    const showModal = () => {
        setIsModalOpen(true);
    };

    const handleOk = () => {

        let formData = new FormData();
        formData.append('phone_number', numberValue);
        formData.append('from_bot', true);

        const headers = {
            headers: {
                'Content-Type': 'application/json',
                'Authorization': `JWT ${JSON.parse(localStorage.getItem('Userdata')).token}`,
                'Accept': 'application/json'
            }
        };

        axios.post(`${config.apiUrl}whatsapp-chatlogs/`, formData, headers).then((res)=>{
            chatLogsManaged()
        })

        setIsModalOpen(false);
    };
    const handleCancel = () => {
        setIsModalOpen(false);
    };
    const handleNumberChange = (value) => {
        setNumberValue(value);
    };


    const handleUserSession = (user_session_id) => {
        // Call the parent's callback function with the new state
        onStateChange(user_session_id);
    };
    


    return (
        <div>
            <div className='left_wrapper'>
                <div className="chatSearch">
                    <FontAwesomeIcon icon={faSearch} className="search-icon" />
                    <input type="text" placeholder="Search" className='input_search_chat' />
                </div>
                <div className='custom_border-active'>
                    <div className='cus_chat_wrapper flex'>
                        <div className='cus_content'>
                            <h2 className='Active-chat-text'>Active Chats</h2>
                            <h2 className='Active-chat-no'>0 Chats <span>0 Unread</span></h2>
                        </div>
                        <div className='chat-btn flex'>
                            <button class="circle-icon-btn gray_btn">
                                <FaFilter className='filterImg' />

                            </button>
                            <div>
                                <button className="circle-icon-btn add-btn" onClick={showModal}>
                                    <IoMdAdd className='addCls' />
                                </button>
                                <Modal
                                    open={isModalOpen}
                                    onOk={handleOk}
                                    onCancel={handleCancel}>

                                    <p className='Enter-number-text'>Enter a number:</p>
                                    <InputNumber value={numberValue} onChange={handleNumberChange} className='Input-number' />
                                </Modal>
                            </div>

                        </div>
                    </div>
                </div>

                {
                    noChats ? <h2 className='nc-found-text'>No Chats Found</h2> :

                        <Link to="/integration/chatscreen">

                            <ul onClick={() => handleScreen()} >
                                {
                                    chatLogs.map((chat, index) => (
                                        <div className='change-clr'>
                                            <li key={index}>
                                                <a onClick={() => handleUserSession(chat)}>
                                                    <div className='custom_border chat-li sideMsg-padding'>
                                                        <div className='sidemessage flex'>
                                                            <div className='sideprofile'>
                                                                <img alt='' />
                                                            </div>
                                                            <div>
                                                                <div className='profile_Verified flex'>
                                                                    <h2 className='profile_name_text'>{chat.phone_number}</h2>
                                                                    <RiVerifiedBadgeFill />

                                                                    {/* <ChatScreen /> */}

                                                                </div>


                                                                <span className='msgText'>{chat.last_message}</span>
                                                            </div>

                                                        </div>
                                                    </div>
                                                </a>
                                            </li>
                                        </div>


                                    ))
                                }
                            </ul>

                        </Link>
                }


            </div>
        </div>
    )
}