import React, { useState, useEffect } from 'react';
import config from '../../config';
import axios from 'axios';
import { message, Progress } from 'antd';

export default function Success() {
  const [isLoggedIn, setIsLoggedIn] = useState(false);
  const [progress, setProgress] = useState(0); // Initialize progress state
  const [sessionId, setSessionId] = useState(null); // State to store session_id

  function setCheckoutID(){
    const params = new URLSearchParams(window.location.search);
    localStorage.setItem('checkout_session_id',params.get('session_id'))
  }

  useEffect(() => {

    setCheckoutID()
    handleSignupOnSuccessPage();
  }, []);

  // Function to handle signup on success page
  async function handleSignupOnSuccessPage() {
    try {
      setProgress(10); // Update progress
      // Retrieve the saved form data from local storage
      const savedFormData = localStorage.getItem('signupFormData');
      if (!savedFormData) {
        throw new Error('No form data found');
      }

      // Parse the saved form data
      const formData = new FormData();
      const parsedData = JSON.parse(savedFormData);

      // Append each key-value pair to FormData
      await Promise.all(parsedData.map(async ([key, value]) => {
        if (key === 'image') {
          // If the key is 'image', convert base64 string to Blob
          const response = await fetch(value);
          const blob = await response.blob();
          const file = new File([blob], "uploaded_image.jpg", { type: blob.type });
          formData.append(key, file);
        } else {
          formData.append(key, value);
        }
      }));

      formData.append('checkout_session_id', localStorage.getItem('checkout_session_id'));

      setProgress(30); // Update progress
      // Make the signup API request
      const response = await axios.post(`${config.apiUrl}user/signup`, formData, {
        headers: {
          'Content-Type': 'multipart/form-data',
        },
        onUploadProgress: progressEvent => {
          setProgress(Math.round((progressEvent.loaded / progressEvent.total) * 100 * 0.5) + 30); // Update progress during upload
        }
      });

      setProgress(60); // Update progress
      // await loginAfterSignup(formData.get('username'), formData.get('password'));
      // setIsLoggedIn(true); // Set isLoggedIn to true upon successful signup

      setProgress(100); // Complete progress

      localStorage.setItem('token', response.data.token);
      localStorage.setItem('refreshToken', response.data.refreshToken);

      const token = response.data.token;
      const refreshToken = response.data.refreshToken;

      // Create a JSON object containing the tokens
      const userData = {
        token: token,
        refreshToken: refreshToken,
        // You can add other relevant user details here as needed
      };

      // Save the JSON object to localStorage
      localStorage.setItem("Userdata", JSON.stringify(userData));

      setIsLoggedIn(true)

      message.success('User Account Created Successfully');

      // Clear the signupFormData from local storage
      localStorage.removeItem('signupFormData');
    } catch (error) {
      // Handle error
      console.error('Signup error:', error);
      if (error.response && error.response.data) {
        message.error(error.response.data.message);
      }
    }
  }

  // async function loginAfterSignup(username, password) {
  //   try {
  //     // Make the login API request
  //     const response = await axios.post(`${config.apiUrl}user/login`, {
  //       username,
  //       password,
  //     });


  //     // Save user data and username to local storage
  //     localStorage.setItem('Userdata', JSON.stringify(response.data)); // Save the user data
  //     localStorage.setItem('username', username); // Save the username

  //     setProgress(80); // Update progress
  //   } catch (error) {
  //     // Handle login error
  //     console.error('Login error:', error);
  //   }
  // }

  return (
    <div className="flex items-center justify-center h-screen">
      <div>
        <div className="flex flex-col items-center space-y-2">
          <svg xmlns="http://www.w3.org/2000/svg" className="text-green-600 w-28 h-28" fill="none" viewBox="0 0 24 24" stroke="currentColor" strokeWidth="1">
            <path strokeLinecap="round" strokeLinejoin="round" d="M9 12l2 2 4-4m6 2a9 9 0 11-18 0 9 9 0 0118 0z" />
          </svg>
          <h1 className="text-4xl font-bold">Thank You!</h1>

          {isLoggedIn ? (
              <p>Account Creation Successful 🎉 </p>

          ) : (
            <p>Wait for Account Creation..</p>

          )}
          {isLoggedIn ? (
            <a
              className="inline-flex items-center px-4 py-2 text-white bg-indigo-600 border border-indigo-600 rounded  hover:bg-indigo-700 focus:outline-none focus:ring"
              href="/user/chatbot">
              <svg xmlns="http://www.w3.org/2000/svg" className="w-3 h-3 mr-2" fill="none" viewBox="0 0 24 24" stroke="currentColor" strokeWidth="2">
                <path strokeLinecap="round" strokeLinejoin="round" d="M7 16l-4-4m0 0l4-4m-4 4h18" />
              </svg>
              <span className="text-sm font-medium text-white">Return to the dashboard</span>
            </a>
          ) : (
            <Progress percent={progress} size="small" />
          )}
        </div>
      </div>
    </div>
  );
}
