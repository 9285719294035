import React, { useState, useCallback, useEffect } from 'react';
import uploadIcon from '../../assets/botTrainingIcons/uploadIcon.svg'
import deleteIcon from '../../assets/botTrainingIcons/deleteIcon.svg';
import pdfIcon from '../../assets/botTrainingIcons/pdfIcon.svg'
import searchIcon from '../../assets/botTrainingIcons/searchIcon.svg';
import moreOptionIcon from '../../assets/botTrainingIcons/moreOptionIcon.svg';
import fileIcon from '../../assets/botTrainingIcons/fileIcon.svg';

import axios from 'axios';
import config from '../../config';
import { message } from 'antd';

const FilesComponent = () => {
  const[characters, setCharacters]= useState(0)
  const [isDragging, setIsDragging] = useState(false);
  const [file, setFile] = useState(null);
  const [uploading, setUploading] = useState(false)
  const [tableDataStatus, setTableDataStatus] = useState('')

  const [filelist, setFileList] = useState([]);
  const [showOptions, setShowOptions] = useState(false);
  const [openRowIndex, setOpenRowIndex] = useState(null);

  const [searchTerm, setSearchTerm] = useState('');

  const filteredFiles = filelist.filter(file =>
    file.name.toLowerCase().includes(searchTerm.toLowerCase())
  );

  const handleSearch = useCallback((e) => {
    setSearchTerm(e.target.value);
  }, []);

  const handleMoreOptionsClick = (index) => {
    setOpenRowIndex(openRowIndex === index ? null : index);
  };

  const handleDragEnter = (e) => {
    e.preventDefault();
    setIsDragging(true);
  };

  const handleDragLeave = (e) => {
    e.preventDefault();
    setIsDragging(false);
  };

  const handleDrop = (e) => {
    e.preventDefault();
    setIsDragging(false);
    const droppedFile = e.dataTransfer.files[0];
    if (droppedFile && droppedFile.type === 'application/pdf') {
      setFile(droppedFile);
    }
  };

  const handleFileInput = (e) => {
    console.log('foile input');
    
    const selectedFile = e.target.files[0];
    if (selectedFile && selectedFile.type === 'application/pdf') {
      setFile(selectedFile);
    }

    try {
      const reader = new FileReader();
      reader.onload = () => {
        setTimeout(() => {
          const mockCharCount = Math.floor(Math.random() * 10000); 
          console.log(mockCharCount, '---char count');
          setCharacters(mockCharCount);
          message.success({ content: "File Uploaded Successfully", duration: 2 });

          
        }, 1000);
      };
      reader.readAsArrayBuffer(selectedFile);
    } catch (err) {
      console.log(err);
      
    }


  };

  const handleDelete = () => {
    setFile(null);
    setCharacters(0)
  };

  const handleUploadAndTrainBtn = async () => {
    console.log(file);
    setUploading(true)  
    
      try {
        const formData = new FormData();
        formData.append("name", file);
        formData.append("user", localStorage.getItem('username'));

        const response = await axios.post(`${config.apiUrl}upload/`, formData,);

        message.success({ content: "File Uploaded Successfully", duration: 2 });
        setUploading(false)
        setFile(null)
        getScrapedFileList()

      } catch (error) {
        if (error.response && error.response.status === 415) {
          // Unsupported Media Type
          message.error({ content: "Unsupported file type", duration: 2 });
          setUploading(false)
        } else {
          console.error("Error uploading file:", error);
          message.error({ content: "Failed to upload file", duration: 2 });
          setUploading(false)
        }
      }

  }


  const getScrapedFileList = () => {
    let username = localStorage.getItem('username');
    setTableDataStatus('Loading Data....')

    fetch(`${config.apiUrl}files/?user=${username}`)
      .then((response) => response.json())
      .then((data) => {

        if ((data).length == 0) { 
          setTableDataStatus('No data available')
        }

        const refineddata = data.map((item) => {
          let filename = item.name.split('/').pop();
          if (filename.includes('?')) {
            filename = filename.split('?')[0];
          }
          
          return {
            updated: formatDate(item.date),
            name: filename,
            url: item.name,
            user: item.user,
            id: item.id,
            status:'Trained'
          };
        });

        setFileList(refineddata)
      })
      .catch((error) => { 
      setTableDataStatus('No data available')
      console.error('Error fetching data:', error)
      });

  }

  useEffect(() => {
    getScrapedFileList()
  }, [])
  

  const formatDate = (dateinstring) => {
  
    const date = new Date(dateinstring) 
    const now = new Date();
    const diffTime = now - date;
    const diffDays = Math.floor(diffTime / (1000 * 60 * 60 * 24));

    if (diffDays === 0) {
        return "Today";
    } else if (diffDays === 1) {
        return "Yesterday";
    } else {
        return `${diffDays} days ago`;
    }
}

  const handleDeleteFileOption = (file) => {
    fetch(`${config.apiUrl}delete/${file.id}`, {
      method: 'DELETE',
    })
      .then((response) => response.json())
      .then((data) => {
        getScrapedFileList()
        setOpenRowIndex(null)
        message.success({ content: "File Deleted Successfully", duration: 2 });

      })
      .catch((error) => console.error('Error deleting file:', error));
  }

  const handlePDFdownload = (file) => {
    fetch(file.url)
      .then(response => {
        return response.blob();
      })
      .then(blob => {
        const url = window.URL.createObjectURL(blob);
        const a = document.createElement('a');
        a.href = url;
        a.download = file.name;
        document.body.appendChild(a);
        a.click();
        window.URL.revokeObjectURL(url);
        setOpenRowIndex(null)
        // handleCancel();
      })
      .catch(error => {
        console.error('Error downloading file:', error);
      });
  };

  return (
    <>
        <h2 style={{ fontSize: '22px', fontWeight:'bold',marginLeft: '1.5rem',marginTop: '2rem' }}>Upload files to train Jaweb on your data</h2>
      <div className="flex">
        <div className="links-content space-y-6">

          <div
            style={{
              border: '2px solid #eee',
              borderRadius: '6px',
              padding: '1.5rem',
              textAlign: 'center',
              backgroundColor: isDragging ? '#e0e0e0' : '#ffffff',
              cursor: 'pointer',
              transition: 'background-color 0.3s ease',
              width: '100%',
              height: '200px'
            }}
            onDragEnter={handleDragEnter}
            onDragOver={handleDragEnter}
            onDragLeave={handleDragLeave}
            onDrop={handleDrop}
            onClick={() => document.getElementById('fileInput').click()}
          >
            <input
              type="file"
              id="fileInput"
              style={{ display: 'none' }}
              onChange={handleFileInput}
              accept=".pdf"
            />
            <img src={uploadIcon} alt="Upload Icon" style={{ marginBottom: '10px', display: 'block', marginLeft: 'auto', marginRight: 'auto' }} />
            <p style={{ margin: '0 0 5px', color: '#333', fontWeight: 'bold' }}>
              Click to upload PDF or drag and drop
            </p>
            <p style={{ margin: 0, fontSize: '14px', color: '#666' }}>
              for specialised learning
            </p>
          </div>
                    

        </div>

        <div className="train-container">
          <h2 className="train-title">Source</h2>
          <p className="train-link-info">({characters.toLocaleString()} characters detected)</p>
          <h3 className="train-sub-title">Total Detected Characters</h3>
          <p className="train-limit-info">
            <span style={{fontWeight:'bold', fontSize:'13px', color:'#000'}}>{characters.toLocaleString()}</span> / {(400000).toLocaleString()} Limit
          </p>
        </div>
      </div>
    
      
      {
        file &&
        <>
        <div
          style={{
            border: '1px solid #e0e0e0',
            borderRadius: '8px',
            padding: '15px',
            display: 'flex',
            alignItems: 'center',
              backgroundColor: 'white',
            margin:'1rem'
          }}
        >
          <div style={{ backgroundColor: '#f0f0f0', borderRadius: '50%', padding: '10px', marginRight: '15px' }}>
            <img src={pdfIcon} size={24} color="#666" />
          </div>
          <div style={{ flexGrow: 1 }}>
            <p style={{ margin: 0, fontWeight: 'bold', color: '#333' }}>{file.name}</p>
            <p style={{ margin: 0, fontSize: '14px', color: '#666' }}>
              {(file.size / (1024 * 1024)).toFixed(2)} 0 MB
            </p>
          </div>
          <button
            onClick={handleDelete}
            style={{
              background: 'none',
              border: 'none',
              cursor: 'pointer',
              padding: '5px',
            }}
          >
            <img src={deleteIcon} size={20} color="#666" />
          </button>
        </div>

        <button className="upload-train-button" onClick={handleUploadAndTrainBtn} disabled={uploading}>
          {uploading ? 'Uploading...' : 'Upload and train'}
          </button>
          </>
      }


      <div className="filelist-container">
        <div className="filelist-header">
          <h2 className="filelist-header-title">Files</h2>
          <div className="filelist-search-container">
            <input
              type="text"
              placeholder="Search"
              className="filelist-search-input"
            value={searchTerm}
            onChange={handleSearch}

            />
            <img src={searchIcon} className="filelist-search-icon" alt="Search" />
          </div>
        </div>
        <table className="filelist-table">
          <thead>
            <tr className="filelist-table-header">
              <th>Name</th>
              <th>Status</th>
              <th>Updated</th>
              <th width="100"></th>
            </tr>
          </thead>
      <tbody>
        {filteredFiles.length > 0 ? filteredFiles.map((file, index) => (
          <tr key={index} className="filelist-table-row">
            <td className="filelist-table-cell">{file.name}</td>
            <td className="filelist-table-cell">
              <span className={`filelist-status ${file.status === 'Processing' ? 'filelist-status-processing' : 'filelist-status-default'}`}>
                {file.status}
              </span>
            </td>
            <td className="filelist-table-cell">{file.updated}</td>
            <td className="filelist-more-option">
              <img 
                src={moreOptionIcon} 
                alt="More Options" 
                style={{ width: '20px' }} 
                onClick={() => handleMoreOptionsClick(index)} 
              />
              {openRowIndex === index && (
                <div className="options-menu">
                  <div className="option-item" onClick={() => handlePDFdownload(file)}>
                    <img src={fileIcon} alt="View" style={{ marginRight: '8px', width: '20px' }} />
                    Download
                  </div>
                  <div className="option-item" onClick={() => handleDeleteFileOption(file)}>
                    <img src={deleteIcon} alt="Delete" style={{ marginRight: '8px', width: '20px' }} />
                    Delete
                  </div>
                </div>
              )}
            </td>
          </tr>
        )) : (
          <tr>
            <td colSpan={7} className="text-center text-gray-500">
              {tableDataStatus}
            </td>
          </tr>
        )}
      </tbody>
        </table>
      </div>


    </>
  )
}

export default FilesComponent;