import React from 'react'
import Sidebar from './components/Sidebar'
import "../../src/Pages/Css/Integration.css"
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faSearch } from '@fortawesome/free-solid-svg-icons';
import { IoSettingsOutline } from "react-icons/io5";
import { FaRegBell } from "react-icons/fa";
import { BsChatText } from "react-icons/bs";
import { BsChevronDown } from "react-icons/bs";
import profile_img from "../assets/Ellipse 43.png"
import filter from "../assets/mdi_filter.png"
import addIcon from "../assets/fa-solid_plus.png"
import ncImg from "../assets/nochat.png"
import chatImg from "../assets/chat.png"
import qrcode from "../assets/qr-code-outline.png"
import { Link } from 'react-router-dom';
import { FaFilter } from "react-icons/fa";
import { IoMdAdd } from "react-icons/io";
import Avatar from '../assets/Avatar (1).png'
import { RiVerifiedBadgeFill } from "react-icons/ri";
import Chatsidebar from './Chatsidebar';

export default function ChatSection() {
  
  return (
    <div className='flex'>
    

      <div className='cus_test'>
      
        <div className='flex'>
      
          <div className='chat_wrapper '>
            <div className='noChat_found'>
              <div className='wrapperImage'>
                <img src={ncImg} alt='test' className='ncImg' ></img>
              </div>

              <h2 className='text_noChat'>Looks like you don't have any chats yet!</h2>
              <div className=" wrapper_bottom">
                <div className="row">
                  <div className="col-sm-4 col-md-4">
                    <div className="item">
                      <img src={qrcode} alt="alt message" className="img-fluid d-block mx-auto" height={100} width={100} />
                      <p className='col_text'>Generate your QR Code or Chat Link to
                        get messages from WhatsApp users
                        <a href='#' className='LinkText'> Click here.</a></p>
                    </div>
                  </div>
                  <div className="col-sm-4 col-md-4">
                    <div className="item">
                      <img src={chatImg} alt="alt message" height={100} width={100} className="img-fluid d-block mx-auto" />
                      <p className='col_text'>Add a free Chat Widget to your website
                        <a href='#' className='LinkText'> Click here.</a></p>
                    </div>
                  </div>
                  <div className="col-sm-4 col-md-4">
                    <div className="item">
                      <img src={Avatar} alt="alt message" className="img-fluid d-block mx-auto" height={100} width={100} />
                      <p className='col_text'>Add your first contact
                        <a href='#' className='LinkText'> from here.</a></p>
                    </div>
                  </div>
                </div>
              </div>

            </div>
          </div>
        </div>
      </div>





    </div>
  )
}