import React,{useEffect,useState} from 'react'
import axios from 'axios';
import { Spin,List,Avatar,Table,Tag,Space,Statistic,Card } from 'antd';
import config from '../../config';
import CustomLayout from '../../layouts/Customlayout';
import { CurrencySymbolMap } from './currency';

import { ArrowDownOutlined, ArrowUpOutlined } from '@ant-design/icons';

export default function ShopifyPage() {
    const [SpinWheel,setSpin]= useState(false)
    const [authenticated,setAuthenticated]=useState(false)
    const [OrderData,setOrderData]=useState([])
    const [orderNumbers,setOrderDataNum]=useState()

    useEffect(()=>{
        ShopifyAuth()
    },[])


    const ShopifyAuth = async () => {
        setSpin(true);
        
        try {
          const response = await axios.get(`${config.apiUrl}shopify/?username=${localStorage.getItem('username')}`);
          
          console.log(response.data);
          const access_token = response.data[0].access_token;
          const shop=response.data[0].shop // Extracting access token from the first object in the array
          // Extracting access token from response data
          setAuthenticated(true);
          // Make a GET request to retrieve orders from the Shopify store's API
          try {
            const response = await axios.get(`${config.apiUrl}shopify/orders/?access_token=${access_token}&shop=${shop}&username=${localStorage.getItem('username')}`);

            console.log(response.data);
            setOrderData(response.data.orders)
            setOrderDataNum(response.data.orders.length)

            // Handle the response data
        
        } catch (error) {
            console.error('Error fetching Shopify orders:', error);
            // Handle the error
        } finally {
            setSpin(false); // Always set the spin to false, whether request is successful or not
        }
        


   
          
        } catch (error) {
          console.error('Error fetching orders:', error);
          setAuthenticated(false);
          setSpin(false);
          // Handle the error as needed
        }
      };
      

      const columns = [
        {
          title: 'Name',
          dataIndex: 'shipping_address',
          key: 'shipping_address',
          render: (text) => 
          <a>
            
            {text?.name}
          </a>,
        },
        {
          title: 'Currency',
          dataIndex: 'currency',
          key: 'currency',
          render: (_, { currency }) => {
          
            return (
              <span>
                {CurrencySymbolMap[currency]}
              </span>
            );
          },
  
        },
        {
          title: 'Total Price',
          dataIndex: 'current_total_price',
          key: 'current_total_price',
        },
        {
          title: 'Phone',
          dataIndex: 'phone',
          key: 'phone',
        },
        {
          title: 'Email',
          dataIndex: 'email',
          key: 'email',
        },
        {
          title: 'Order Number',
          dataIndex: 'order_number',
          key: 'order_number',
        },
        {
          title: 'Address',
          dataIndex: 'address',
          key: 'address',
        },
        {
          title: 'Confirmed',
          key: 'confirmed',
          dataIndex: 'confirmed',
          render: (_, { confirmed }) => {
            let color = confirmed ? 'green' :   'volcano';
            return (
              <Tag color={color}>
                {confirmed ? 'Confirmed' : 'Not Confirmed'}
              </Tag>
            );
          },
        },
        
        
      ];


  return (
    <CustomLayout>

    <div className='mt-10 flex flex-col justify-center'>
    {SpinWheel ? (
        <div className='flex justify-center'>
                    <Spin />
        </div>

    ) : (
        authenticated ? (
           <div>
            <div>
            <Card style={{ marginBottom: 10 }}>
              <Statistic
                title="Total Orders"
                value={orderNumbers}
                valueStyle={{
                  color: '#3f8600',
                }}
                prefix={<ArrowUpOutlined />}
              />
            </Card>

            </div>
            <div className="table-responsive">
            <Table columns={columns} dataSource={OrderData} />
            </div>
           </div>
              )
  
      : (
            <div className='textAlign-center'>Please Connect A Store To This Account</div>
            )
    )}
    </div>

    </CustomLayout>

  )
}
