import React, { useState, useEffect, useRef } from 'react';
import Sidebar from './components/Sidebar';
import './Css/Messaging.css'
import attachimg from "../assets/attach.png"
import galleryimg from "../assets/image.png"
import locationimg from "../assets/location.png"
import smile from "../assets/smile 1.png"
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faSearch } from '@fortawesome/free-solid-svg-icons';
import { IoSettingsOutline } from "react-icons/io5";
import { FaRegBell } from "react-icons/fa";
import { BsChatText } from "react-icons/bs";
import { BsChevronDown } from "react-icons/bs";
import profile_img from "../assets/Ellipse 43.png"
import { Link } from 'react-router-dom';
import { FiPhone } from "react-icons/fi";
import { FiVideo } from "react-icons/fi";
import { HiOutlineDotsVertical } from "react-icons/hi";
import { FaFilter } from "react-icons/fa";
import { IoMdAdd } from "react-icons/io";
import { RiVerifiedBadgeFill } from "react-icons/ri";
import { MdEmojiEmotions } from "react-icons/md";
import { Dropdown } from 'antd';
import { DownOutlined } from '@ant-design/icons';
import Chatsidebar from './Chatsidebar';
import axios from 'axios';
import ChatSection from './ChatSection';
import config from '../config';

export default function ChatScreen() {

    const [isModalOpen, setIsModalOpen] = useState(false);
    const [numberValue, setNumberValue] = useState(0);


    const [inputValue, setInputValue] = useState("");
    const [messageList, setMessageList] = useState([]);
  
    const [newSocket, setNewSocket] = useState(null);
    const [noData, setNoData] = useState(true)
    const [childData, setChildData] = useState("")
  
    useEffect(() => {
      const socket = new WebSocket(`wss://jawebcrm.onrender.com/ws/whatsapp_chat/87654345678987654/`);
      setNewSocket(socket);
  
      return () => {
        socket.close();
      };
    }, []);
  
    useEffect(() => {
      if (!newSocket) return;
  
      newSocket.onopen = () => {
        console.log('WebSocket connection opened');
      };
  
      newSocket.onclose = (event) => {
        console.log('WebSocket connection closed:', event.reason);
      };
  
      newSocket.onmessage = (event) => {
        
        if (typeof JSON.parse(event.data).message == 'object'){
            setMessageList(messageList => [...messageList, JSON.parse(event.data).message]);
        }

      };
  
      return () => {
        newSocket.onopen = null;
        newSocket.onclose = null;
        newSocket.onmessage = null;
      };
    }, [newSocket]);

    const chatMessagesManaged = () =>{
        // console.log('number changed ------------------>>', childData.phone_number);

        // let formData = new FormData();
        // formData.append('phone_number', numberValue);

        const headers = {
            headers: {
                'Content-Type': 'application/json',
                'Authorization': `JWT ${JSON.parse(localStorage.getItem('token'))}`,
                'Accept': 'application/json'
            }
        };

        axios.get(`${config.apiUrl}whatsapp-message-get/`, headers).then((res)=>{
            console.log(res,'------------------whatsapp-message-get-')
            setMessageList(res.data)
        })
    }


    useEffect(()=>{    
        chatMessagesManaged()
    },[childData.phone_number])
    
  
    const handleSubmit = async (e) => {
      e.preventDefault();
  
        console.log(inputValue);

      const temp_json ={
        'message': inputValue,
        'from_bot': true
      };
  
      if (newSocket) {
        newSocket.send(JSON.stringify({
          type: "send_message",
          message: JSON.stringify(temp_json)
        }));
      }

      let formData = new FormData();
      formData.append('message', inputValue);
      formData.append('phone_number', childData.phone_number);
      
  

      const headers = {
          headers: {
              'Content-Type': 'application/json',
              'Authorization': `JWT ${JSON.parse(localStorage.getItem('token'))}`,
              'Accept': 'application/json'
          }
      }; 

      axios.post(`${config.apiUrl}whatsapp-message-send/`, formData, headers).then((res)=>{
        //   console.log(res, '----------------------->>>> whatsapp-message-send');
      })
  
    //   console.log(messageList,'---------message list');

      setMessageList(messageList => [...messageList, temp_json]);
      setInputValue("");
    };

    const showModal = () => {
        setIsModalOpen(true);
        console.log("showwwww")
    };
    const handleOk = () => {
        setIsModalOpen(false);
    };
    const handleCancel = () => {
        setIsModalOpen(false);
    };
    const handleNumberChange = (value) => {
        setNumberValue(value);
    };

    const handleScreen = () =>{
        console.log(childData.phone_number,'-->>');
        setNoData(false)
    }
    // function rightside() {
    //     setRightSidebar(!rightsidebar)
    // }

    function rightside() {
        document.body.classList.toggle('open_customer_info')
    }

    

    const handleChildStateChange = (newState) => {
        // console.log(newState,'-------------newstate');
        setChildData(newState);
    };

    return (

        <div className='flex'>
            <Sidebar />
            <div className='cusWidth'>
                <div className='chat-head-wrapper flex'>
                    <div className="search-container">
                        <FontAwesomeIcon icon={faSearch} className="search-icon" />
                        <input type="text" placeholder="Search in Dashboard...." className='input_search' />
                    </div>
                    <div className='flex circle_wrapper'>
                        <button type="button" class="circle-icon">
                            <BsChatText className='icon_clr' />
                        </button>
                        <button type="button" class="circle-icon">
                            <FaRegBell className='icon_clr' />

                        </button>
                        <button type="button" class="circle-icon">
                            <IoSettingsOutline className='icon_clr' />
                        </button>
                        <div className='flex user_profile'>
                            <div className='text_wrapper'>
                                <h2 className='profile_name'>Hi John!</h2>
                                <span className='profile_discription'>Engineer</span>
                            </div>
                            <div className='sideprofile'>
                                <img alt='' />
                            </div>
                            <BsChevronDown className='down_arrow' />

                        </div>
                    </div>
                </div>
                <div className='screenWrapper'>
                    <div class="column1" >

                   <Chatsidebar handleScreen = {handleScreen} onStateChange={handleChildStateChange} messageList={messageList} />

                    </div>
                    <div class="column2">
                        { noData ? <ChatSection /> : <>
                        <div className='chatheader flex'>
                            <div className='Chatprofile flex'>
                            <div className='sideprofile'>
                                <img alt='' />
                            </div>
                                <div>
                                    <h2 className='profile_name_text'>{childData.phone_number}</h2> 
                                    <span className='profile-status'>Online</span>
                                </div>

                            </div>
                            <div className='flex menuBTns'>
                                <button type="button" class="circle-icon borderCus">
                                    <FiPhone className='iconCLr' />
                                </button>
                                <button type="button" class="circle-icon borderCus">
                                    <FiVideo className='iconCLr' />
                                </button>
                                <button type="button" class="circle-icon borderCus" onClick={() => rightside()}>
                                    <HiOutlineDotsVertical className='iconCLr' />
                                </button>
                            </div>


                        </div>

                    
                        <div className='Conversation_section'>
                            <div>
                                <span className='currentTime'>Today, 11:03 AM </span>

                                {
                                    messageList.length>0 ? messageList.map((data, index) => (

                                        !data.from_bot ? <div className='rightContainer '>
                                        <div class="container msgWrap">
                                            <div className='receiverMsg receiverMsg-radius'>
                                                <p>{data.message}</p>
                                            </div>
                                            <div className='receiver-name'>
                                                <h2>User</h2>
                                                <span class="time-right">08:23 AM</span>
                                            </div>
    
                                        </div>
                                    </div> : <div className='leftConatiner'>
                                        <div class="container rightMsgwrap">
                                            <div className='senderMsg senderMsg-radius'>
                                                <p>{data.message}</p>
                                            </div>
                                            <div className='flex senderName'>
                                                <span class="time-left">09:23 AM</span>
                                                <h2 className='sender-name-text'>You</h2>
                                            </div>
                                        </div>
                                    </div>  
                                    )):''
                                }

                            </div>
                        </div>
                        <div className='chat-input-section'>
                            <form onSubmit={handleSubmit}>
                                <div className='input-wrapper'>
                                    <input type='text' className='search-bar' 
                                        value={inputValue}
                                        onChange={(e) => setInputValue(e.target.value)}
                                        placeholder='Send your message...'/>

                                    <button type="button" className='emoji-icon'>
                                        <MdEmojiEmotions className='emojiSvg' />

                                    </button>
                                    <div className='media-icons'>
                                        <button type="button">
                                            <img src={galleryimg} alt='galleryimg'></img>
                                        </button>
                                        <button type="button">
                                            <img src={attachimg} alt='attachimg'></img>
                                        </button>
                                        <button type="button">
                                            <img src={locationimg} alt='locationimg'></img>
                                        </button>
                                    </div>

                                </div>

                            </form>
                        </div>
                        <div className='rightSidebar'>
                            <div className='custom-border'>
                                <h2 className='head_name'>Customer Info</h2>
                            </div>
                            <div className='customer_img custom-border-two'>
                                <div className='rightSideProfile'>
                                    <img alt='' />
                                </div>
                                <h2>Angela Vatiga</h2>
                                <span>SEO Expert</span>
                            </div>
                            <div className='custom-border-two customer-detail'>
                                <div className='flex  customer-btns'>
                                    <button type="button" className='status_btn'>Status</button>
                                    <button type="button" className=' status_btn Closed_btn-clr'>Closed</button>
                                </div>
                                <ul>
                                    <li className='flex customerLi '>
                                        <h2>Contact No</h2>
                                        <span>+44 1632 960776</span>
                                    </li>
                                    <li className='flex customerLi'>
                                        <h2>Location</h2>
                                        <span>Clifford, United Kingdom</span>
                                    </li>
                                    <li className='flex customerLi'>
                                        <h2>Email</h2>
                                        <span>angela.vatiga@gmail.com</span>
                                    </li>
                                    <li className='flex customerLi'>
                                        <h2>IP Address</h2>
                                        <span>159.186.197.104</span>
                                    </li>
                                </ul>
                            </div>
                            <div className='cus_pad'>
                            <div className='dropdown-wrapper' style={{ display: 'flex', justifyContent: 'space-between', alignItems: 'baseline', gap: '100' }}>
                                <span className="dropdown-heading">Campaigns</span>
                                <Dropdown>
                                    <a className="ant-dropdown-link">
                                        <DownOutlined />
                                    </a>
                                </Dropdown>
                            </div>
                                <div className='chatSummary'>
                                    <h2>Chat Summary</h2>
                                    <p>Angela Vatiga notifies the team of the completion of the requirements document and shares it for review. You acknowledge receipt, and Angela requests Olivia to review the
                                        latest design, to which you respond affirmatively.</p>

                                </div>
                                <div className='download_btn_wrapper'>

                                    <button className='Download_btn'>Download Chat Summary</button>
                                </div>
                            </div>

                        </div>

                        </>}
                 
                    </div>
                  
                     
                        

                </div>
            </div>


        </div>
    )
}