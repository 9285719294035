import { useContext, createContext, useState } from "react";
import { useNavigate } from "react-router-dom";
import config from "../config";
import { message } from "antd";

const AuthContext = createContext();

const AuthProvider = ({ children }) => {
  const [user, setUser] = useState(null);
  const [error,seterror]= useState(false)
  const [token, setToken] = useState(localStorage.getItem("token"));
  const navigate = useNavigate();
  const loginAction = async (data) => {
    try {
      const response = await fetch(`${config.apiUrl}user/login`, {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
        },
        body: JSON.stringify(data),
      });
      const res = await response.json();
      if (res.token) {
        // let userdata = localStorage.getItem('');

        seterror(false)
        console.log(error)
        localStorage.setItem('username',data.username)
        localStorage.setItem('token',res.token)
        setUser(JSON.stringify(data));
        setToken(res.token);

        if (res.userPermission && res.userPermission.team_permission == "User") {
          navigate('/user/leads')
        } else {
          navigate('/user/chatbot')
        }

        localStorage.setItem("Userdata", JSON.stringify(res));
        message.success("Logged in Successfully",3)
       
        return;
      }

      throw new Error(res.message);
    } catch (err) {
        seterror(true)
        message.error("Invalid Password or Email",3)
    }
  };

//   const logOut = () => {
//     setUser(null);
//     setToken("");
//     localStorage.removeItem("Userdata");
//     navigate("/auth/login");
//   };

  return (
    <AuthContext.Provider value={{ token, user, loginAction,error }}>
      {children}
    </AuthContext.Provider>
  );

};

export default AuthProvider;

export const useAuth = () => {
  return useContext(AuthContext);
};