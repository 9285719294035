
import './App.css';
import Signup from './Pages/Auth/Signup';
import NotFound from './Pages/NotFound';
import { Provider } from 'react-redux';
import store from './store';
import Settings from './Pages/Settings';
import Messaging from './Pages/Messaging';
import Integration from './Pages/Integration';
import AccountSettings from './Pages/AccountSetup';
import Verify from './Pages/Auth/Verify';
import Users from './Pages/Users';
import DefaultDashboard from './Pages/Index';
import ChangePassword from './Pages/User/ChangePassword';
import Notification from './Pages/User/Notification';
import Website from './Pages/Integration/Website';
import Wordpress from './Pages/Integration/Wordpress';
import Shopify from './Pages/Integration/Shopify';
import Invitation from './Pages/Invitation';
import WhatsApp from './Pages/Integration/WhatsApp';
import ChatSection from './Pages/ChatSection';
import ChatScreen from './Pages/chatScreen';
import WebSocketProvider from './Pages/components/WebSocketProvider';
import Checkuser from './Pages/components/Checkuser';
import Callcenter from './Pages/Callcenter';
import Mail from './Pages/mail/index';
import MailItem from './Pages/mail/MailItem';
import 'antd/dist/reset.css'
import Questions from './Pages/Questions';
import MailContent from './Pages/mail/MailContent';
import Pricing from './Pages/payment/Pricing';
import { Elements } from '@stripe/react-stripe-js';
import { loadStripe } from '@stripe/stripe-js';
import Cancel from './Pages/payment/Cancel';
import Success from './Pages/payment/Success';
import ShopifyPage from './Pages/Shopify';
import Chatbotsection from './Pages/User/Chatbotsection';
import { BrowserRouter as Router, Route, Routes } from "react-router-dom";
import Login from "./Pages/Auth/Login";
import UserList from './Pages/Leads/Leads';
import SignUpShopify from './Pages/Auth/SignUpShopify';
import ShopifyCallback from './Pages/components/ShopifyCallback';
import AuthProvider from "./hooks/AuthProvider";
import PrivateRoute from "./routers/PrivateRouter";
import SuccessShopify from './Pages/payment/SuccessShopify';
import PasswordReset from './Pages/Auth/PasswordReset';
import CheckSubscription from './routers/BillingChecker';
import Zapier from './Pages/Integration/Zapier';
import './translations/translations.js'
import Credit from './Pages/User/Credit';
import EmailChatSummaryLogo from './Pages/EmailChatSummary.js';
import CreditTransactionHistory from './Pages/User/TransactionHistory.js';
import { ConfigProvider } from "antd";
import AnalyticDashboard from './Pages/AnalyticDashboardData.js';
import LoginV2 from './Pages/Onboarding/LoginV2.js';
import SingupV2 from './Pages/Onboarding/SignupV2.js';
import PasswordResetV2 from './Pages/Onboarding/PasswordResetV2.js';

import BotTraining from './Pages/BotTraining/BotTrainingPage.js';

const stripePromise = loadStripe('pk_live_51NXTEUCYSjtwtOyQma5bLqGdcFa1G7Rd3v2ThTaYAuo1nP6225YKgHRuBlJ7Sgx59j5s7ldWJfKE4xOq0YcicxAM00B2iT64vg');

function App() {
  return (
      <ConfigProvider
        theme={{
          components: {
            Button: {
              colorPrimaryBorderHover: 'black',
              colorPrimaryHover: 'gray',
              colorPrimary: 'black',
              colorPrimaryActive: 'black',
              defaultActiveColor: 'black',
              colorPrimaryTextHover: 'black',
              colorBgContainerDisabled:'gray',
              // Apply custom style when loading is true
              colorPrimaryLoadingBackground: 'inherit', // Keeps the same background color when loading
              colorPrimaryLoadingText: 'inherit', // Keeps the same text color when loading
            }
          }
        }}
      >
    <div className="App">

      <Router>
        <AuthProvider>
          <Routes>
            <Route path="/auth/login" element={<LoginV2 />} />
            <Route path="/" element={<LoginV2/>}/>
            <Route path="/auth/signup" element={<Elements stripe={stripePromise}><SingupV2 /></Elements>}/>
            <Route path="/auth/signupShopify" element={<Elements stripe={stripePromise}>< SignUpShopify/></Elements>}/>
            <Route path="/auth/verify" element={<Verify/>}/>
            <Route path="/auth/passwordReset" element={<PasswordResetV2/>}/>
            <Route path="/auth/signup/invitation/:invitation_id" element={<Invitation/>}/>
            <Route path="/auth/payment" element={<Pricing/>}/>
            <Route path="/auth/payment/cancel" element={<Cancel/>}/>
            <Route path="/auth/payment/success" element={<Success/>}/>
            <Route path="/auth/shopifyBilling/success" element={<SuccessShopify/>}/>
            <Route path="*" element={<NotFound />} />
            <Route path="/shopify/callback" element={<SignUpShopify/>} />

            <Route path="/auth/login/v2" element={<LoginV2 />} />
            <Route path="/auth/signup/v2" element={<Elements stripe={stripePromise}><SingupV2 /></Elements>} />
            {/* <Route path="/auth/payment" element={<Pricing/>}/> */}
            <Route path="/auth/passwordReset/v2" element={<PasswordResetV2/>}/>



            <Route element={<PrivateRoute />}>

              <Route element={<CheckSubscription />}>
                <Route path="/user/leads" element={<UserList />} />
                <Route path="/user/chatbot" element={<Chatbotsection/>}/>
                <Route path="/user/leads" element={<UserList/>}/>
                <Route path="/user/messages/:session_id" element={<Messaging/>}/>
                <Route path="/user/integration" element={<Integration/>}/>
                <Route path="/user/Adminusers" element={<Users/>}/>
                <Route path="/user/settings" element={<BotTraining/>}/>
                <Route path="/user/help" element={<Questions/>}/> 
                <Route path='/user/shopify' element={<ShopifyPage/>}/>
                <Route path="/user/notification" element={<Notification />} />
                <Route path="/user/Chatbotsection" element={<Chatbotsection/>} />
                <Route path="/integration/website" element={<Website/>}/>
                <Route path="/integration/wordpress" element={<Wordpress />} />
                <Route path="/integration/shopify" element={<Shopify/>} />
                <Route path="/integration/WhatsApp" element={<WhatsApp/>}></Route>
                <Route path="/integration/Zapier" element={<Zapier/>}></Route>
                <Route path="/integration/chatsection" element={<ChatSection/>}></Route>
                <Route path="/integration/chatscreen" element={<ChatScreen/>}></Route>
                <Route path="/user/analytics" element={<AnalyticDashboard />} />
                <Route path="/user/edit-profile" element={<AccountSettings/>}/>
                <Route path="/user/change-password" element={<ChangePassword />} />
                <Route path="/user/email-summary" element={<EmailChatSummaryLogo />} />
                <Route path="/user/transaction-history" element={<CreditTransactionHistory/>}/>
              </Route>
              <Route path="/user/plans"element={<Elements stripe={stripePromise}><Credit /></Elements>} />

            </Route>

        </Routes>
      </AuthProvider>
    </Router>

    </div>
    </ConfigProvider>

  );
}

export default App;
