import React, { useEffect, useState, useRef } from "react";
import GoogleMapReact from "google-map-react";
import "./Map.css";
import axios from "axios";
import config from "../../config";
import { Icon } from "@iconify/react";
import locationIcon from "@iconify/icons-mdi/map-marker";

const LocationPin = ({ text }) => (
  <div className="pin">
    <Icon icon={locationIcon} className="pin-icon" />
    <p className="pin-text">{text}</p>
  </div>
);

const WorldMap = (data) => {
  const [markerLocation, setMarkerLocation] = useState([]);
  const mapInstanceRef = useRef(null);
  const mapsInstanceRef = useRef(null);
  const [mapLoaded, setMapLoaded] = useState(false);

  console.log(data.results);

  let temp_data = data.results;

  useEffect(() => {
    const fetchMarkerLocations = async () => {
      const locations = [];
      for (let i = 0; i < temp_data.length; i++) {
        if (temp_data[i].name !== "N/A") {
          try {
            const res = await axios.get(
              `https://maps.googleapis.com/maps/api/geocode/json?address=${temp_data[i].name}&key=${config.mapKey}`
            );
            const location = res.data.results[0].geometry.location;
            locations.push({
              ...location,
              name: temp_data[i].name,
              value: temp_data[i].value,
            });
          } catch (error) {
            console.error("Error fetching location data:", error);
          }
        }
      }
      setMarkerLocation(locations);
    };

    if (temp_data) {
      fetchMarkerLocations();
    }
  }, [temp_data]);

  return (
    <div className="map">
      <div className="google-map">
        <GoogleMapReact
          bootstrapURLKeys={{ key: config.mapKey }}
          defaultCenter={{ lat: 0, lng: 0 }}
          defaultZoom={1}
          yesIWantToUseGoogleMapApiInternals
          onGoogleApiLoaded={({ map, maps }) => {
            mapInstanceRef.current = map;
            mapsInstanceRef.current = maps;
            setMapLoaded(true);
          }}
        >
          {markerLocation.map((loc) => (
            <LocationPin
              key={`${loc.lat}-${loc.lng}`}
              lat={loc.lat}
              lng={loc.lng}
              text={`${loc.name} - ${loc.value}`}
            />
          ))}
        </GoogleMapReact>
      </div>
    </div>
  );
};

export default WorldMap;