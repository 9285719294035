import React, { Component } from 'react';
import { UserOutlined, LockOutlined, BellOutlined, WechatWorkOutlined, CreditCardOutlined,BookOutlined } from '@ant-design/icons';
import { Menu, Row, Col } from 'antd';
import EditProfile from './User/EditProfile';
import Sidebar from './components/Sidebar';
import Navbar from './components/Navbar';
import CustomLayout from '../layouts/Customlayout';
import './User/Css/AccountSettings.css'; // Import the CSS file with responsive styles
import { withTranslation, useTranslation } from 'react-i18next';
import SettingOption from './User/components/MenuItems';



export class AccountSetting extends Component {
  render() {
    // Retrieve the UserObject from localStorage and parse it
    const UserInfo = JSON.parse(localStorage.getItem('UserObject'));
    // Determine if the mode is 'shopify'
    const shopify = UserInfo?.mode === 'shopify';

    const { t } =  this.props;

    return (
      <CustomLayout>
        <div className="container">
          <Row gutter={[16, 16]} className="flex-container">
            <Col xs={24} sm={24} md={8} className="menu">
              <SettingOption shopify={shopify} />
            </Col>
            <Col xs={24} sm={24} md={16} className="content">
              <EditProfile />
            </Col>
          </Row>
        </div>
      </CustomLayout>
    );
  }
}

export default AccountSetting;
