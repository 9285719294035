import React, { useState, useEffect } from 'react';
import { UserOutlined, LockOutlined, BellOutlined, WechatWorkOutlined, CreditCardOutlined, BookOutlined } from '@ant-design/icons';
import { Spin, Row, Col, Table, Button } from 'antd';
import { useTranslation } from 'react-i18next';
import CustomLayout from '../../layouts/Customlayout';
import SettingOption from './components/MenuItems';
import 'antd/dist/reset.css';
import axios from 'axios';
import config from '../../config';
import jsPDF from 'jspdf';
import autoTable from 'jspdf-autotable';

// Base64 image or a public URL of the image
const logo = 'https://jaweb.me/wp-content/uploads/2023/09/21dffb6b18844ef887c3479f1fbd1286-1024x259.png'; // Replace with your base64 encoded image or public image URL

const CreditTransactionHistory = () => {
  const [data, setData] = useState(null);
  const [error, setError] = useState(null);
  const [loading, setLoading] = useState(false);
  const { t } = useTranslation();
  const transactionHistoryTranslation = t('accountSetting');

  useEffect(() => {
    setLoading(true);
    const headers = {
      'Content-Type': 'application/json',
      'Authorization': `Token ${localStorage.getItem('token')}`,
      'Accept': 'application/json',
    };

    axios.get(`${config.apiUrl}credits-transaction-history/`, { headers })
      .then(res => {
        setData(res.data.data);
        setLoading(false);
      })
      .catch(error => {
        setError(error.message);
        setLoading(false);
        console.error(error);
      });
  }, []);

  const UserInfo = JSON.parse(localStorage.getItem('UserObject'));
  const shopify = UserInfo?.mode === 'shopify';

  const formatDate = (dateString) => {
    const date = new Date(dateString);
    return date.toISOString().split('T')[0];
  };

  const formatAmount = (amount) => { 
    return `$${amount}`;
  }

  const columns = [
    {
      title: transactionHistoryTranslation.transactionHistory.table.paymentAmt,
      dataIndex: 'payment_amount',
      key: 'payment_amount',
      render: (amount) => formatAmount(amount)
    },
    {
      title: transactionHistoryTranslation.transactionHistory.table.totalCredit,
      dataIndex: 'total_credit',
      key: 'total_credit',
    },
    {
      title: transactionHistoryTranslation.transactionHistory.table.creditType,
      dataIndex: 'credit_type',
      key: 'credit_type',
    },
    {
      title: transactionHistoryTranslation.transactionHistory.table.date,
      dataIndex: 'created_at',
      key: 'created_at',
      render: (text) => formatDate(text)
    },
  ];

  const generatePDF = () => {
    const doc = new jsPDF();
  
    // Add company logo
    doc.addImage(logo, 'PNG', 15, 10, 50, 15); // Adjust the X, Y, width, and height as per your needs
  
    // Add company name
    doc.setFontSize(16);
    doc.setTextColor(40);
    doc.text("Jaweb", 140, 20); // Company Name, positioned on the right
  
    // Add company address
    doc.setFontSize(10);
    doc.text("Jaweb \nAI Chatbot Solutions\ninfo@jaweb.me\nwww.jaweb.me", 140, 25);
  
    // Add invoice title
    doc.setFontSize(22);
    doc.text("Invoice", 15, 40);
  
    // Add customer details
    doc.setFontSize(12);
    doc.text(`Email: ${UserInfo.email || 'customer@example.com'}`, 15, 60);
  
    // Add invoice number and date
    const invoiceNumber = Math.floor(Math.random() * 1000000); // Random Invoice number
    const invoiceDate = new Date().toISOString().split('T')[0]; // Today's date
  
    doc.text(`Invoice No: ${invoiceNumber}`, 140, 55);
    doc.text(`Date: ${invoiceDate}`, 140, 60);
  
    // Add table title
    doc.setFontSize(16);
    doc.text("Transaction Details", 15, 70);
  
    // Add table of transactions
    autoTable(doc, {
      startY: 80, // Start after the header
      head: [[
        transactionHistoryTranslation.transactionHistory.table.paymentAmt,
        transactionHistoryTranslation.transactionHistory.table.totalCredit,
        transactionHistoryTranslation.transactionHistory.table.creditType,
        transactionHistoryTranslation.transactionHistory.table.date,
      ]],
      body: data.map(item => [
        formatAmount(item.payment_amount),
        item.total_credit || "-",
        item.credit_type,
        formatDate(item.created_at)
      ]),
      theme: 'grid', // Add borders around cells
      headStyles: { fillColor: '#710BE1' }, // Custom header color (matching the button color)
      styles: { cellPadding: 3, fontSize: 10, overflow: 'linebreak' }, // Table styling
      columnStyles: {
        0: { cellWidth: 50 }, // Adjust column widths if necessary
        1: { cellWidth: 40 },
        2: { cellWidth: 50 },
        3: { cellWidth: 30 },
      },
    });
  
    // Add footer
    doc.setFontSize(10);
    doc.text("Thank you for your business!", 15, doc.lastAutoTable.finalY + 20); // Positioned below the table
  
    // Save the PDF
    doc.save(`invoice-${invoiceNumber}.pdf`);
  };
  return (
    <CustomLayout>
      <div className="container">
        <Row gutter={[16, 16]} className="flex-container">
          <Col xs={24} sm={24} md={8} className="menu">
            <SettingOption shopify={shopify} />
          </Col>
          <Col xs={24} sm={24} md={16} className="content">
            <div>
           
              <Table columns={columns} dataSource={data} loading={loading} />
              <Button type="primary"  onClick={generatePDF} style={{ marginBottom: 16,backgroundColor:'#710BE1' }}>
                Download Invoice
              </Button>
            </div>
          </Col>
        </Row>
      </div>
    </CustomLayout>
  );
}

export default CreditTransactionHistory;
