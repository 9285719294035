import React, { useState } from 'react';
import { Button, Steps, message, Input, Modal } from 'antd';
import CustomLayout from '../../layouts/Customlayout';
import SettingOption from './Settingsoptions';
import { ArrowRightOutlined } from '@ant-design/icons';
import jaweb from './Images/LogoJAWEB.jpg';
import './Css/zapier.css';  // Add custom styles

const { Step } = Steps;

export default function Zapier() {
    const [current, setCurrent] = useState(0);
    const [apiKey, setApiKey] = useState(localStorage.getItem('token') || '');
    const [isModalVisible, setIsModalVisible] = useState(false);
    const [modalContent, setModalContent] = useState({ title: '', description: '' });

    const steps = [
        {
            title: 'Get API Key',
            content: (
                <div>
                    <p> Please use this API key to connect with Zapier.</p>
                    <Input
                        value={apiKey}
                        readOnly
                    />
                </div>
            ),
        },
        {
            title: 'Login to Zapier',
            content: (
                <div>
                    <p>Log in to your Zapier account. If you don't have one, you can sign up at zapier.com.</p>
                    <Button href="https://zapier.com/sign-up" target="_blank">
                        Go to Zapier
                    </Button>
                </div>
            ),
        },
        {
            title: 'Create a Zap',
            content: (
                <div>
                    <p>Once logged in, create a new Zap and choose the app you want to integrate with Jaweb.</p>
                </div>
            ),
        },
        {
            title: 'Connect API Key',
            content: (
                <div>
                    <p>When prompted to connect an API key, use the key provided above.</p>
                </div>
            ),
        },
        {
            title: 'Test and Activate',
            content: (
                <div>
                    <p>Test the integration in Zapier. If everything is working correctly, activate your Zap.</p>
                </div>
            ),
        },
    ];

    const showModal = (title, description) => {
        setModalContent({ title, description });
        setIsModalVisible(true);
    };

    const handleCancel = () => {
        setIsModalVisible(false);
    };

    const next = () => {
        setCurrent(current + 1);
    };

    const prev = () => {
        setCurrent(current - 1);
    };

    return (
        <CustomLayout>
            <div className='flex flex-col md:flex-row gap-6'>
                <div className='md:w-1/6'>
                    <SettingOption />
                </div>
                <div className='flex-1'>
                    <div style={{ padding: '20px' }}>
                        <span style={{ fontSize: '17px', fontWeight: 'bold', paddingRight: '10px' }}>
                            Steps to Integrate Jaweb With Zapier
                        </span>

                        {/* Start of the intro section with images and invitation button */}
                        <div className='integration-intro' style={{ margin: '30px 0', textAlign: 'center' }}>
                            <div className='integration-logos flex justify-center items-center'>
                                <img 
                                    src={jaweb} 
                                    alt='Jaweb Logo' 
                                    className='logo-animation'
                                    style={{ width: '150px', marginRight: '20px' }} 
                                />
                                <ArrowRightOutlined style={{ fontSize: '32px', color: '#1890ff' }} />
                                <img 
                                    src='https://www.good2gosoftware.com/wp-content/uploads/2023/02/2560px-Zapier_logo.svg_-2048x938.png' 
                                    alt='Zapier Logo' 
                                    className='logo-animation'
                                    style={{ width: '120px', marginLeft: '20px' }} 
                                />
                            </div>
                            <p style={{ marginTop: '20px', fontSize: '16px' }}>
                               Jaweb is live on Zapier App Store! Click the link below to add the app to your account.
                            </p>
                            <Button
                                size='large'
                                href='https://zapier.com/apps/jaweb/integrations'
                                target='_blank'
                                className='invitation-button'
                            >
                                Get App 
                            </Button>
                        </div>
                        {/* End of intro section */}

                        {/* Integration Triggers and Actions */}
                        <div className="triggers-actions" style={{ margin: '30px 0', textAlign: 'center' }}>
                            <h3 style={{ fontWeight: 'bold' }}>Integration Features</h3>
                            <div className='flex justify-center gap-8'>
                                <Button 
                                    type="primary"
                                    onClick={() => showModal("Trigger: New Email Added to Chat Session", "Automatically triggers when a new email is added to an ongoing chat session, allowing seamless follow-up actions in Zapier.")}
                                >
                                    Trigger: New Email Added to Chat Session
                                </Button>
                                <Button 
                                    type="primary"
                                    onClick={() => showModal("Action: Send Email", "Automatically sends an email to a specified recipient based on conditions set in your Zap, keeping your contacts informed effortlessly.")}
                                >
                                    Action: Send Email to a Specified Email
                                </Button>
                            </div>
                        </div>

                        <Modal title={modalContent.title} visible={isModalVisible} onCancel={handleCancel} footer={null}>
                            <p>{modalContent.description}</p>
                        </Modal>

                        <div style={{ marginTop: '20px' }}>
                            <Steps current={current}>
                                {steps.map((item, index) => (
                                    <Step key={index} title={item.title} />
                                ))}
                            </Steps>
                            <div className="steps-content" style={{ marginTop: '20px' }}>
                                {steps[current].content}
                            </div>
                            <div className="steps-action" style={{ marginTop: '20px' }}>
                                {current < steps.length - 1 && (
                                    <Button type="primary" onClick={() => next()}>
                                        Next
                                    </Button>
                                )}
                                {current === steps.length - 1 && (
                                    <Button type="primary" onClick={() => message.success('Integration Complete!')}>
                                        Done
                                    </Button>
                                )}
                                {current > 0 && (
                                    <Button style={{ margin: '0 8px' }} onClick={() => prev()}>
                                        Previous
                                    </Button>
                                )}
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </CustomLayout>
    );
}
