import React, { useState, useEffect } from 'react';

import colorJawebLogo from '../../../assets/Onboarding/colorLogo.png';
import shopifyLogo from '../../../assets/Onboarding/shopifyLogo.svg';
import './EmailLogin.css'


const EmailLoginComponent = ({ username, setUsername, setIsEmailScreen, HandleLoginWithShopify }) => {
  const [errorMessage, setErrorMessage] = useState('');

  const handleEmailSubmit = () => {
    setErrorMessage('');

    if (!username) {
      setErrorMessage('Email address or username is required.');
      return;
    }
    setIsEmailScreen(false);
  };

  return (
    <div className="login-container">
      <img src={colorJawebLogo} alt="Jaweb logo" className="login-logo" />
      <h1 className="login-heading">What's your email address / username?</h1>
      <p className="login-subheading">Please provide your email address / username to access your account</p>
      <div style={{ marginTop: '40px' }}>
        <label className="login-input-label">Email Address / Username*</label>
        <input
          type="email"
          placeholder="Enter your email address / username"
          className={`login-input ${errorMessage ? 'error' : ''}`}
          value={username}
          onChange={(e) => setUsername(e.target.value)}
        />
        {errorMessage && <div className="error-message">{errorMessage}</div>}
      </div>
      <div style={{ margin: '40px 0px' }}>
        <button className="login-button" onClick={handleEmailSubmit}>Next</button>
      </div>
      <p className="login-signup-text">
        Don't have an account? <a href="/auth/signup" className="login-signup-link">Sign Up</a>
      </p>
      <button className="login-shopify-button" onClick={()=>HandleLoginWithShopify()}>
        Login with
        <img src={shopifyLogo} alt="Shopify icon" className="login-shopify-icon" />
      </button>
    </div>
  );
};

export default EmailLoginComponent;
