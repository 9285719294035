import React, { useEffect, useState } from 'react';
import { Image, Steps, Button, message, Progress, Spin, Input } from 'antd';
import CustomLayout from '../../layouts/Customlayout';
import Shopify1 from './Images/shopify1.png';
import Shopify2 from './Images/shopify2.png';
import Shopify4 from './Images/shopify4.png';
import axios from 'axios';
import { Prism as SyntaxHighlighter } from 'react-syntax-highlighter';
import { atomDark } from 'react-syntax-highlighter/dist/esm/styles/prism';
import { ShoppingFilled } from '@ant-design/icons';
import SettingOption from './Settingsoptions';
import config from '../../config';

export default function Shopify() {
    const [shop, setShop] = useState("");
    const [loading, setLoading] = useState(false);
    const [disabled, setDisabled] = useState(false);
    const [authenticated, setAuthenticated] = useState(false);
    const [spinWheel, setSpin] = useState(false);

    useEffect(() => {
        ShopifyAuth();
    }, []);

    const ShopifyAuth = async () => {
        setSpin(true);
        try {
            const response = await axios.get(`${config.apiUrl}shopify/?username=${localStorage.getItem('username')}`);
            if (response.data.length === 0) {
                setAuthenticated(false);
            } else {
                setAuthenticated(true);
            }
        } catch (error) {
            console.error('Error fetching chatlogs:', error);
            setAuthenticated(false);
        } finally {
            setSpin(false);
        }
    };


    return (
        <CustomLayout>

<div className='flex flex-col md:flex-row gap-6'>
        <div className='md:w-1/6'>
          <SettingOption />
        </div>
        <div className='flex-1'>
          <div style={{ padding: '20px' }}>
            <span style={{ fontSize: '17px', fontWeight: 'bold',paddingRight:'10px' }}>Steps to Install on Jaweb</span>
            <span>Follow These Steps to Install on Jaweb Plugin On Your Shopify Store</span>
          </div>

          <div style={{ padding: '20px' }}>
            <span style={{ fontWeight: 'bold' }}>Theme</span>
            <span className='ml-4'>Click on Online , Navigate to Themes and click on the Customize Button</span>
            <div className="image-container" style={{ marginTop: '20px' }}>
              <Image
                width="100%"
                src={Shopify1}
                placeholder={
                  <Image
                    preview={false}
                    src={Shopify1}
                    width="100%"
                  />
                }
              />
            </div>
          </div>
          <div style={{ padding: '20px' }}>
            <span style={{ fontWeight: 'bold' }}>App Embeds</span>
            <span className='ml-4'>Click on the third options like it shows in the image below</span>

            <div className="image-container" style={{ marginTop: '20px' }}>
              <Image
                width="100%"
                src={Shopify2}
                placeholder={
                  <Image
                    preview={false}
                    src={Shopify2}
                    width="100%"
                  />
                }
              />
            </div>
          </div>
          <div style={{ padding: '20px' }}>
            <span style={{ fontWeight: 'bold' }}>Toggle JawebChatbot</span>
            <span className='ml-4'>Toggle the JawebChatbot Plugin and click on Save</span>
            <div className="image-container" style={{ marginTop: '20px' }}>
              <Image
                width="100%"
                src={Shopify4}
                placeholder={
                  <Image
                    preview={false}
                    src={Shopify4}
                    width="100%"
                  />
                }
              />
            </div>
          </div>
         
          
        </div>
      </div>
            {/* <div>
                <div className='flex flex-col md:flex-row gap-6'>
                    <div className='md:w-1/6'>
                        <SettingOption />
                    </div>
                    <div>
                   
                        <div className='mb-2'>
                        <div className="mt-4">
				<p>Follow the steps below to  install Jaweb Plugin </p>
                <ol>
                    <li className='mt-4'>
                        <p>First, create a <code>xyz.html</code> file(choose any suitable name for your file) </p>
                    </li>

                    <li className='mt-2'>
                        <p>In your body Tag Add the code below</p>
                        <SyntaxHighlighter className="hl-code" language="javascript" style={atomDark}>
                        </SyntaxHighlighter>
                    </li>
                    <li>
                        <p>Also add the code below in your Body tag as well</p>
                        <SyntaxHighlighter className="hl-code" language="javascript" style={atomDark}>
                        </SyntaxHighlighter>
                    </li>
                    <li>
                        <strong>User Identification</strong>
                        <p>You can put this at the last line  of the project</p>
  
                    </li>
                </ol>
			</div>
                        

                        <Steps
                                direction="vertical"
                                size="small"
                                current={3}
                                items={[
                                {
                                    title: 'Theme',
                                   
                                    description:'Click on Online , Navigate to Themes and click on the Customize Button',
                                },
                                {
                                    title: 'App Embeds',
                                    description:'Click on the third options like it shows in the image below',
                                },
                                {
                                    title: 'Toggle JawebChatbot',
                                    description:'Toggle the JawebChatbot Plugin and click on Save',
                                },
                               
                                ]}
                            />
              
                        </div>
                        <div className='flex flex-row gap-6'>
                            <Image
                                width={400}
                                src={Shopify1}
                                placeholder={<Image preview={false} src={Shopify1} width={200} />}
                            />
                           
                        </div>

                        <div className='flex flex-row gap-6'>
                            <Image
                                width={400}
                                src={Shopify2}
                                placeholder={<Image preview={false} src={Shopify2} width={200} />}
                            />
                           
                        </div>

                        <div className='flex flex-row gap-6'>
                            <Image
                                width={400}
                                src={Shopify4}
                                placeholder={<Image preview={false} src={Shopify4} width={200} />}
                            />
                           
                        </div>
                    </div>
                </div>
            </div> */}
        </CustomLayout>
    );
}
