import React, { useState, useEffect } from 'react';
import onboardingScreen1 from '../../../assets/Onboarding/onboarding1.svg';
import onboardingScreen2 from '../../../assets/Onboarding/onboarding2.svg';
import onboardingScreen3 from '../../../assets/Onboarding/onboarding3.svg';
import '../Onboarding.css';

export default function OnboardingSlideshow() {
    const [currentIndex, setCurrentIndex] = useState(0);

    const slides = [
        { id: 1, img: onboardingScreen1},
        { id: 2, img: onboardingScreen2 },
        { id: 3, img: onboardingScreen3 },
    ];

    useEffect(() => {
        const interval = setInterval(() => {
            setCurrentIndex((prevIndex) => (prevIndex + 1) % slides.length);
        }, 3000);

        return () => clearInterval(interval);
    }, [slides.length]);

    return (
        <div style={{ flex: '1', position: 'relative', height: '100%', overflow: 'hidden' }}>
            {slides.map((slide, index) => (
                <div
                    key={slide.id}
                    style={{
                        opacity: index === currentIndex ? 1 : 0,
                        transition: 'opacity 1s ease-in-out',
                        position: 'absolute',
                        top: 0,
                        left: 0,
                        width: '100%',
                        height: '100%',
                        background: `url(${slide.img}) no-repeat center center/cover`,
                        zIndex: index === currentIndex ? 1 : 0,
                    }}
                />
            ))}
        </div>
    );
}
