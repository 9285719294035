import React, { useState, useEffect, useRef } from 'react';
import PropTypes from 'prop-types';
import WaveSurfer from 'wavesurfer.js';
import { FaPlayCircle, FaPauseCircle } from 'react-icons/fa';

const Waveform = ({ audioUrl }) => {
  const containerRef = useRef();
  const waveSurferRef = useRef();
  const [isPlaying, setIsPlaying] = useState(false);

  useEffect(() => {
    const loadAudio = async () => {
      try {
        const response = await fetch(audioUrl);
        const audioBlob = await response.blob();
        const audioBlobUrl = URL.createObjectURL(audioBlob);

        const waveSurferInstance = WaveSurfer.create({
          container: containerRef.current,
          responsive: true,
          barWidth: 2,
          barHeight: 10,
          cursorWidth: 0,
        });

        waveSurferInstance.load(audioBlobUrl);
        waveSurferInstance.on('ready', () => {
          waveSurferRef.current = waveSurferInstance;
        });
      } catch (error) {
        console.error('Error loading audio:', error);
      }
    };

    loadAudio();

    return () => {
      if (waveSurferRef.current) {
        waveSurferRef.current.destroy();
      }
    };
  }, [audioUrl]);

  const togglePlayPause = () => {
    if (waveSurferRef.current) {
      const isPlaying = waveSurferRef.current.isPlaying();
      isPlaying ? waveSurferRef.current.pause() : waveSurferRef.current.play();
      setIsPlaying(!isPlaying);
    }
  };

  return (
    <div style={{ display: 'grid', gridTemplateColumns: '40px 1fr', alignItems: 'center' }}>
      <button
        onClick={togglePlayPause}
        type="button"
        style={{
          width: '40px',
          height: '40px',
          border: 'none',
          padding: '0',
          backgroundColor: 'white',
          marginRight:'20px'
        }}
      >
        {isPlaying ? <FaPauseCircle size="3em" /> : <FaPlayCircle size="3em" />}
      </button>
      <div ref={containerRef} />
    </div>
  );
};

Waveform.propTypes = {
  audioUrl: PropTypes.string.isRequired,
};

export default Waveform;
