import React from 'react'
import QuestionList from './components/QuestionList'
import CustomLayout from '../layouts/Customlayout'



export default function
    () {
    return (
        <CustomLayout>
             <div className='help_main'>
      Coming Soon !</div>
        </CustomLayout>
    )
}
