import React, { useState, useEffect } from 'react';
import OnboardingSlideshow from './OnboardingComponent/OnboardingSlideshow';
import colorJawebLogo from '../../assets/Onboarding/colorLogo.png';
import { EyeOutlined, EyeInvisibleOutlined } from '@ant-design/icons';
import axios from 'axios';
import config from '../../config';
import { message } from 'antd';
import './SignupV2.css'
import { useNavigate, useLocation } from 'react-router-dom';



export default function PasswordResetV2() {
  const [windowWidth, setWindowWidth] = useState(window.innerWidth);
  const [currentStep, setCurrentStep] = useState(0);
  const [otp, setOtp] = useState(['', '', '', '', '', '']);
  const [isOtpError, setIsOtpError] = useState(false);

  const [generatedOtp, setGeneratedOtp] = useState('');
  const [timer, setTimer] = useState(60);

  const [password, setPassword] = useState('');
  const [confirmPassword, setConfirmPassword] = useState('');
  const [showPassword, setShowPassword] = useState(false);
  const [showConfirmPassword, setShowConfirmPassword] = useState(false);
  const [disableBtn, setDisableBtn] = useState(false);

  const [email , setEmail] = useState('');
  const navigate = useNavigate();

  
  useEffect(() => {
    const handleResize = () => {
      setWindowWidth(window.innerWidth);
    };

    window.addEventListener('resize', handleResize);
    return () => {
      window.removeEventListener('resize', handleResize);
    };
  }, []);

  useEffect(() => {
    console.log(currentStep, '-----------------step');
    
    if (currentStep === 1) {
      handleSendOtp()
      const interval = setInterval(() => {
        setTimer((prevTimer) => (prevTimer > 0 ? prevTimer - 1 : 0));
      }, 1000);
      
      return () => clearInterval(interval);
    }

    
    
  }, [currentStep]);


  const handleSendOtp = async () => {
    // console.log(formData, '--dada');
    
    const otp = generateRandomNumberString(6);
    setGeneratedOtp(otp);
    console.log(otp,'----------------otp');
    

    try {
      
      const response = await axios.post(`${config.apiUrl}user/send-otp`, { email, otp, type: 'reset' });

      if (response.status === 200) {
        message.success('OTP sent successfully');
      } else {
        message.error('Failed to send OTP');
      }
    } catch (error) {
      if (error.name === 'Error' && error.errorFields) {
        message.error('Please fill out all required fields correctly.');
      } else {
        message.error('Error sending OTP');
      }
    }
  };

  const handleNextStep = (e) => {
    e.preventDefault();
    setCurrentStep(currentStep + 1);
  };

  const handleOtpChange = (index, value) => {
    if (value.length <= 1 && /^\d*$/.test(value)) {
      const newOtp = [...otp];
      newOtp[index] = value;
      setOtp(newOtp);
      setIsOtpError(false);
      if (value && index < 5) {
        document.getElementById(`otp-${index + 1}`).focus();
      }
    }
  };

  const handleKeyDown = (index, event) => {
    if (event.key === 'Backspace' && otp[index] === '') {
      if (index > 0) {
        document.getElementById(`otp-${index - 1}`).focus();
      }
    }
  };

  const generateRandomNumberString = (length) => {
    let result = '';
    for (let i = 0; i < length; i++) {
      const randomDigit = Math.floor(Math.random() * 10);
      result += randomDigit;
    }
    return result;
  };


  const handleResendOtp = async (e) => {
    e.preventDefault();
    setTimer(60);

    const otp = generateRandomNumberString(6);
    setGeneratedOtp(otp);
    console.log(otp,'----------------re otp');

    try {
      
      const response = await axios.post(`${config.apiUrl}user/send-otp`, { email, otp, type: 'reset' });

      if (response.status === 200) {
        message.success('OTP sent successfully');
      } else {
        message.error('Failed to send OTP');
      }
    } catch (error) {
      if (error.name === 'Error' && error.errorFields) {
        message.error('Please fill out all required fields correctly.');
      } else {
        message.error('Error sending OTP');
      }
    }

  }

  const handleOtpVerification = (e) => {
    e.preventDefault();
    
    if (otp.join('') != generatedOtp) {
      setIsOtpError(true);
      return;
    }
    
    setIsOtpError(false);
    handleNextStep(e);
  };

  const handleResetPassword = async (e) => { 
    e.preventDefault();
    console.log(password, email);
    
    try {
      const response = await axios.post(`${config.apiUrl}user/passwordreset`, { email, "new_password":password });

      if (response.status === 200) {
        message.success('Password reset successfully');
        navigate('/auth/login');
      } else {
        message.error('Failed to reset password');
      }
    } catch (error) {
      message.error('Error resetting password');
    }
  };

  return (
    <div style={{ display: 'flex', height: '100vh' }}>
      {windowWidth >= 1000 && <OnboardingSlideshow />}

      <div style={{ flex: '1' }}>

        {currentStep === 0 && (
          <div className="signup-container">
            <img src={colorJawebLogo} alt="Jaweb logo" className="signup-logo" />

            <h1 className="signup-title">What's your email address?</h1>
            <p className="signup-subtitle">
              This will be your registered address for all the essential communications
            </p>
            <form onSubmit={handleNextStep}>
              <div className="signup-form-group">
                <label className="signup-label">Email Address*</label>
                <input
                  type="email"
                  name="email"
                  value={email}
                  onChange={(e)=> setEmail(e.target.value)}
                  placeholder="Enter your email address"
                  className={`signup-input`}
                />

              </div>
              <button type="submit" className="signup-button">
                Send Code
              </button>
            </form>
          </div>
        )}
        {
          currentStep === 1 && (
            <div className="signup-container">
            <img src={colorJawebLogo} alt="Jaweb logo" className="signup-logo" />

            <h1 className="signup-title">Don’t remember your password?</h1>
            <p className="signup-subtitle">
              Please enter your email to reset your password
            </p>
            <form onSubmit={handleOtpVerification}>
              <div className="signup-form-group">
                <label className="signup-label">Email Address*</label>
                <input
                  type="email"
                  name="email"
                  value={email}
                  onChange={(e)=> setEmail(e.target.value)}
                  placeholder="Enter your email address"
                  className={`signup-input`}
                />

                </div>
                <div className="signup-form-group">
              <p className="signup-timer-text">OTP</p>
              <div className="signup-otp-container">
                {otp.map((digit, index) => (
                  <input
                    key={index}
                    id={`otp-${index}`}
                    type="text"
                    maxLength="1"
                    className={`signup-otp-input ${isOtpError ? 'otp-input-signup-error' : ''}`}
                    value={digit}
                    onChange={(e) => handleOtpChange(index, e.target.value)}
                    onKeyDown={(e) => handleKeyDown(index, e)}
                    required
                  />
                ))}
                  </div>
                  
              {isOtpError && (
                <p className="signup-error-text">The OTP you've entered is invalid</p>
              )}
              <p className="signup-timer-text">{timer} sec</p>
              <p 
                className="signup-resend-link" 
                onClick={(e)=>(handleResendOtp(e))}
              >
                Didn't receive the code? Re-send OTP
              </p>
                </div>
              <button type="submit" className="signup-button" disabled={otp.some(digit => !digit)}>
                Confirm
              </button>
            </form>
          </div> 
          )
        }
   
        {currentStep === 2 && (
          <div className="signup-container">
            <img src={colorJawebLogo} alt="Jaweb logo" className="signup-logo" />

            <h1 className="signup-title">Let's help you create a new password</h1>
            <p className="signup-subtitle">
              Please create your password
            </p>
            <form onSubmit={handleResetPassword}>
              <div className="signup-form-group">
                <label className="signup-label">Password*</label>
                <div className="signup-input-wrapper">
                  <input
                    type={showPassword ? 'text' : 'password'}
                    name="password"
                    value={password}
                    onChange={(e) => setPassword(e.target.value)}
                    className={`signup-input`}
                    placeholder="Enter your password"
                  />
                  <div className="signup-icon" onClick={() => setShowPassword(!showPassword)}>
                    {showPassword ? <EyeOutlined size={20} /> : <EyeInvisibleOutlined size={20} />}
                  </div>
                </div>

              </div>
              <div className="signup-form-group">
                <label className="signup-label">Confirm Password*</label>
                <div className="signup-input-wrapper">
                  <input
                    type={showConfirmPassword ? 'text' : 'password'}
                    name="confirmPassword"
                    value={confirmPassword}
                    onChange={(e)=>{setConfirmPassword(e.target.value)}}
                    className={`signup-input ${confirmPassword ? 'input-error' : ''}`}
                    placeholder="Confirm your password"
                  />
                  <div className="signup-icon" onClick={() => setShowConfirmPassword(!showConfirmPassword)}>
                    {showConfirmPassword ? <EyeOutlined size={20} /> : <EyeInvisibleOutlined size={20} />}
                  </div>
                </div>
                {confirmPassword && (
                  <p className="error-message">{(confirmPassword != password)&& 'Password do not match' }</p>
                )}
              </div>
              <button 
                type="submit" 
                className="signup-button"
                disabled={!password || !confirmPassword}
              >
                Reset Password
              </button>
            </form>
          </div>
        )}


      </div>
    </div>
  );
}
