export const LanguagesOptions = [
    {
        "name": "Abkhaz",
        "code": "ab"
    },
    {
        "name": "Afar",
        "code": "aa"
    },
    {
        "name": "Afrikaans",
        "code": "af"
    },
    {
        "name": "Akan",
        "code": "ak"
    },
    {
        "name": "Albanian",
        "code": "sq"
    },
    {
        "name": "Amharic",
        "code": "am"
    },
    {
        "name": "Arabic",
        "code": "ar"
    },
    {
        "name": "Aragonese",
        "code": "an"
    },
    {
        "name": "Armenian",
        "code": "hy"
    },
    {
        "name": "Assamese",
        "code": "as"
    },
    {
        "name": "Avar",
        "code": "av"
    },
    {
        "name": "Avestan",
        "code": "ae"
    },
    {
        "name": "Aymara",
        "code": "ay"
    },
    {
        "name": "Azerbaijani",
        "code": "az"
    },
    {
        "name": "Bambara",
        "code": "bm"
    },
    {
        "name": "Basque",
        "code": "eu"
    },
    {
        "name": "Belarusian",
        "code": "be"
    },
    {
        "name": "Bengali",
        "code": "bn"
    },
    {
        "name": "Bihari",
        "code": "bh"
    },
    {
        "name": "Bislama",
        "code": "bi"
    },
    {
        "name": "Bosnian",
        "code": "bs"
    },
    {
        "name": "Bulgarian",
        "code": "bg"
    },
    {
        "name": "Burmese",
        "code": "my"
    },
    {
        "name": "Catalan",
        "code": "ca"
    },
    {
        "name": "Cherokee",
        "code": "chr"
    },
    {
        "name": "Chinese",
        "code": "zh"
    },
    {
        "name": "Corsican",
        "code": "co"
    },
    {
        "name": "Croatian",
        "code": "hr"
    },
    {
        "name": "Czech",
        "code": "cs"
    },
    {
        "name": "Danish",
        "code": "da"
    },
    {
        "name": "Dutch",
        "code": "nl"
    },
    {
        "name": "English",
        "code": "en"
    },
    {
        "name": "Esperanto",
        "code": "eo"
    },
    {
        "name": "Estonian",
        "code": "et"
    },
    {
        "name": "Fijian",
        "code": "fj"
    },
    {
        "name": "Finnish",
        "code": "fi"
    },
    {
        "name": "French",
        "code": "fr"
    },
    {
        "name": "Galician",
        "code": "gl"
    },
    {
        "name": "Georgian",
        "code": "ka"
    },
    {
        "name": "German",
        "code": "de"
    },
    {
        "name": "Greek",
        "code": "el"
    },
    {
        "name": "Guarani",
        "code": "gn"
    },
    {
        "name": "Gujarati",
        "code": "gu"
    },
    {
        "name": "Haitian",
        "code": "ht"
    },
    {
        "name": "Hausa",
        "code": "ha"
    },
    {
        "name": "Hebrew",
        "code": "he"
    },
    {
        "name": "Hindi",
        "code": "hi"
    },
    {
        "name": "Hungarian",
        "code": "hu"
    },
    {
        "name": "Icelandic",
        "code": "is"
    },
    {
        "name": "Igbo",
        "code": "ig"
    },
    {
        "name": "Indonesian",
        "code": "id"
    },
    {
        "name": "Irish",
        "code": "ga"
    },
    {
        "name": "Italian",
        "code": "it"
    },
    {
        "name": "Japanese",
        "code": "ja"
    },
    {
        "name": "Javanese",
        "code": "jv"
    },
    {
        "name": "Kyrgyz",
        "code": "ky"
    },
    {
        "name": "Korean",
        "code": "ko"
    },
    {
        "name": "Kurdish",
        "code": "ku"
    },
    {
        "name": "Kyrgyz",
        "code": "kg"
    },
    {
        "name": "Lao",
        "code": "lo"
    },
    {
        "name": "Latvian",
        "code": "lv"
    },
    {
        "name": "Lithuanian",
        "code": "lt"
    },
    {
        "name": "Luxembourgish",
        "code": "lb"
    },
    {
        "name": "Macedonian",
        "code": "mk"
    },
    {
        "name": "Malagasy",
        "code": "mg"
    },
    {
        "name": "Malay",
        "code": "ms"
    },
    {
        "name": "Maltese",
        "code": "mt"
    },
    {
        "name": "Maori",
        "code": "mi"
    },
    {
        "name": "Marathi",
        "code": "mr"
    },
    {
        "name": "Mongolian",
        "code": "mn"
    },
    {
        "name": "Nepali",
        "code": "ne"
    },
    {
        "name": "Norwegian",
        "code": "no"
    },
    {
        "name": "Pashto",
        "code": "ps"
    },
    {
        "name": "Persian",
        "code": "fa"
    },
    {
        "name": "Polish",
        "code": "pl"
    },
    {
        "name": "Portuguese",
        "code": "pt"
    },
    {
        "name": "Punjabi",
        "code": "pa"
    },
    {
        "name": "Romanian",
        "code": "ro"
    },
    {
        "name": "Russian",
        "code": "ru"
    },
    {
        "name": "Samoan",
        "code": "sm"
    },
    {
        "name": "Scots Gaelic",
        "code": "gd"
    },
    {
        "name": "Serbian",
        "code": "sr"
    },
    {
        "name": "Sesotho",
        "code": "st"
    },
    {
        "name": "Shona",
        "code": "sn"
    },
    {
        "name": "Sindhi",
        "code": "sd"
    },
    {
        "name": "Sinhala",
        "code": "si"
    },
    {
        "name": "Slovak",
        "code": "sk"
    },
    {
        "name": "Slovenian",
        "code": "sl"
    },
    {
        "name": "Somali",
        "code": "so"
    },
    {
        "name": "Spanish",
        "code": "es"
    },
    {
        "name": "Swahili",
        "code": "sw"
    },
    {
        "name": "Swedish",
        "code": "sv"
    },
    {
        "name": "Tajik",
        "code": "tg"
    },
    {
        "name": "Tamil",
        "code": "ta"
    },
    {
        "name": "Tatar",
        "code": "tt"
    },
    {
        "name": "Telugu",
        "code": "te"
    },
    {
        "name": "Thai",
        "code": "th"
    },
    {
        "name": "Turkish",
        "code": "tr"
    },
    {
        "name": "Turkmen",
        "code": "tk"
    },
    {
        "name": "Ukrainian",
        "code": "uk"
    },
    {
        "name": "Urdu",
        "code": "ur"
    },
    {
        "name": "Uzbek",
        "code": "uz"
    },
    {
        "name": "Vietnamese",
        "code": "vi"
    },
    {
        "name": "Welsh",
        "code": "cy"
    },
    {
        "name": "Xhosa",
        "code": "xh"
    },
    {
        "name": "Yiddish",
        "code": "yi"
    },
    {
        "name": "Yoruba",
        "code": "yo"
    },
    {
        "name": "Zulu",
        "code": "zu"
    }
];


export const IndustriesOptions = [
    "Agriculture",
    "Automotive",
    "Aerospace",
    "Construction",
    "Consumer Goods",
    "Education",
    "Energy",
    "Entertainment",
    "Financial Services",
    "Healthcare",
    "Hospitality and Tourism",
    "Information Technology",
    "Insurance",
    "Manufacturing",
    "Media and Communications",
    "Mining and Metals",
    "Pharmaceuticals",
    "Real Estate",
    "Retail",
    "Telecommunications",
    "Transportation and Logistics",
    "Utilities",
    "Waste Management",
    "Chemicals",
    "Textiles and Apparel"
];
