import React, { useState,useEffect } from 'react';
import { useNavigate } from "react-router-dom";
import Spinner from '../components/Spinner';
import Warning from '../components/Warning';
import Success from '../components/Success';
import { Form, Avatar, Button, Input, DatePicker, Row, Col, message, Upload } from 'antd';
import bg from '../../../src/assets/svg/bg.png'

function Verify() {
  const [code, setCode] = useState('');

  const [spin,Setspin]=useState(false)
  const [failed,setFailed]=useState(false)
  const [success,setSuccess]=useState(false)
  const [Failedmessage,setFailedmessage]=useState('')
  const navigate = useNavigate();


  function spinner() {
    // Your spinner logic here
  }





  function handleSubmit(event) {
    event.preventDefault();
    Setspin(true);
  
    let body = JSON.stringify({
      'otp': code,
      'username':localStorage.getItem('username')

    });
  
    fetch('https://jawebcrm.onrender.com/api/user/verify', {
      method: 'POST',
      headers: {
        'Content-Type': 'application/json'
      },
      body: body
    })
      .then(res => {
        if (res.ok) {
          return res.json();
        } else {
 
  
          // Handle error response here
          return res.json().then(error => {
  
            // Check for specific error messages
            if (error.msg === 'invalid') {
       
              message.error({ content: "Invalid code", duration: 4 });
              Setspin(false)
     

            } 
  
            throw error;
          });
        }
      })
      .then(json => {
        Setspin(false);
        message.success({ content: "Valid code", duration: 4 });

  
        setTimeout(() => {
          navigate('/');
          Setspin(false);
        }, 3000);
     
      })
      .catch(error => {
        Setspin(false);
        // Handle additional error scenarios here
        console.error("Catch Block Error:", error);
      });
  }
  

  

  return (
    <div className="bg-[#F3E6FF] flex h-screen flex-1">

      <div className='hidden md:block'>
              <img className='h-screen' src={bg}/>
          </div>


    <div className='flex flex-col items-center flex-1 p-6 mt-32'>

  





        {failed ? (
        <Warning message={Failedmessage}/>
      ) : success ? (
        <Success message='Email Verified Successfully'/>
      ) : (
        <div>
        <img  className='h-[59.69px] w-[235.62px]' src='https://jawebstorage.us-iad-1.linodeobjects.com/images/Jaweb color logo.png'/>
      </div>
      )}      
          

        <div>
            <span className='font-bold text-lg'>Confirm Email</span>
        </div>





          <div className="mb-2">
          <label for="phone" className="block text-sm font-semibold text-gray-800">
              Code
          </label>
          <input 
          name="" 
          type="text" 
          placeholder='4 Digits Code'
          value={code}
          onChange={(e) => setCode(e.target.value)}

          className="block w-full px-6 pr-36 py-1 mt-1 text-black-700 bg-white border rounded-md focus:border-black-400 focus:ring-black-300 focus:outline-none focus:ring focus:ring-opacity-40"  required/>
        
          </div>







          <div className="mt-6">


          {spin?
            <Spinner/>
            :
            <button
              onClick={handleSubmit}
              className="w-full px-36 py-2 tracking-wide text-white transition-colors duration-200 transform bg-gray-900 rounded-md hover:bg-gray-900 focus:outline-none focus:bg-gray-900"

            >
              <span>Verfiy code</span>
            </button>}



  
          </div>



    </div>

  </div>
  );
}

export default Verify;
