import React, { Component } from 'react';
import { List, Switch, Menu, Row, Col,Flex } from 'antd';
import { 
	MailOutlined,
	ShoppingOutlined,
	FileDoneOutlined,
	UserOutlined,
	LockOutlined,
	BellOutlined,
	CreditCardOutlined,
	BookOutlined
} from '@ant-design/icons';
import { Link, useLocation } from 'react-router-dom';
import CustomLayout from '../../layouts/Customlayout';
import './Css/AccountSettings.css'; // Import the CSS file with responsive styles

import { withTranslation } from 'react-i18next';
import SettingOption from './components/MenuItems';

export class Notification extends Component {
	state = {
		config: [
			{
				key: 'key-email',
				title: 'accountSetting.emailNoti',
				icon: MailOutlined,
				desc: 'accountSetting.emailNotiNote',
				allow: true,
			},
			{
				key: 'key-task',
				title: 'accountSetting.chatNoti',
				icon: FileDoneOutlined,
				desc: 'accountSetting.chatNotiNote',
				allow: true,
			},
		],
	};

	render() {
		const UserInfo = JSON.parse(localStorage.getItem('UserObject'));

		const shopify = UserInfo?.mode === 'shopify';

		const { config } = this.state;
		const { t } = this.props;


		return (
			<CustomLayout>
				<div className="container">
					<Row gutter={[16, 16]} className="flex-container">
						<Col xs={24} sm={24} md={8} className="menu">
							<SettingOption shopify={shopify} />
						</Col>
						<Col xs={24} sm={24} md={16} className="content">
							<List
								itemLayout="horizontal"
								dataSource={config}
								renderItem={item => (
									<List.Item>
										<Flex className="w-full justify-between items-center">
											<div className="flex items-center">
												<item.icon className="text-2xl font-bold mb-0 text-[#7F28F8]" />
												<div className="ml-3">
													<h4 className="mb-0">{t(item.title)}</h4>
													<p>{t(item.desc)}</p>
												</div>
											</div>
											<div className="ml-3">
												<Switch
													defaultChecked={item.allow}
													onChange={checked => {
														const checkedItem = config.map(elm => {
															if (elm.key === item.key) {
																elm.allow = checked;
															}
															return elm;
														});
														this.setState({
															config: [...checkedItem],
														});
													}}
													className="switcher_clr"
												/>
											</div>
										</Flex>
									</List.Item>
								)}
							/>
						</Col>
					</Row>
				</div>
			</CustomLayout>
		);
	}
}

export default withTranslation()(Notification);
