import React from 'react'
import './Css/sidebar.css'
import {
ViewColumnsIcon,EnvelopeIcon,ChatBubbleBottomCenterIcon,UserGroupIcon, UserIcon, CogIcon, ArrowLeftEndOnRectangleIcon, CodeBracketIcon, AdjustmentsHorizontalIcon, PhoneIcon
} from '@heroicons/react/20/solid'
import { useNavigate } from 'react-router-dom';
import { message,key } from 'antd';
import { BsQuestionSquareFill } from "react-icons/bs";



export default function Sidebar() {
    const navigate = useNavigate();

  return (
  

    <div  className="px-8 w-64 absolute min-h-screen sm:relative bg-[#7F28F8] text-white shadow  flex-col justify-between hidden sm:flex sidebar-width">
            <div className='mr-6' >
              <img className='' alt='company-alt' src='https://jawebstorage.us-iad-1.linodeobjects.com/images/Jaweb_logo_white.png' />
            </div>
            <ul className=" flex flex-col flex-1">
              {/* <li className="flex w-full   text-gray-300 cursor-pointer items-center mb-10">
                    <AdjustmentsHorizontalIcon width={25} className='text-white'/>
                    <a href="/user/leads" className="flex items-center focus:outline-none">
                        <span className="text-sm ml-2">Dashboard</span>
                  
                    </a> 
                    
                  
                </li> */}
                <li className="flex w-full justify-between text-gray-400 hover:text-gray-300 cursor-pointer items-center mb-10">
                    <a href="/user/leads" className="flex items-center focus:outline-none">
                      <EnvelopeIcon width={25} className='text-white'/>

                        <span className="text-sm ml-2 text-white">Leads</span>
                    </a>
                </li>
                <li className="flex w-full justify-between text-gray-400 hover:text-gray-300 cursor-pointer items-center mb-10">
                  <a  href="/user/messages/none" className="flex items-center focus:outline-none">
                      
                  <ChatBubbleBottomCenterIcon width={25} className='text-white'/>

                        <span className="text-sm ml-2 text-white">Messages</span>
                        {localStorage.getItem('unreads')?<span class="content-message-unread ml-4">{localStorage.getItem('unreads')}+</span>:
                        <></>
}

                    </a>
                </li>
                <li className="flex w-full justify-between text-gray-400 hover:text-gray-300 cursor-pointer items-center mb-10">
                    <a href="/user/integration" className="flex items-center focus:outline-none" >
                    <CodeBracketIcon width={25} className='text-white'/>

                        <span className="text-sm ml-2 text-white">Integration</span>
                    </a>
                </li>
                <li className="flex w-full justify-between text-gray-400 hover:text-gray-300 cursor-pointer items-center mb-10">
                    <a href="/user/Adminusers" className="flex items-center focus:outline-none">
                    <UserGroupIcon width={25} className='text-white'/>
                        <span className="text-sm ml-2 text-white">Users</span>
                    </a>
                </li>
                <li className="flex w-full justify-between text-gray-400 hover:text-gray-300 cursor-pointer items-center mb-10 disabled">
                    <a href="/user/Callcenter" className="flex items-center focus:outline-none">
                        <PhoneIcon width={25} className='text-white'/>
                        <span className="text-sm ml-2 text-white">Call Center</span>
                    </a>
                </li>

                <li className="flex w-full justify-between text-gray-400 hover:text-gray-300 cursor-pointer items-center mb-10">
                  <a href="/user/edit-profile" className="flex items-center focus:outline-none">
                  <UserIcon width={25} className='text-white'/>

                      <span className="text-sm ml-2 text-white">Account Settings</span>
                  </a>
              </li>
                <li className="flex w-full justify-between text-gray-400 hover:text-gray-300 cursor-pointer items-center mb-10">
                    <a href="/user/settings" className="flex items-center focus:outline-none">
                    <CogIcon width={25} className='text-white'/>
                        <span className="text-sm ml-2 text-white">Bot Training</span>
                    </a>
                </li>
                <li className="flex w-full justify-between text-gray-400 hover:text-gray-300 cursor-pointer items-center  cus-pad-left">
                  <a href="/user/questions" className="flex items-center focus:outline-none">
                  <BsQuestionSquareFill  width={25} className='text-white'/>
                 <span className="text-sm ml-2 text-white">Questions</span>
                  </a>
              </li>

                <li className="flex w-full justify-between text-gray-400 hover:text-gray-300 cursor-pointer items-center mt-10">
                    <a  onClick={()=>{
                        localStorage.clear();
                        message.error({ content: 'Successfully Logged out', duration: 4 });
                        setTimeout(() => {
                            navigate("/auth/login")
                        }, 3000);
                      
                    }} className="flex items-center focus:outline-none">
                    <ArrowLeftEndOnRectangleIcon width={25} className='text-white'/>
                        <span className="text-sm ml-2 text-white">Logout</span>
                    </a>
                </li>
            </ul>

           
        </div>


  )
}
