import React, { useState, useEffect, useRef } from 'react';
import CustomLayout from '../layouts/Customlayout';
import './../Pages/Css/Analytics.css'
import { RiseOutlined } from '@ant-design/icons';
import { PieChart, Pie, Cell, AreaChart, Area, LineChart, BarChart, Bar, Line, XAxis, YAxis, CartesianGrid, Tooltip, Legend, ResponsiveContainer } from 'recharts';
import axios from 'axios';
import config from '../config';
import { useTranslation } from 'react-i18next';

export default function AnalyticDashboard() {
  const mode = JSON.parse(localStorage.getItem('UserObject')).mode;
  const { t } = useTranslation();
  const analyticsTranslation = t("analytics");
  const isMounted = useRef(true);
  
  const [chatbotCards, setChatbotCards] = useState([
    { title: "Total Chatlogs", value: '--', percent: "0%" },
    { title: "Unique User", value: '--', percent: "0%" },
    { title: "Avg. Message Per Session", value: '--', percent: "0%" },
    { title: "User Retention", value: '--', percent: "0%" }
  ]);

  const [whatsappCards, setWhatsappCards] = useState([
    { title: "Total Chatlogs", value: '--', percent: "0%" },
    { title: "Unique User", value: '--', percent: "0%" },
    { title: "Avg. Message Per Session", value: '--', percent: "0%" },
    { title: "Retended User", value: '--', percent: "0%" }
  ]);

  const [lineChartData, setLineChartData] = useState([
    { name: 'Jan', thisYear: 0, lastYear: 0 },
    { name: 'Feb', thisYear: 0, lastYear: 0 },
    { name: 'Mar', thisYear: 0, lastYear: 0 },
    { name: 'Apr', thisYear: 0, lastYear: 0 },
    { name: 'May', thisYear: 0, lastYear: 0 },
    { name: 'Jun', thisYear: 0, lastYear: 0 },
  ]);

  const [barChartdata, setBarChartData] = useState([
    { month: "Jan", thisYear: 0, lastYear: 0 },
    { month: "Feb", thisYear: 0, lastYear: 0 },
    { month: "Mar", thisYear: 0, lastYear: 0 },
    { month: "Apr", thisYear: 0, lastYear: 0 },
    { month: "May", thisYear: 0, lastYear: 0 },
    { month: "Jun", thisYear: 0, lastYear: 0 },
    { month: "Jul", thisYear: 0, lastYear: 0 },
    { month: "Aug", thisYear: 0, lastYear: 0 },
    { month: "Sep", thisYear: 0, lastYear: 0 },
    { month: "Oct", thisYear: 0, lastYear: 0 },
    { month: "Nov", thisYear: 0, lastYear: 0 },
    { month: "Dec", thisYear: 0, lastYear: 0 }
  ]);

  const [piedata, setPieData] = useState([
    { name: 'Confirmed Resolutions', value: 0, color: '#1F2937', rating: '5' },
    { name: 'Assumed Resolutions', value: 0, color: '#C4B5FD', rating: '4' },
    { name: 'No Resolutions', value: 100, color: '#7C3AED', rating: '3' },
    { name: 'Unclear', value: 0, color: '#A78BFA', rating: '2' },
    { name: 'Need Improvement', value: 0, color: '#9B1C31', rating: '1' },
  ]);

  const [areadata, setAreaData] = useState([
    { month: 'Jan', value: 0 },
    { month: 'Feb', value: 0 },
    { month: 'Mar', value: 0 },
    { month: 'Apr', value: 0 },
    { month: 'May', value: 0 },
    { month: 'June', value: 0 },
  ]);

  useEffect(() => {
    return () => {
      isMounted.current = false;
    };
  }, []);

  useEffect(() => {
    const controller = new AbortController();
    
    const fetchChatbotAnalytics = async () => {
      const headers = {
        headers: {
          'Content-Type': 'application/json',
          'Authorization': `Token ${localStorage.getItem('token')}`,
          'Accept': 'application/json'
        },
        params: {
          company_username: localStorage.getItem('username')
        },
        signal: controller.signal
      };

      try {
        const res = await axios.get(`${config.apiUrl}chatbot-analytics/`, headers);
        if (isMounted.current) {
          const tempChatlogAnalysis = [
            { title: "Total Chatlogs", value: res.data.data.chatlogs, percent: "0%" },
            { title: "Unique User", value: res.data.data.unique_users, percent: "0%" },
            { title: "Avg. Message Per Session", value: `${(res.data.data.average_messages_per_session).toFixed(2)} mps`, percent: "0%" },
            { title: "User Retention", value: res.data.data.user_retention, percent: "0%" }
          ];
          setChatbotCards(tempChatlogAnalysis);
        }
      } catch (error) {
        if (!axios.isCancel(error) && isMounted.current) {
          console.error('Error fetching chatbot analytics:', error);
          const tempChatlogAnalysis = [
            { title: "Total Chatlogs", value: '0', percent: "0%" },
            { title: "Unique User", value: '0', percent: "0%" },
            { title: "Avg. Message Per Session", value: '0', percent: "0%" },
            { title: "User Retention", value: '0', percent: "0%" }
          ];
          setChatbotCards(tempChatlogAnalysis);
        }
      }
    };

    fetchChatbotAnalytics();

    return () => {
      controller.abort();
    };
  }, []);  // chatbot cards

  useEffect(() => {
    const controller = new AbortController();
    
    const fetchWhatsappAnalytics = async () => {
      const headers = {
        headers: {
          'Content-Type': 'application/json',
          'Authorization': `Token ${localStorage.getItem('token')}`,
          'Accept': 'application/json'
        },
        params: {
          phone_id: JSON.parse(localStorage.getItem('UserObject')).phone_number_id
        },
        signal: controller.signal
      };

      try {
        const res = await axios.get(`${config.apiUrl}whatsapp-analytics/`, headers);
        if (isMounted.current) {
          const temWhatsappAnalysis = [
            { title: "Total Chatlogs", value: res.data.total_chat_logs, percent: "0%" },
            { title: "Unique User", value: res.data.unique_users, percent: "0%" },
            { title: "Avg. Message Per Session", value: `${(res.data.average_messages_per_session).toFixed(2)} mps`, percent: "0%" },
            { title: "Retended User", value: res.data.retained_users, percent: "0%" }
          ];
          setWhatsappCards(temWhatsappAnalysis);
        }
      } catch (error) {
        if (!axios.isCancel(error) && isMounted.current) {
          console.error('Error fetching whatsapp analytics:', error);
          const tempWhatsappAnalysis = [
            { title: "Total Chatlogs", value: 0, percent: "0%" },
            { title: "Unique User", value: 0, percent: "0%" },
            { title: "Avg. Message Per Session", value: 0, percent: "0%" },
            { title: "Retended User", value: 0, percent: "0%" }
          ];
          setWhatsappCards(tempWhatsappAnalysis);
        }
      }
    };

    fetchWhatsappAnalytics();

    return () => {
      controller.abort();
    };
  }, []); // whatsapp cards

  useEffect(() => {
    const controller = new AbortController();

    const fetchLineChartData = async () => {
      const headers = {
        headers: {
          'Content-Type': 'application/json',
          'Authorization': `Token ${localStorage.getItem('token')}`,
          'Accept': 'application/json'
        },
        params: {
          company_username: localStorage.getItem('username'),
          phone_id: JSON.parse(localStorage.getItem('UserObject')).phone_number_id
        },
        signal: controller.signal
      };

      try {
        const res = await axios.get(`${config.apiUrl}total-chatlog-graph/`, headers);
        if (isMounted.current) {
          setLineChartData(res.data);
        }
      } catch (error) {
        if (!axios.isCancel(error)) {
          console.error('Error fetching line chart data:', error);
        }
      }
    };

    fetchLineChartData();

    return () => {
      controller.abort();
    };
  }, []); // line graph

  useEffect(() => {
    const controller = new AbortController();

    const fetchBarChartData = async () => {
      const headers = {
        headers: {
          'Content-Type': 'application/json',
          'Authorization': `Token ${localStorage.getItem('token')}`,
          'Accept': 'application/json'
        },
        params: {
          company_username: localStorage.getItem('username')
        },
        signal: controller.signal
      };

      try {
        const res = await axios.get(`${config.apiUrl}user-satisfaction-graph/`, headers);
        if (isMounted.current) {
          setBarChartData(res.data.data);
        }
      } catch (error) {
        if (!axios.isCancel(error)) {
          console.error('Error fetching bar chart data:', error);
        }
      }
    };

    fetchBarChartData();

    return () => {
      controller.abort();
    };
  }, []); // bar graph

  useEffect(() => {
    const controller = new AbortController();

    const fetchPieChartData = async () => {
      const headers = {
        headers: {
          'Content-Type': 'application/json',
          'Authorization': `Token ${localStorage.getItem('token')}`,
          'Accept': 'application/json'
        },
        params: {
          company_name: localStorage.getItem('username')
        },
        signal: controller.signal
      };

      try {
        const res = await axios.get(`${config.apiUrl}feedback-rate-graph/`, headers);
        if (isMounted.current && res.data.rating_percentages.length) {
          const updatedPieData = piedata.map(item => {
            const ratingData = res.data.rating_percentages.find(r => r.rating === parseInt(item.rating));
            return ratingData ? { ...item, value: parseFloat((ratingData.percentage).toFixed(2)) } : item;
          });
          setPieData(updatedPieData);
        }
      } catch (error) {
        if (!axios.isCancel(error)) {
          console.error('Error fetching pie chart data:', error);
        }
      }
    };

    fetchPieChartData();

    return () => {
      controller.abort();
    };
  }, []); // pie chart

  useEffect(() => {
    if (mode === 'shopify') {
      const controller = new AbortController();

      const fetchAreaChartData = async () => {
        try {
          const shopifyResponse = await axios.get(
            `${config.apiUrl}shopify/?username=${localStorage.getItem('username')}`,
            { signal: controller.signal }
          );
          
          if (isMounted.current && shopifyResponse.data.length > 0) {
            const { access_token, shop } = shopifyResponse.data[0];
            
            const ordersResponse = await axios.get(
              `${config.apiUrl}lead-conversion-graph/`,
              {
                params: {
                  access_token,
                  shop,
                  username: localStorage.getItem('username')
                },
                signal: controller.signal
              }
            );

            if (isMounted.current) {
              setAreaData(ordersResponse.data.orders);
            }
          }
        } catch (error) {
          if (!axios.isCancel(error)) {
            console.error('Error fetching area chart data:', error);
          }
        }
      };

      fetchAreaChartData();

      return () => {
        controller.abort();
      };
    }
  }, [mode]); // area chart

  const CustomTooltip = ({ active, payload, label }) => {
    if (active && payload && payload.length) {
      return (
        <div className="bg-white p-2 border border-gray-300 rounded shadow">
          <p className="font-bold">{label}</p>
          <p className="text-sm">This year: {payload[0].value}</p>
          <p className="text-sm">Last year: {payload[1].value}</p>
        </div>
      );
    }
    return null;
  };

  const CustomYAxisTick = ({ x, y, payload }) => {
    return (
      <g transform={`translate(${x},${y})`}>
        <text x={0} y={0} dy={16} textAnchor="end" fill="#666" fontSize={12}>
          {payload.value > 0 ? `${payload.value}` : payload.value}
        </text>
      </g>
    );
  };

  const getBarColor = (index) => {
    const colors = ['#8B5CF6', '#4C1D95', '#1F2937', '#DDD6FE', '#7C3AED', '#A78BFA'];
    return colors[index % colors.length];
  };

  return (

    <CustomLayout>
			
			{/* <div className="dashboard-section">
				<h1>Chatbot Analysis</h1>
				<div className="cards-container">
					{chatbotCards.map((card, index) => (
						<div key={index} className={`stats-card ${index % 2 === 0 ? 'light' : 'dark'}`}>
							<div className="card-content">
								<h3>{card.title}</h3>
								<div className='card-subContainer'>
									<p className='card-value'>{card.value}</p>
									<div className='card-percent'>
										<p>{card.percent}</p>
										<RiseOutlined size={20} />
									</div>
								</div>
							</div>
						</div>
					))}
				</div>
			</div> */}


			<div className="dashboard-section">
				<h1>{analyticsTranslation.chatbot.label}</h1>
				<div className="cards-container">
					<div className={`stats-card light`}>
						<div className="card-content">
							<h3>{analyticsTranslation.chatbot.totalChatlog}</h3>
							<div className='card-subContainer'>
								<p className='card-value'>{chatbotCards[0].value}</p>
								<div className='card-percent'>
									<p>{chatbotCards[0].percent}</p>
									<RiseOutlined size={20} />
								</div>
							</div>
						</div>
					</div>
					<div className={`stats-card dark`}>
						<div className="card-content">
							<h3>{analyticsTranslation.chatbot.uniqueUser}</h3>
							<div className='card-subContainer'>
								<p className='card-value'>{chatbotCards[1].value}</p>
								<div className='card-percent'>
									<p>{chatbotCards[1].percent}</p>
									<RiseOutlined size={20} />
								</div>
							</div>
						</div>
					</div>
					<div className={`stats-card light`}>
						<div className="card-content">
							<h3>{analyticsTranslation.chatbot.avgMessagePerSession}</h3>
							<div className='card-subContainer'>
								<p className='card-value'>{chatbotCards[2].value}</p>
								<div className='card-percent'>
									<p>{chatbotCards[2].percent}</p>
									<RiseOutlined size={20} />
								</div>
							</div>
						</div>
					</div>
					<div className={`stats-card dark`}>
						<div className="card-content">
							<h3>{analyticsTranslation.chatbot.userRetention}</h3>
							<div className='card-subContainer'>
								<p className='card-value'>{chatbotCards[3].value}</p>
								<div className='card-percent'>
									<p>{chatbotCards[3].percent}</p>
									<RiseOutlined size={20} />
								</div>
							</div>
						</div>
					</div>
				</div>
			</div>

			<div className="dashboard-section">
				<h1>{analyticsTranslation.whatsapp.label}</h1>
				<div className="cards-container">
					<div className={`stats-card light`}>
						<div className="card-content">
							<h3>{analyticsTranslation.whatsapp.totalChatlog}</h3>
							<div className='card-subContainer'>
								<p className='card-value'>{whatsappCards[0].value}</p>
								<div className='card-percent'>
									<p>{whatsappCards[0].percent}</p>
									<RiseOutlined size={20} />
								</div>
							</div>
						</div>
					</div>
					<div className={`stats-card dark`}>
						<div className="card-content">
							<h3>{analyticsTranslation.whatsapp.uniqueUser}</h3>
							<div className='card-subContainer'>
								<p className='card-value'>{whatsappCards[1].value}</p>
								<div className='card-percent'>
									<p>{whatsappCards[1].percent}</p>
									<RiseOutlined size={20} />
								</div>
							</div>
						</div>
					</div>
					<div className={`stats-card light`}>
						<div className="card-content">
							<h3>{analyticsTranslation.whatsapp.avgMessagePerSession}</h3>
							<div className='card-subContainer'>
								<p className='card-value'>{whatsappCards[2].value}</p>
								<div className='card-percent'>
									<p>{whatsappCards[2].percent}</p>
									<RiseOutlined size={20} />
								</div>
							</div>
						</div>
					</div>
					<div className={`stats-card dark`}>
						<div className="card-content">
							<h3>{analyticsTranslation.whatsapp.userRetention}</h3>
							<div className='card-subContainer'>
								<p className='card-value'>{whatsappCards[3].value}</p>
								<div className='card-percent'>
									<p>{whatsappCards[3].percent}</p>
									<RiseOutlined size={20} />
								</div>
							</div>
						</div>
					</div>
				</div>
			</div>

			
			<div className={`dashboard-grid ${mode == "email" && 'single'}`}>
				<div className="chart-wrapper">
					<div className="chart-container">
						<h2 className="chart-title">{ analyticsTranslation.lineGraph.label}</h2>
						<p className="chart-subtitle">{ analyticsTranslation.lineGraph.totalChats}</p>
						<ResponsiveContainer width="100%" height="80%">
							<LineChart data={lineChartData} margin={{ top: 5, right: 30, left: 20, bottom: 5 }}>
								<CartesianGrid strokeDasharray="3 3" vertical={false} />
								<XAxis dataKey="name" axisLine={false} tickLine={false} />
								<YAxis axisLine={false} tickLine={false} tickCount={4} domain={[0, 30]} />
								<Tooltip content={<CustomTooltip />} />
								<Legend align="right" verticalAlign="top" />
								<Line type="monotone" dataKey="thisYear" stroke="#8884d8" dot={false} name={ analyticsTranslation.lineGraph.thisYear} />
								<Line type="monotone" dataKey="lastYear" stroke="#82ca9d" strokeDasharray="5 5" dot={false} name={ analyticsTranslation.lineGraph.lastYear} />
							</LineChart>
						</ResponsiveContainer>
					</div>
				</div>

					{
						mode == 'email' ? '' : <div className="chart-wrapper">
						<div className="chart-container tall">
							<div className="chart-header">
								<h2 className="chart-title">{ analyticsTranslation.barGraph.label}</h2>
								{/* <select className="chart-select">
									<option>Half Yearly</option>
								</select> */}
							</div>
						<ResponsiveContainer width="100%" height="80%">
							<BarChart data={barChartdata} margin={{ top: 20, right: 30, left: 20, bottom: 5 }}>
								<CartesianGrid strokeDasharray="3 3" vertical={false} />
								<XAxis 
									dataKey="month" 
									axisLine={false} 
									tickLine={false} 
									tick={{ fill: '#6B7280', fontSize: 12 }}
								/>
								<YAxis 
									axisLine={false} 
									tickLine={false} 
									tick={{ fill: '#6B7280', fontSize: 12 }}
									ticks={[0, 20, 40, 60, 80, 100]}
									domain={[0, 100]}
									/>

									<Legend align="right" verticalAlign="top" />
									<Tooltip content={<CustomTooltip />} />

									
								<Bar 
										dataKey="thisYear"
										fill="#8B5CF6"
										radius={[10, 10, 10, 10]}
										barSize={15}
										name={ analyticsTranslation.barGraph.thisYear}
								>
									{barChartdata?.map((entry, index) => (
										<Bar key={`bar-${index}`} dataKey="thisYear" fill={getBarColor(index)}  />
									))}
									</Bar>

								<Bar 
										dataKey="lastYear"
										fill="#C4B5FD"
										radius={[10, 10, 10, 10]}
										barSize={15}
										name={ analyticsTranslation.barGraph.lastYear}
								>
									{barChartdata?.map((entry, index) => (
										<Bar key={`bar-${index}`} dataKey="lastYear" fill={getBarColor(index)} />
									))}
								</Bar>

							</BarChart>
						</ResponsiveContainer>
						</div>
						</div>
					}

			</div>

			<div className="dashboard-grid-2">

				{
					mode == 'email' ? <div className="chart-wrapper">
					<div className="chart-container tall">
						<div className="chart-header">
								<h2 className="chart-title">{ analyticsTranslation.barGraph.label}</h2>
							{/* <select className="chart-select">
								<option>Half Yearly</option>
							</select> */}
						</div>
						<ResponsiveContainer width="100%" height="80%">
							<BarChart data={barChartdata} margin={{ top: 20, right: 30, left: 20, bottom: 5 }}>
								<CartesianGrid strokeDasharray="3 3" vertical={false} />
								<XAxis 
									dataKey="month" 
									axisLine={false} 
									tickLine={false} 
									tick={{ fill: '#6B7280', fontSize: 12 }}
								/>
								<YAxis 
									axisLine={false} 
									tickLine={false} 
									tick={{ fill: '#6B7280', fontSize: 12 }}
									ticks={[0, 1, 2, 3, 4, 5]}
									domain={[0, 5]}
									/>

									<Legend align="right" verticalAlign="top" />
									<Tooltip content={<CustomTooltip />} />

									
								<Bar 
									dataKey="thisYear" 
									fill="#8B5CF6" 
									radius={[10, 10, 10, 10]}
										barSize={15}
										name={analyticsTranslation.barGraph.thisYear}
								>
									{barChartdata?.map((entry, index) => (
										<Bar key={`bar-${index}`} dataKey="thisYear" fill={getBarColor(index)}  />
									))}
									</Bar>

								<Bar 
									dataKey="lastYear" 
									fill="#C4B5FD" 
									radius={[10, 10, 10, 10]}
										barSize={15}
										name={analyticsTranslation.barGraph.lastYear}
								>
									{barChartdata?.map((entry, index) => (
										<Bar key={`bar-${index}`} dataKey="lastYear" fill={getBarColor(index)} />
									))}
								</Bar>


									

							</BarChart>
						</ResponsiveContainer>
					</div>
				</div> :	<div className="chart-wrapper">
					<div className="chart-container tall">
						<div className="chart-header">
									<h2 className="chart-title">{ analyticsTranslation.areaChart.label}</h2>
						</div>
								<p className="chart-subtitle">{ analyticsTranslation.areaChart.totalOrder}</p>
						<ResponsiveContainer width="100%" height="80%">
							<AreaChart data={areadata} margin={{ top: 10, right: 30, left: 0, bottom: 0 }}>
								<defs>
									<linearGradient id="colorValue" x1="0" y1="0" x2="0" y2="1">
										<stop offset="5%" stopColor="#8884d8" stopOpacity={0.8} />
										<stop offset="95%" stopColor="#8884d8" stopOpacity={0} />
									</linearGradient>
								</defs>
								<CartesianGrid strokeDasharray="3 3" vertical={false} stroke="#e0e0e0" />
										
								<XAxis 
									dataKey="month" 
									axisLine={false} 
									tickLine={false} 
									tick={{ fill: '#666', fontSize: 12 }} 
								/>
								<YAxis 
									axisLine={false} 
									tickLine={false} 
									tick={<CustomYAxisTick />} 
									// domain={[0, 10]} 
										/>
										
									<Legend align="right" verticalAlign="top" />
									<Tooltip />

								<Area 
											type="monotone"
											dataKey="totalOrder"
											stroke="#8884d8"
											fillOpacity={1}
											fill="url(#colorValue)"
											name={analyticsTranslation.areaChart.totalOrder}
								/>
							</AreaChart>
						</ResponsiveContainer>
					</div>
				</div>
				}

				<div className="chart-wrapper">

				<div className="chart-container tall">
					<div className="chart-header">
							<h2 className="chart-title">{ analyticsTranslation.piechart.label}</h2>
					</div>
					
					{/* <div className="chart-data">
						{piedata.map((item, index) => (
							<div key={index} className="data-item">
								<div className="data-label">
									<div className="color-circle" style={{ backgroundColor: item.color }}></div>
									<span className="data-name">{item.name}</span>
								</div>
								<span className="data-value">{item.value}%</span>
							</div>
						))}
					</div> */}
					
					<div className="chart-data">
						<div className="data-item">
							<div className="data-label">
								<div className="color-circle" style={{ backgroundColor: piedata[0].color }}></div>
								<span className="data-name">{analyticsTranslation.piechart.rating_1}</span>
							</div>
							<span className="data-value">{piedata[0].value}%</span>
						</div>
						<div className="data-item">
							<div className="data-label">
								<div className="color-circle" style={{ backgroundColor: piedata[1].color }}></div>
								<span className="data-name">{analyticsTranslation.piechart.rating_2}</span>
							</div>
							<span className="data-value">{piedata[1].value}%</span>
						</div>
						<div className="data-item">
							<div className="data-label">
								<div className="color-circle" style={{ backgroundColor: piedata[2].color }}></div>
								<span className="data-name">{analyticsTranslation.piechart.rating_3}</span>
							</div>
							<span className="data-value">{piedata[2].value}%</span>
						</div>
						<div className="data-item">
							<div className="data-label">
								<div className="color-circle" style={{ backgroundColor: piedata[3].color }}></div>
								<span className="data-name">{analyticsTranslation.piechart.rating_4}</span>
							</div>
							<span className="data-value">{piedata[3].value}%</span>
						</div>
						<div className="data-item">
							<div className="data-label">
								<div className="color-circle" style={{ backgroundColor: piedata[4].color }}></div>
								<span className="data-name">{analyticsTranslation.piechart.rating_5}</span>
							</div>
							<span className="data-value">{piedata[4].value}%</span>
						</div>
					</div>

					<div className="chart-body">
						<ResponsiveContainer width="100%" height="70%">
							<PieChart>
								<Pie
									data={piedata}
									cx="50%"
									cy="50%"
									innerRadius={40}
									outerRadius={80}
									paddingAngle={2}
									dataKey="value"
									startAngle={90}
									endAngle={-270}
								>
									{piedata.map((entry, index) => (
										<Cell key={`cell-${index}`} fill={entry.color} />
									))}
									</Pie>
									<Tooltip />
							</PieChart>
						</ResponsiveContainer>
					</div>
					</div>
					</div>

				
				</div>
    </CustomLayout>

  )
}