import React, { useEffect, useState } from 'react';
import { useLocation, useNavigate } from 'react-router-dom';
import axios from 'axios';
import { message, Spin } from 'antd';
import { CheckOutlined } from '@ant-design/icons';
import config from '../../config';
import '../payment/CSS/Pricing.css'; // Assuming you have shared styles with Pricing
import colorJawebLogo from '../../assets/Onboarding/colorLogo.png';


const SignUpShopify = () => {
  const location = useLocation();
  const navigate = useNavigate();
  const [loading, setLoading] = useState(true); // Loader state to track request
  const [isNewUser, setIsNewUser] = useState(false); // Track if the user is new
  const [userCheckComplete, setUserCheckComplete] = useState(false); // Track if user check is complete

  useEffect(() => {
    const params = new URLSearchParams(location.search);
    const shop = params.get('shop');
    const code = params.get('code');

    if (shop && code) {
      // Check if the user is new or existing
      axios.post(`${config.apiUrl}shopify/check-user/`, { shop, code })
        .then(response => {
          if (response.data.is_new_user) {
            setIsNewUser(true); // Set new user
          } else {
            // If an existing user, proceed with the OAuth process directly
            handleOAuth(shop, code); // Function for existing users
          }
        })
        .catch(error => {
          message.error('User Check Error', 3);
          navigate('/auth/login');
        })
        .finally(() => {
          setLoading(false); // Stop loader after user check
          setUserCheckComplete(true); // Mark user check as complete
        });
    } else {
      // If no shop or code, redirect to login
      navigate('/auth/login');
    }
  }, [location, navigate]);

  const handleOAuth = (shop, code, plan = null) => {
    setLoading(true); // Start loader during OAuth process

    axios.post(`${config.apiUrl}shopify/callback/`, { shop, code, plan, is_new_user: isNewUser })
      .then(response => {
        setLoading(false); // Stop loader

        if (response.data.redirect_url) {
          localStorage.setItem('access_token', response.data.access_token);
          localStorage.setItem('shop', response.data.shop);
          window.location.href = response.data.redirect_url;
        } else {
          localStorage.setItem('token', response.data.token);
          localStorage.setItem('refreshToken', response.data.refreshToken);
          localStorage.setItem("Userdata", JSON.stringify(response.data));
          navigate('/user/chatbot');
        }
      })
      .catch(error => {
        setLoading(false); // Stop loader in case of error
        message.error('Authorization Error', 3);
        navigate('/auth/login');
      });
  };

  const handlePlanSelection = (planType) => {
    const params = new URLSearchParams(location.search);
    const shop = params.get('shop');
    const code = params.get('code');

    // Call handleOAuth and pass the selected plan type (monthly or yearly)
    handleOAuth(shop, code, planType);
  };

  return (
    <div className="pricing-section-dark">
      {loading && (
        <div className="loader-container">
          <Spin size="large" /> {/* Show loader while processing the request */}
        </div>
      )}

      {!loading && userCheckComplete && isNewUser && (
        <div>
          <div>
            <img src={colorJawebLogo} className='signup-logo'/>
            </div>
          <div className="pricing-cards-dark h-full flex justify-between">
          {/* Monthly Plan */}
          <div className="pricing-card-dark">
            <h3>Shopify Access - Monthly</h3>
            <p>Live Chatbot On Your Shopify Store</p>
            <div className="price">
              <span>$49/month</span>
            </div>
            <div className="features">
              <div className="feature-item">
                <CheckOutlined /> <span>Access to dashboard with analytics</span>
              </div>
              <div className="feature-item">
                <CheckOutlined /> <span>Zapier integration</span>
              </div>
              <div className="feature-item">
                <CheckOutlined /> <span>WhatsApp integration</span>
              </div>
              <div className="feature-item">
                <CheckOutlined /> <span>Real-time messaging</span>
              </div>
              <div className="feature-item">
                <CheckOutlined /> <span>AI-powered chatbot</span>
              </div>
            </div>
            <button
              onClick={() => handlePlanSelection('monthly')} // Pass 'monthly' as the selected plan
              className="action-button-dark"
            >
              Choose Monthly
            </button>
          </div>

          {/* Yearly Plan */}
          <div className="pricing-card-dark">
            <h3>Shopify Access - Yearly</h3>
            <p>Save with the yearly plan</p>
            <div className="price">
              <span>$558/year</span>
            </div>
            <div className="features">
              <div className="feature-item">
                <CheckOutlined /> <span>Access to dashboard with analytics</span>
              </div>
              <div className="feature-item">
                <CheckOutlined /> <span>Zapier integration</span>
              </div>
              <div className="feature-item">
                <CheckOutlined /> <span>WhatsApp integration</span>
              </div>
              <div className="feature-item">
                <CheckOutlined /> <span>Real-time messaging</span>
              </div>
              <div className="feature-item">
                <CheckOutlined /> <span>AI-powered chatbot</span>
              </div>
            </div>
            <button
              onClick={() => handlePlanSelection('yearly')} // Pass 'yearly' as the selected plan
              className="action-button-dark"
            >
              Choose Yearly
            </button>
          </div>
        </div>
        </div>
   
      )}
    </div>
  );
};

export default SignUpShopify;
