import React, { useState, useEffect, useRef } from 'react';
import Sidebar from './components/Sidebar';
import Navbar from './components/Navbar';
import Flex from '../components/shared-components/Flex';

import './Css/Messaging.css'
import { useNavigate, useParams, useLocation } from 'react-router-dom';
// import Spinner from './components/Spinner';
import { Spin } from "antd";
import config from '../config';
import { CheckBadgeIcon, CheckCircleIcon, ChatBubbleBottomCenterIcon, EllipsisVerticalIcon } from '@heroicons/react/20/solid';
import { Input, Tooltip, List, Switch } from 'antd';
import ChatView from './ChatView';
import NotificationSound from "./notification-sound.mp3";
import ncImg from "../assets/nochat.png"
import chatImg from "../assets/chat.png"
import qrcode from "../assets/qr-code-outline.png"
import Avatar from '../assets/Avatar (1).png'
import { FaFilter } from "react-icons/fa";
import { IoMdAdd } from "react-icons/io";
import { RiVerifiedBadgeFill } from "react-icons/ri";
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faSearch } from '@fortawesome/free-solid-svg-icons';
import { Modal, Button, InputNumber } from 'antd'
import { FaWhatsapp } from "react-icons/fa";
import { IoLogoWhatsapp } from "react-icons/io";
import { FiPhone } from "react-icons/fi";
import { FiVideo } from "react-icons/fi";
import { HiOutlineDotsVertical } from "react-icons/hi";
import attachimg from "../assets/attach.png"
import galleryimg from "../assets/image.png"
import locationimg from "../assets/location.png"
import { MdEmojiEmotions } from "react-icons/md";
import { Dropdown } from 'antd';
import { DownOutlined, MailOutlined } from '@ant-design/icons';
import { Divider } from 'antd';
import { IoChatbubbleEllipses } from "react-icons/io5";
import { FaSquareInstagram } from "react-icons/fa6";
import { FaInstagram } from 'react-icons/fa';
import { IoMdSend } from "react-icons/io";

import CustomLayout from '../layouts/Customlayout';
import EmojiPicker from 'emoji-picker-react';
import { useTranslation } from 'react-i18next';
import editIcon from "../assets/edit.png"

import axios from 'axios';

export default function Messaging() {
  const { session_id } = useParams();
  const audioPlayer = useRef(null);

  const [messageInput, setMessageInput] = useState('');
  const [isModalOpen, setIsModalOpen] = useState(false);
  const navigate = useNavigate();
  const [showMessage, setShowMessage] = useState(false);
  const [Messages, setMessages] = useState([])
  const [numberValue, setNumberValue] = useState('');
  const [whatsappScreen, setWhatsappScreen] = useState(false)
  const location = useLocation();
  const currentUrl = location.pathname;
  const pathParts = currentUrl.split('/');

  const lastPart = pathParts[pathParts.length - 1];



  //SEYYY



  const [refreshing, setRefreshing] = useState(false);
  const [image, setImage] = useState('https://w7.pngwing.com/pngs/178/595/png-transparent-user-profile-computer-icons-login-user-avatars-thumbnail.png')
  const [userWeekly, setUserWeekly] = useState()
  const [userdataVar, setUserdata] = useState({})
  const [Userprofile, SetuserProfile] = useState(false)
  const [dataLoaded, setDataLoaded] = useState(false);
  const [chats, setChats] = useState([]);
  const [spin, Setspin] = useState(true)
  const [spinAssign, SetspinAssign] = useState(true)
  const [chatlog, setChatlog] = useState({})
  const [Supports, setsupports] = useState([])
  const [whatsappOpen, setWhatsappOpen] = useState(false);
  const [activeChatId, setActiveChatId] = useState(null);


  const [whatsappChatLogs, setWhatsappChatLogs] = useState([])
  const [activeWhatsappSession, setActiveWhatsappSession] = useState({})
  const [newWhatsappNumber, setNewWhatsappNumber] = useState("")
  const [whatsappTextMsg, setWhatsappTextMsg] = useState("")
  const [messageList, setMessageList] = useState([]);
  const [newSocket, setNewSocket] = useState(null);
  const messsagelistRef = useRef(null)
  const messsageRef = useRef(null)
  const [messageType, setMessateType] = useState('chat')
  const [messagesSuggestions, setMessagesSuggestions] = useState([])
  const [messageReceived, setMessageReceived] = useState(false)

  const [fromWhatsappNumber, setFromWhatsappNumber] = useState('')
  const [toWhatsappNumber, setToWhatsappNumber] = useState('')

  //for Emoji's
  const [showEmojiPicker, setShowEmojiPicker] = useState(false);
  const emojiPickerRef = useRef(null);
  const latestWhatsappTextMsg = useRef(whatsappTextMsg);
  const [botControlled, setBotControlled] = useState(false);
  const [whatsappBot, setWhatsappBot] = useState(false);
  const [selectedLang, setSelectedLang] = useState('')

  const [isExpanded, setIsExpanded] = useState(false);

  const [disableForm, setDisableForm] = useState(false);
    const { t } = useTranslation();
  const messagesTranslations = t("messages")
  const [toggletakeover, setToggleTakeover] = useState([
    {
      title: messagesTranslations.takecontrol,
      icon: MailOutlined,
      allow: false,
    },
  ]);
  // const [selectedMessage,setSelectedMessage] = useState()

  // const toggleExpand = (messageId) => {
  //   if(messageId){
  //   setSelectedMessage(messageId)
  //   setIsExpanded(!isExpanded);
  //   }else{
  //   setSelectedMessage()
  //   setIsExpanded(!isExpanded);
  //   }
  // };


  const handleChatClick = (chat) => {
    setActiveChatId(chat.user_session_id);
    // handleWhatsappscreen(chat);
  };

  const [expandedMessages, setExpandedMessages] = useState(new Set());
  const [totalChats, setTotalChats] = useState(0)

  const toggleExpand = (messageId) => {
    setExpandedMessages(prevState => {
      const newState = new Set(prevState);
      if (newState.has(messageId)) {
        newState.delete(messageId);
      } else {
        newState.add(messageId);
      }
      return newState;
    });
  };

  const isMessageExpanded = (messageId) => expandedMessages.has(messageId);

  useEffect(() => {
    latestWhatsappTextMsg.current = whatsappTextMsg;
  }, [whatsappTextMsg]);

  useEffect(() => {
    const handleClickOutside = (event) => {
      if (emojiPickerRef.current && !emojiPickerRef.current.contains(event.target)) {
        setShowEmojiPicker(false);
      }
    };

    document.addEventListener('mousedown', handleClickOutside);
    const selectedLang = sessionStorage.getItem('selectedLang') ? sessionStorage.getItem('selectedLang') : 'en' 
    setSelectedLang(selectedLang)
    return () => {
      document.removeEventListener('mousedown', handleClickOutside);
      sessionStorage.removeItem('selectedLang')

    };
  }, []);

  const handleEmojiClick = (emojiObject) => {
    setWhatsappTextMsg(prevText => prevText + emojiObject.emoji);
  };
  // useEffect(() => {
  //   const socket = new WebSocket(`ws://127.0.0.1:8000/ws/whatsapp_chat/87654345678987654/`);
  //   setNewSocket(socket);

  //   return () => {
  //     socket.close();
  //   };
  // }, []);

  useEffect(() => {
    const headers = {
      headers: {
        'Content-Type': 'application/json',
        'Authorization': `Token ${JSON.parse(localStorage.getItem('Userdata')).token}`,
        'Accept': 'application/json'
      }
    };

    axios.get(`${config.apiUrl}message-suggestions/`, headers).then((res) => {
      setMessagesSuggestions(res.data.data)
    })
  }, [activeWhatsappSession])

  useEffect(() => {

    if (messsagelistRef.current) {
      messsagelistRef.current.scrollTop = messsagelistRef.current.scrollHeight;
    }

    if (messsageRef.current) {
      messsageRef.current.scrollTop = messsageRef.current.scrollHeight;
    }

  }, [messageList?.length, Messages?.length]);


  useEffect(() => {
    if (!newSocket) return;

    newSocket.onopen = () => {
      console.log('WebSocket connection opened');
    };

    newSocket.onclose = (event) => {
      console.log('WebSocket connection closed:', event.reason);
    };

    // newSocket.onmessage = (event) => {

    //   console.log(JSON.parse(event.data).message.last_message,'----------------------------__>><><>><<><><');

    //   let tempJson = JSON.parse(event.data).message

    //   tempJson.message = tempJson.last_message

    //   if (typeof JSON.parse(event.data).message == 'object') {
    //     setMessageList(messageList => [...messageList, tempJson]);
    //   }

    // };
    newSocket.onmessage = (event) => {
      try {
        const data = JSON.parse(event.data);

        // console.log(JSON.parse(event.data), '-----------------from hoook')
        setMessageReceived(!messageReceived)

        // Ensure data.message is an object and has the property last_message
        if (typeof data.message === 'object') {
          let tempJson = { ...data.message };

          // console.log(tempJson, '-----------------------------___>>>>TJ')

          // tempJson.message =  tempJson.last_message;

          setMessageList(messageList => [...messageList, tempJson]);
        }
      } catch (e) {
        console.error("Failed to handle WebSocket message:", e);
      }
    };

    return () => {
      newSocket.onopen = null;
      newSocket.onclose = null;
      newSocket.onmessage = null;
    };
  }, [newSocket]);

  const chatLogsManaged = () => {

    const params = {
      sender_number: JSON.parse(localStorage.getItem('UserObject')).phone_number_id,
    };

    const headers = {
      headers: {
        'Content-Type': 'application/json',
        'Authorization': `Token ${JSON.parse(localStorage.getItem('Userdata')).token}`,
        'Accept': 'application/json'
      },
      params: params
    };

    axios.get(`${config.apiUrl}whatsapp-chatlogs/`, headers).then((res) => {
      setWhatsappChatLogs(res.data)
    })
  }


  useEffect(() => {
    chatLogsManaged()
  })

  const handleWhatsappTextMsgSend = async (e) => {
    e.preventDefault();

    console.log(whatsappTextMsg, '--new');

    const temp_json = {
      'message': whatsappTextMsg,
      'from_bot': true
    };

    if (newSocket) {
      newSocket.send(JSON.stringify({
        type: "send_message",
        message: JSON.stringify(temp_json)
      }));
    }

    let formData = new FormData();
    formData.append('message', whatsappTextMsg);
    formData.append('sender_number', JSON.parse(localStorage.getItem('UserObject')).phone_number_id);
    formData.append('receiver_number', activeWhatsappSession.phone_number);



    const headers = {
      headers: {
        'Content-Type': 'application/json',
        'Authorization': `Token ${JSON.parse(localStorage.getItem('Userdata')).token}`,
        'Accept': 'application/json'
      }
    };

    axios.post(`${config.apiUrl}whatsapp-message-send/`, formData, headers).then((res) => {
      // console.log(res, '----------------------->>>> whatsapp-message-send');
    })

    setMessageList(messageList => [...messageList, temp_json]);
    setWhatsappTextMsg('')

  }


  const chatMessagesManaged = () => {

    let formData = new FormData();
    formData.append('session_id', activeWhatsappSession.user_session_id);

    const headers = {
      headers: {
        'Content-Type': 'application/json',
        'Authorization': `Token ${JSON.parse(localStorage.getItem('Userdata')).token}`,
        'Accept': 'application/json'
      }
    };

    axios.post(`${config.apiUrl}whatsapp-message-get/`, formData, headers).then((res) => {
      // console.log(res, '------------------whatsapp-message-get----------------------------------')
      setMessageList(res.data)
    })

  }


  useEffect(() => {
    chatMessagesManaged()
  }, [activeWhatsappSession.phone_number])



  useEffect(() => {
    loadUserdata();
    setDataLoaded(true);
  }, []);

  const messagesContainerRef = useRef(null);



  useEffect(() => {
    // Scroll to the bottom whenever Messages state updates
    if (messagesContainerRef.current) {
      messagesContainerRef.current.scrollTop = messagesContainerRef.current.scrollHeight;
    }
  }, [Messages]);


  useEffect(() => {

    let userdata = localStorage.getItem('UserObject');
    const data = JSON.parse(userdata);

    const headers = {
      headers: {
        'Content-Type': 'application/json',
        'Authorization': `Token ${JSON.parse(localStorage.getItem('Userdata')).token}`,
        'Accept': 'application/json'
      }
    };

    axios.get(`${config.apiUrl}chatlogs-get/${localStorage.getItem('username')}`, headers).then((res) => {
      setChats(res.data);
      setTotalChats((res.data).length)
      Setspin(false)
      setUserdata(data)

    })

  }, [])

  const loadUserdata = async () => {

    try {

      let userdata = localStorage.getItem('UserObject');
      const data = JSON.parse(userdata);

      setImage(`${config.apiUrl}${data?.image}`);

      // const Chatlogs_fetch = await fetch(`${config.apiUrl}chatlogs-get/${data?.username}`);
      // const chatlogs = await Chatlogs_fetch.json();
      // setChats(chatlogs);
      // Setspin(false)
      // setUserdata(data)


      if (session_id != "none") {
        const messages_fetch = await fetch(`${config.apiUrl}message-get/${session_id}`);
        const messages = await messages_fetch.json();

        setMessages(messages);

        const Chatlog_fetch = await fetch(`${config.apiUrl}chatlog-get/${session_id}`);
        const chatlog = await Chatlog_fetch.json();
        // console.log(chatlog)
        setChatlog(chatlog)



        const fetch_team = await fetch(`${config.apiUrl}team-get/${data.email}`);
        const team_supports = await fetch_team.json();
        setsupports(team_supports);
        // console.log(team_supports)
        SetspinAssign(false)


      }




    } catch (error) {
      // Handle errors
      console.error('Error:', error);
    }
  };

  const pullToRefreshFunction = () => {
    setRefreshing(true)
    loadUserdata();
    setRefreshing(false)

  }

  const handleCancel = () => {
    setIsModalOpen(false);
  };
  const handleNumberChange = (value) => {
    setNewWhatsappNumber(value);
  };
  const showModal = () => {
    setIsModalOpen(true);
  };

  const toggleWhatsappSection = (activeSection) => {
    if (activeSection === 'chat') {
      setMessateType('chat')
      setWhatsappOpen(false)
      setTotalChats(chats.length)

    } else if (activeSection === 'whatApp') {
      chatLogsManaged()
      setMessateType('whatsapp')
      setWhatsappOpen(true)
      setTotalChats(whatsappChatLogs.length)

    }
  };



  const handleWhatsappscreen = (whatsappChatLogObj) => {
    setWhatsappScreen(true)
    setActiveWhatsappSession(whatsappChatLogObj)
    console.log(whatsappChatLogObj, '------------------chat objectr');
    setWhatsappBot(whatsappChatLogObj.disable_chatbot)

    // ws://127.0.0.1:8000
    // wss://jawebcrm.onrender.com/ws/whatsapp_chat/${whatsappChatLogObj['user_session_id']}/
    const socket = new WebSocket(`wss://jawebcrm.onrender.com/ws/whatsapp_chat/${whatsappChatLogObj['user_session_id']}/`);
    setNewSocket(socket);

    return () => {
      socket.close();
    };
  }

  const handleInputChange = (e) => {
    setMessageInput(e.target.value);
  };

  const sendMessage = () => {
    setMessageInput('');
  };



  const handleUserSession = async (id) => {
    document.body.classList.remove('open_customer_info');
    setActiveChatId(id);

    setWhatsappScreen(false)
    SetspinAssign(true);
    navigate(`/user/messages/${id}`)

    // Make a GET request to mark the chat as read
    const markAsReadPromise = fetch(`${config.apiUrl}update-unread/${id}`)
      .then(response => response.json())
      .then(data => {
        if (data.status === 'success') {
          // Successfully marked as read, update local state
          setChats(prevChats => prevChats.map(chat => ({
            ...chat,
            unread: chat.user_session_id === id ? 0 : chat.unread,
          })));
        } else {
          // Handle error if needed
          console.error('Failed to mark chat as read:', data.message);
        }
      })
      .catch(error => {
        console.error('Error during fetch:', error);
      });

    // Fetch messages, chatlog, and team supports concurrently
    // const [messagesResponse, chatlogResponse, teamSupportsResponse] = await Promise.all([
    //   fetch(`${config.apiUrl}message-get/${id}`).then(response => response.json()),
    //   fetch(`${config.apiUrl}chatlog-get/${id}`).then(response => response.json()),
    //   fetch(`${config.apiUrl}team-get/${userdataVar.email}`).then(response => response.json()),
    // ]);

    const [messagesResponse, chatlogResponse, teamSupportsResponse] = await Promise.all([
      axios.get(`${config.apiUrl}message-get/${id}`).then(response => response.data),
      axios.get(`${config.apiUrl}chatlog-get/${id}`).then(response => response.data),
      // axios.get(`${config.apiUrl}team-get/${userdataVar.email}`).then(response => response.data),
    ]);

    setMessages(messagesResponse);
    setChatlog(chatlogResponse);
    // setsupports(teamSupportsResponse);

    SetspinAssign(false);
  };


  const closeUserProfile = () => {
    SetuserProfile(false)
  };

  const handleUpdateData = (newData) => {
    setChatlog(newData);
  };

  const handleMessageSuggestion = (suggestedMsg, msgType) => {

    if (msgType == "whatsappMsg") {

      const temp_json = {
        'message': suggestedMsg,
        'from_bot': true
      };

      if (newSocket) {
        newSocket.send(JSON.stringify({
          type: "send_message",
          message: JSON.stringify(temp_json)
        }));
      }

      let formData = new FormData();
      formData.append('message', suggestedMsg);
      formData.append('sender_number', localStorage.getItem('senderNumber'));
      formData.append('receiver_number', activeWhatsappSession.phone_number);

      const headers = {
        headers: {
          'Content-Type': 'application/json',
          'Authorization': `Token ${JSON.parse(localStorage.getItem('Userdata')).token}`,
          'Accept': 'application/json'
        }
      };

      setMessagesSuggestions(messagesSuggestions.filter(item => item !== suggestedMsg))
      axios.post(`${config.apiUrl}whatsapp-message-send/`, formData, headers).then((res) => {
        setMessageList(messageList => [...messageList, temp_json]);
      })

    } else {

      if (socket && socket.readyState === socket.OPEN) {
        const additionalData = { isBusiness: false };
        const messagePayload = JSON.stringify({
          message: suggestedMsg,
          additionalData: additionalData,
        });
        socket.send(messagePayload);
      }
      apiCall(suggestedMsg)
      setMessagesSuggestions(messagesSuggestions.filter(item => item !== suggestedMsg))

    }

  }

  const [socket, setSocket] = useState(null);
  const [chatlogSocket, setChatlogSocket] = useState(null)

  useEffect(() => {
    const openWebSocket = () => {
      const newSocket = new WebSocket(`wss://jawebcrm.onrender.com/ws/chat/${lastPart}/`);

      newSocket.onopen = () => {
        console.log('WebSocket connection opened');
        setSocket(newSocket);
      };

      newSocket.onmessage = (event) => {
        const data = JSON.parse(event.data);
        console.log(data)
        const message = data.message;
        const isBusiness = data.additionalData.isBusiness;
        const session_id_bot = data.additionalData.SessionId

        if (isBusiness === true) {


          setMessages((prevChatMessages) => [
            ...prevChatMessages,
            { id: prevChatMessages.length + 1, message: message, isBusiness: true },
          ]);

          audioPlayer.current.play();


        } else if (data.additionalData.editedMsg == true) {
          console.log('edited msg');
          
        }

        else {
          // console.log("isnotbusiness")
          // console.log(message)


          setMessages((prevChatMessages) => [
            ...prevChatMessages,
            { id: prevChatMessages.length + 1, message: message, isBusiness: false },
          ]);
        }




      };

      newSocket.onclose = (event) => {
        console.log('WebSocket connection closed:', event.reason);
        // Attempt to reopen the WebSocket when closed
        setTimeout(openWebSocket, 1000);
      };

      return () => {
        newSocket.close();
      };
    };

    // Open the WebSocket when component mounts
    openWebSocket();

    // Close the WebSocket when component unmounts
    return () => {
      if (socket) {
        socket.close();
      }
    };
  }, [lastPart]); // Include lastPart in the dependency array


  // useEffect(() => {
  //   const openWebSocket = () => {
  //     const newSocket = new WebSocket(`wss://jawebcrm.onrender.com/ws/whatsapp_chatlogs/${JSON.parse(localStorage.getItem('UserObject')).phone_number_id}/`);

  //     newSocket.onopen = () => {
  //       console.log('Chatlog connection opened');
  //       setChatlogSocket(newSocket);
  //     };

  //     newSocket.onmessage = (event) => {
  //       const data = JSON.parse(event.data);
  //       console.log(data.message)
  //       setWhatsappChatLogs(data.message)
  //     };

  //     newSocket.onclose = (event) => {
  //       console.log('Chatlog connection closed:', event.reason);
  //       setTimeout(openWebSocket, 1000);
  //     };

  //     return () => {
  //       newSocket.close();
  //     };
  //   };

  //   openWebSocket();

  //   return () => {
  //     if (chatlogSocket) {
  //       chatlogSocket.close();
  //     }
  //   };
  // }, []);


  const apiCall = async (userMessage) => {
    try {



      const response = await fetch(`${config.apiUrl}message-create/`, {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
        },
        body: JSON.stringify({
          message: userMessage,
          isBusiness: false,
          session_id: session_id,
          organization: userdataVar.username,
          from_business: false
        }),
      });

      if (response.ok) {
        const data = await response.json();
        // console.log(data)


      } else {

        console.error('Failed to fetch data from the API');
        // apiCall(userMessage)
      }
    } catch (error) {
      console.error('Error occurred while fetching data:', error);


    }



  }


  const handleKeyPress = async (e) => {

    if (e.key === 'Enter') {

      const userMessage = e.target.value;

      setMessageInput('')


      if (socket && socket.readyState === socket.OPEN) {
        const additionalData = { isBusiness: false, disableChatbot: botControlled };
        const messagePayload = JSON.stringify({
          message: userMessage,
          additionalData: additionalData,
        });
        socket.send(messagePayload);


      }

      // scrollToBottom()
      apiCall(userMessage)




    }
  }

  // return newdate

  const formatTime = (time) => {

    if (time) {
      // console.log(time,'---time');
      
      const [hour, minute] = time.split(':');
  
      // console.log(hour, minute,'----time');
      
      let newHour = parseInt(hour, 10);
      const suffix = newHour >= 12 ? 'PM' : 'AM';
      newHour = newHour % 12 || 12;
      return `${newHour}:${minute} ${suffix}`;
      
    }
  }

  const formatDate = (date) => {
    console.log(date,'-----------------time date');
    return new Date(date).toLocaleDateString('en-US', { weekday: 'long', month: 'long', day: 'numeric' });
  };


  const currentTime = () => {
    var d = new Date()
    let newHour = parseInt(d.getUTCHours(), 10);
    const suffix = newHour >= 12 ? 'PM' : 'AM';
    newHour = newHour % 12 || 12;

    return `${d.getUTCHours()}:${d.getUTCHours()} ${suffix}`
  }

  const handleBotControl = () => {

    let formData = new FormData();
    formData.append('session_id', lastPart);
    formData.append('session_type', 'normal');
    formData.append('toggle_action', !botControlled);

    const headers = {
      headers: {
        'Content-Type': 'application/json',
        'Authorization': `Token ${JSON.parse(localStorage.getItem('Userdata')).token}`,
        'Accept': 'application/json'
      }
    };

    axios.post(`${config.apiUrl}disable-chatbot/`, formData, headers).then((res) => {
      setBotControlled(!botControlled)
      console.log(res.data, '=--------bot control');
    })
  }

  const handleWhatsappBotControl = () => {

    let formData = new FormData();
    formData.append('session_id', activeWhatsappSession.user_session_id);
    formData.append('session_type', 'whatsapp');
    formData.append('toggle_action', !whatsappBot);

    const headers = {
      headers: {
        'Content-Type': 'application/json',
        'Authorization': `Token ${JSON.parse(localStorage.getItem('Userdata')).token}`,
        'Accept': 'application/json'
      }
    };

    axios.post(`${config.apiUrl}disable-chatbot/`, formData, headers).then((res) => {
      console.log(res.data, '=--------bot control');
      setWhatsappBot(!whatsappBot)

    })

  }

  const [isEditing, setIsEditing] = useState(false)
  const [editedMsgId, setEditedMsgId] = useState('')
  const [originalMsg, setOriginalMsg] = useState('')

  const now = new Date();
  const hours = now.getUTCHours();
  const minutes = now.getUTCMinutes().toString().padStart(2, '0');
  const period = hours >= 12 ? 'PM' : 'AM';
  const hoursIn12Format = hours % 12 || 12; // Convert 0 hours to 12
  const currentTimeInGMT = `${hoursIn12Format}:${minutes} ${period}`;
  

  const handleMsgEdit = (msgObj) => { 
    setIsEditing(true);
    setEditedMsgId(msgObj.id)
    setMessageInput(msgObj.message);
    setOriginalMsg(msgObj.message)
    
  }

  const handleEditMsgSave = (e) => {

    if (e.key === 'Enter') {

      const editedMessage = e.target.value;

      let formData = new FormData();
      formData.append('edited_msg', editedMessage);
      formData.append('msg_id', editedMsgId);

      const headers = {
        headers: {
          'Content-Type': 'application/json',
          'Authorization': `Token ${JSON.parse(localStorage.getItem('Userdata')).token}`,
          'Accept': 'application/json'
        }
      };

      setMessageInput('');
      axios.post(`${config.apiUrl}edit-message/`, formData, headers).then((res) => {
        
        setMessages((prevChatMessages) =>
          prevChatMessages.map((msg) =>
            msg.id === editedMsgId
              ? { ...msg, message: editedMessage }
              : msg
          )
        );

        if (socket && socket.readyState === socket.OPEN) {
          const additionalData = { editedMsg: true, originalMsg: originalMsg };
          const messagePayload = JSON.stringify({
            message: editedMessage,
            additionalData: additionalData,
          });
          socket.send(messagePayload);
        }
          
        setIsEditing(false);

      })
    }

  }

  function rightside() {
    document.body.classList.toggle('open_customer_info')
  }
  return (

    <CustomLayout>

      <div className="py-2 md:px-6 w-full chat-content-width">
        <section className="chat-section">
          <div className="chat-container">
            <div className="chat-content">
              <div className="content-sidebar">
                <div>
                  {/* <div className="chatSearch">
                    <FontAwesomeIcon icon={faSearch} className="search-icon" />
                    <input type="text" placeholder="Search" className='input_search_chat' />
                  </div> */}
                  <div className='custom_border-active'>
                    <div className='cus_chat_wrapper flex new-wrapper'>
                      <div className='cus_content'>
                        <h2 className='Active-chat-text'>{ messagesTranslations.activeChats}</h2>

                        <h2 className='Active-chat-no'>{totalChats} {messagesTranslations.chats}</h2>
                      </div>
                      <div className='chat-btn flex'>

                      </div>
                    </div>
                  </div>
                  <div className='flex socialIcon'>
                    <div className={`icon_wrapper ${!whatsappOpen ? 'isactive' : ''}`} onClick={() => toggleWhatsappSection('chat')}>
                      <IoChatbubbleEllipses className='custom-icon chatsIcon-clr' />
                      <span className='icontext-wrapper'>{ messagesTranslations.chats}</span>
                    </div>
                    <div className={`icon_wrapper ${whatsappOpen ? 'isactive' : ''}`} onClick={() => toggleWhatsappSection('whatApp')}>
                      <IoLogoWhatsapp className='custom-icon whatsapp-icon-sidebar' />
                      <span className='icontext-wrapper'>{messagesTranslations.whatsapp}</span>
                    </div>
                  </div>

                </div>
                {!whatsappOpen && <div className="content-sidebar-title div-wrapper">
                  Chats
                </div>}
                {!whatsappOpen && <div className="content-messages">

                  <ul className="content-messages-list">
                    {spin ? (
                      <div className='flex justify-center'>
                        <Spin />
                      </div>
                    ) : chats.length > 0 ? (
                      chats.map((chat, id) => (
                        <li key={id} onClick={() => handleChatClick(chat)}
                          className={activeChatId === chat.user_session_id ? 'active-chat' : ''}
                        >
                          <a onClick={() => handleUserSession(chat.user_session_id)} value={chat.user_session_id}>
                            <span className="content-message-info">
                              <span className="content-message-name">{chat.name}</span>
                              <span className="content-message-text">{chat.country}</span>
                            </span>
                            <span className="content-message-more">
                              {chat?.unread == 0 ?
                                <></> :
                                <span className='content-message-unread'>{chat.unread}</span>
                              }
                            </span>
                          </a>

                        </li>

                      ))
                    ) : (
                      <div className='flex flex-col items-center'>
                        <div>
                          <span className='text-gray-500'>oops Empty chats</span>

                        </div>

                      </div>
                    )}
                  </ul>
                </div>}

                {whatsappOpen && (
                  <div>
                    <div className="content-sidebar-title div-wrapper">
                      {messagesTranslations.whatsapp}
                      <IoLogoWhatsapp className='whatsapp-icon' />

                    </div>
                    <div className="content-messages">
                      <ul className="content-messages-list">

                        {spin ? (
                          <Spin className='spin-align' />
                        ) : whatsappChatLogs.length > 0 ? (

                          whatsappChatLogs.map((chat, id) => (
                            <li key={id}
                              onClick={() => handleChatClick(chat)}

                              className={activeChatId === chat.user_session_id ? 'active-chat' : ''}

                            >
                              <a onClick={() => handleWhatsappscreen(chat)} value={chat.user_session_id}>
                                <span className="content-message-info">
                                  <span className="content-message-name">{chat.display_name ? chat.display_name : chat.phone_number}</span>
                                  <span className="content-message-text">{chat.last_message}</span>
                                </span>
                                {/* <span className="content-message-more">
                                  {chat?.unread == 0 ?
                                    <></> :
                                    <span className='content-message-unread'>{chat.unread}</span>
                                  }
                                </span> */}
                              </a>
                            </li>
                          ))
                        ) : (
                          <div className='flex flex-col items-center'>
                            <div>
                              <span className='text-gray-500'>oops Empty chats</span>

                            </div>

                          </div>
                        )}
                      </ul>
                    </div>
                  </div>
                )}
              </div>

              {whatsappScreen ? <div className={selectedLang == 'ar' ? 'arConverstion' : "conversation active "} id="conversation-1">

                <div class="column2">
                  <>

                    <div className='chatheader flex'>
                      <div className='Chatprofile flex'>
                        <div className='sideprofile'>
                          <img alt='' />
                        </div>
                        <div>
                          <h2 className='profile_name_text'>{activeWhatsappSession.display_name ? activeWhatsappSession.display_name : activeWhatsappSession.phone_number}</h2>
                          {/* <span className='profile-status'>Online</span> */}
                        </div>

                      </div>
                      <div className='flex menuBTns gap-btw'>
                        {/* <button type="button" class="circle-icon borderCus">
                            <FiPhone className='iconCLr' />
                          </button>
                          <button type="button" class="circle-icon borderCus">
                            <FiVideo className='iconCLr' />
                          </button> */}
                        <button type="button" class="circle-icon " >
                          <HiOutlineDotsVertical className='iconCLr' onClick={() => { SetuserProfile(true) }} width={25} />
                        </button>
                      </div>


                    </div>
                    <div className='whatApp_toggle'>
                      <label className='currentTime text_ctrl'>
                        {/* <input

                          type="checkbox"
                          checked={whatsappBot}
                          onChange={handleWhatsappBotControl}
                        /> */}
                        Take Control
                        <Switch
                          checked={whatsappBot}
                          onChange={handleWhatsappBotControl}
                          className="toggle-switch "
                        />


                      </label>
                    </div>
                    <div className='Conversation_section conversation-main' ref={messsagelistRef}>
                      <span className='currentTime'>Today, 11:03 AM </span>
                      {
                        messageList.length > 0 ? messageList.map((data, index) => (

                          data.from_bot ? <div className='leftConatiner'>
                            <div className="conversation-item">
                              <div className="conversation-item-content">
                                <div className="conversation-item-wrapper">
                                  <div className='con-right'>
                                    <div className="conversation-item-box">
                                      <div
                                        className={`conversation-item-text conversation-senderMsg ${isMessageExpanded(data.id) ? 'expanded' : ''}`}
                                      >
                                        <p className={`mb-none ${isMessageExpanded(data.id) ? 'expanded' : ''}`}>{data.message}</p>
                                        {(data.message.length > 140 && !isMessageExpanded(data.id)) && (
                                          <span className="read-more" onClick={() => toggleExpand(data.id)}>
                                            ... Read More
                                          </span>
                                        )}
                                        {isMessageExpanded(data.id) && (
                                          <span className="read-less" onClick={() => toggleExpand(data.id)}>
                                            Read Less
                                          </span>
                                        )}
                                        {/* <div className="conversation-item-time">12:30</div> */}
                                      </div>
                                      <div className='flex senderName cus-gap-5'>
                                        <span className="time-left">{data.time ? formatTime(data.time) : currentTime()}</span>
                                        <h2 className='sender-name-text mb-0'>You</h2>
                                      </div>
                                    </div>
                                  </div>
                                </div>
                              </div>
                            </div>

                          </div> :

                            <div className="conversation-item me">

                              <div className="conversation-item-content">
                                <div className="conversation-item-wrapper">
                                  <div className='con-left'>
                                    <div className="conversation-item-box">
                                      <div
                                        className={`conversation-item-text conversation-receiverMsg ${isMessageExpanded(data.id) ? 'expanded' : ''}`}
                                      >
                                        <p className={`mb-none ${isMessageExpanded(data.id) ? 'expanded' : ''}`}>{data.message}</p>
                                        {(data.message.length > 140 && !isMessageExpanded(data.id)) && (
                                          <span className="read-more" onClick={() => toggleExpand(data.id)}>
                                            ... Read More
                                          </span>
                                        )}
                                        {isMessageExpanded(data.id) && (
                                          <span className="read-less" onClick={() => toggleExpand(data.id)}>
                                            Read Less
                                          </span>
                                        )}
                                        {/* <div className="conversation-item-time">12:30</div> */}
                                      </div>
                                      <div className='receiver-name flex cus-gap-5'>
                                        <h2 className='mb-0 sender-name-text'>user</h2>
                                        <span className="time-right">{data.time ? formatTime(data.time) : currentTime()}</span>
                                      </div>
                                    </div>
                                  </div>
                                </div>
                              </div>
                            </div>
                        )) : ''
                      }

                    </div>

                    <div className='chat-input-section'>
                      <form onSubmit={handleWhatsappTextMsgSend}>
                        <div className='input-wrapper new-input-wrapper'>
                          <div className='emoji-picker-container' ref={emojiPickerRef}>
                            <input
                              type='text'
                              className='search-bar conversation-form-input whatsaap-input'
                              value={whatsappTextMsg}
                              onChange={(e) => setWhatsappTextMsg(e.target.value)}
                              placeholder={messagesTranslations.typePlaceholder}
                            />

                            {/* Button to toggle emoji picker */}
                            <button
                              type="button"
                              className='emoji-icon emoji-btn'
                              onClick={() => setShowEmojiPicker(!showEmojiPicker)}
                            >
                              <MdEmojiEmotions className='emojiSvg' />
                            </button>

                            {/* Send button */}
                            {whatsappTextMsg.trim() && (
                              <button
                                onClick={handleWhatsappTextMsgSend}
                                disabled={!whatsappTextMsg.trim()}
                                className='send-button'
                              >
                                <IoMdSend />
                              </button>
                            )}

                            {/* Emoji Picker */}
                            {showEmojiPicker && (
                              <div className='emoji-picker-popup'>
                                <EmojiPicker
                                  onEmojiClick={handleEmojiClick}
                                  disableAutoFocus={true}
                                  native={true}
                                />
                              </div>
                            )}
                          </div>
                        </div>
                      </form>
                    </div>
                  </>

                </div>

              </div>
                :
                <>
                  {session_id === "none" ? (
                    <div 
                    className={selectedLang == 'ar' ? 'arPad' : "conversation conversation-default active"}
>



                      <div className='chat_wrapper '>
                        {/* <div className='noChat_found'> */}
                        <div className='wrapperImage'>
                          <div>
                            <img src={ncImg} alt='test' className='ncImg' ></img>
                          </div>


                          <h2 className='text_noChat'>Looks like you don't have any chats yet!</h2>
                        </div>

                      </div>

                    </div>
                  ) : (

                    <div className={selectedLang == 'ar' ? 'arConverstion' : "conversation active "} id="conversation-1">
                      <div class="conversation-top">
                        <div className='Chatprofile flex'>
                          {/* <div className='sideprofile'>
                            <img alt='' />
                          </div> */}
                          <div>
                            <div class="conversation-user">
                              <div class="conversation-user-name">{chatlog.name}</div>
                              {/* <span className='profile-status'>Online</span> */}

                            </div>



                          </div>

                        </div>

                        <div className='flex menuBTns'>

                          <button type="button" class="circle-icon " >
                            <HiOutlineDotsVertical className='iconCLr' onClick={() => { SetuserProfile(true) }} width={25} />
                          </button>
                        </div>

                      </div>

                      <div className='whatApp_toggle'>
                        <label className='currentTime text_ctrl'>
                          {messagesTranslations.takecontrol}
                          <Switch
                            checked={botControlled}
                            onChange={handleBotControl}
                            className="toggle-switch"
                          />
                        </label>

                      </div>


                      <div className="conversation-main" ref={messsageRef}>
                        <ul className="conversation-wrapper">
                          {/* <div className="coversation-divider"><span>Today</span></div> */}
                          {Messages.length > 0 ? Messages.map((chat, id) => (
                            <React.Fragment key={id}>
                              {/* <span className='currentTime'>Today, 11:03 AM </span> */}

                            {/* {id === 0 || formatDate(chat.date) !== formatDate(Messages[id - 1].date) ? (
                              <span className='currentTime'>{formatDate(chat.date)}</span>
                            ) : null} */}

                              {!chat.isBusiness ? (
                                
                                <li className="conversation-item me">
                                  <div className="conversation-item-content">
                                    <div className="conversation-item-wrapper">
                                      <div className='con-right'>
                                        <div className="conversation-item-box">
                                          <div className={`conversation-item-text conversation-receiverMsg ${isMessageExpanded(chat.id) ? 'expanded' : ''}`}>
                                            <p className={`mb-none ${isMessageExpanded(chat.id) ? 'expanded' : ''}`}>{chat.message}</p>
                                            {(chat.message.length > 140 && !isMessageExpanded(chat.id)) && (
                                              <span className="read-more" onClick={() => toggleExpand(chat.id)}>
                                                ... Read More
                                              </span>
                                            )}
                                            {isMessageExpanded(chat.id) && (
                                              <span className="read-less" onClick={() => toggleExpand(chat.id)}>
                                                Read Less
                                              </span>
                                            )}
                                            <div style={{ position: 'relative', width: '100%', height: '100%' }}>

                                            <img
                                                src={editIcon}
                                                onClick={() => handleMsgEdit(chat)}
                                                style={{
                                                  cursor: 'pointer',
                                                  position: 'absolute',
                                                  bottom: '0px', /* Adjust as needed */
                                                  right: '0px', /* Adjust as needed */
                                                }}
                                                />
                                                </div>
                                          </div>
                                          <div className='flex senderName cus-gap-5'>
                                            <span className="time-left">{formatTime(chat.time)?formatTime(chat.time):currentTimeInGMT}</span>
                                              <h2 className='sender-name-text mb-0'>You</h2>
                                          </div>
                                          
                                        </div>
                                      </div>
                                    </div>

                                  </div>
                                </li>
                              ) : (
                                <>


                                  <li className="conversation-item">
                                    {/* <div className="conversation-item-side">
                                      <img className="conversation-item-image" src="https://encrypted-tbn1.gstatic.com/licensed-image?q=tbn:ANd9GcSb5YTP_Zfb9Aj9h3n79iDjofIAWbIRCn2mbRxjP04h8I7nDF1tj5DP_oCVy4xqRAyd5fxaiA9eZGJ0W4I" alt="" />
                                    </div> */}
                                    <div className="conversation-item-content">
                                      <div className="conversation-item-wrapper">
                                        <div className='con-left'>
                                          <div className="conversation-item-box">
                                            <div className={`conversation-item-text conversation-senderMsg ${isMessageExpanded(chat.id) ? 'expanded' : ''}`}>
                                              <p className={`mb-none ${isMessageExpanded(chat.id) ? 'expanded' : ''}`}>{chat.message}</p>
                                              {(chat.message.length > 140 && !isMessageExpanded(chat.id)) && (
                                                <span className="read-more" onClick={() => toggleExpand(chat.id)}>
                                                  {messagesTranslations.readMore}
                                                </span>
                                              )}
                                              {isMessageExpanded(chat.id) && (
                                                <span className="read-less" onClick={() => toggleExpand(chat.id)}>
                                                  {messagesTranslations.readLess}
                                                </span>
                                              )}
                                              {/* <div className="conversation-item-time">12:30</div> */}
                                              </div>
                                              <div className='receiver-name flex cus-gap-5'>
                                            <h2 className='mb-0 sender-name-text'>User</h2>
                                            <span className="time-right">{formatTime(chat.time)?formatTime(chat.time):currentTimeInGMT}</span>
                                          </div>
                                            {/* <div className='flex senderName cus-gap-5'>
                                              <span className="time-left">09:23 AM</span>
                                              <h2 className='sender-name-text mb-0'>You</h2>
                                            </div> */}
                                          </div>
                                        </div>
                                      </div>

                                    </div>
                                  </li>
                                </>


                              )}
                            </React.Fragment>
                            
                          )) : <div className={selectedLang == 'ar' ? 'arPad' : "conversation chat-conversation-default active"}>

                            <div className='chat_wrapper '>
                              <div className='wrapperImage'>
                                <div>
                                  <img src={ncImg} alt='test' className='ncImg' ></img>
                                </div>
                                <h2 className='text_noChat'>Looks like you don't have any chats yet!</h2>
                              </div>
                            </div>

                        </div>}

                        </ul>
                      </div>

                      <div className="conversation-form">

                        <div className="conversation-form-group">
                          {/* <div className='test' style={{ height: messagesSuggestions?.length == 0 ? 0 : '100px' }}>
                            <div class="scroll-wrapper">

                              {messagesSuggestions && messagesSuggestions.length > 0 ? (
                                <div className='premadechat-wrapper'>
                                  {messagesSuggestions.map((suggestedMsg, index) => (
                                    <div key={index} className="conversation-item-text conversation-senderMsg new-wrapper-sugg" onClick={() => handleMessageSuggestion(suggestedMsg, 'normalMsg')}>
                                      <p className='mb-none rem-MB'>{suggestedMsg}</p>
                                    </div>
                                  ))}
                                </div>
                              ) : <div></div>}

                            </div>

                          </div> */}

                          {/* <input
                            id="messageInput"
                            className="conversation-form-input"
                            rows="1"
                            placeholder={messagesTranslations.sendChatMsgPlaceholder}
                            value={messageInput}
                            disabled={chatlog.closed}
                            onChange={handleInputChange}
                            onKeyPress={handleKeyPress}

                          // Attach the key press event listener

                            />
                             */}
                            {isEditing ? <input
                                  id="messageInput"
                                  className="conversation-form-input"
                                  rows="1"
                                  placeholder={messagesTranslations.sendChatMsgPlaceholder}
                                  value={messageInput}
                                  disabled={chatlog.closed}
                                  onChange={handleInputChange}
                                  onKeyPress={handleEditMsgSave}
                                  />
                              : <input
                                  id="messageInput"
                                  className="conversation-form-input"
                                  rows="1"
                                  placeholder={messagesTranslations.sendChatMsgPlaceholder}
                                  value={messageInput}
                                  disabled={chatlog.closed}
                                  onChange={handleInputChange}
                                  onKeyPress={handleKeyPress}
                                  />
                            }

                          {/* <Tooltip open={chatlog.closed} title="Session Closed" /> */}
                          <button type="button" className="conversation-form-record">
                            <i className="ri-mic-line"></i>
                          </button>
                        </div>

                      </div>
                    </div>
                  )}
                </>

              }


            </div>

          </div>



        </section>
      </div>

      <ChatView type={messageType} whatsappSession={activeWhatsappSession} data={chatlog} team={Supports} onUpdateData={handleUpdateData} messages={Messages} visible={Userprofile} close={() => { closeUserProfile() }} />
    </CustomLayout>
  );
}