import { useNavigate } from 'react-router-dom';
import { CheckOutlined, CloseOutlined } from '@ant-design/icons';
import './CSS/Pricing.css';
import colorJawebLogo from '../../assets/Onboarding/colorLogo.png';

export default function Pricing() {
  const navigate = useNavigate();

  const webAccessData = {
    name: "Web Access",
    description: "Everything you need to run on the web",
    price: "$39/month", // Monthly price
    yearlyPrice: "$444/year", // Yearly price
    benefits: [
      "Access to a nice dashboard with analytics",
      "Zapier Integration",
      "WhatsApp integration",
      "Real-time messaging",
      "AI-powered chatbot to answer clients' questions",
      "Tracking of orders",
      "Tracking of unique Jaweb clients",
      "Answering questions about products",
    ],
    buttonText: "Choose Now",
  };

  return (

    <div className="pricing-section-dark">
      <div>
        <div>
          <img src={colorJawebLogo}  className='signup-logo'/>
          </div>
      <div className="pricing-cards-dark h-full">
        {/* Monthly Pricing Card */}
        <div className="pricing-card-dark">
          <h3>{webAccessData.name} - Monthly</h3>
          <p>{webAccessData.description}</p>

          <div className="price">
            <span>{webAccessData.price}</span>
          </div>

          <div className="features">
            {webAccessData.benefits.map((benefit, idx) => (
              <div key={idx} className="feature-item">
                {idx >= 5 ? <CloseOutlined /> : <CheckOutlined />}
                <span>{benefit}</span>
              </div>
            ))}
          </div>

          <button
            onClick={() => navigate('/auth/signup?plan=monthly', { state: { MakeStepToZero: true } })}
            className="action-button-dark"
          >
            {webAccessData.buttonText}
          </button>
        </div>

        {/* Yearly Pricing Card */}
        <div className="pricing-card-dark">
          <h3>{webAccessData.name} - Yearly</h3>
          <p>{webAccessData.description} with <b>5% off</b></p>

          <div className="price">
            <span>{webAccessData.yearlyPrice}</span>
          </div>

          <div className="features">
            {webAccessData.benefits.map((benefit, idx) => (
              <div key={idx} className="feature-item">
                {idx >= 5 ? <CloseOutlined /> : <CheckOutlined />}
                <span>{benefit}</span>
              </div>
            ))}
          </div>

          <button
            onClick={() => navigate('/auth/signup?plan=yearly', { state: { MakeStepToZero: true } })}
            className="action-button-dark"
          >
            {webAccessData.buttonText}
          </button>
        </div>
      </div>
      </div>
     
    </div>
  );
}
