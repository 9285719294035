



import React, { useState, useRef, useEffect } from 'react';
import "../../App.css"
import axios from 'axios';
import { Modal } from 'antd';
import ReactWhatsapp from 'react-whatsapp';

function WhatchatbotPlugin() {

  return (
    <div>
<div class="fixed-container">
    <div class="chat-box">
        <div class="header_chat">
            <div>
                <img src="chatbotLogo" alt="Logo"/> 
            </div>
            <div class="whatsapp-icon"></div>
        </div>

        <div class="messages-container">
            <div class="message business-message">
                <p>Business message content goes here</p>
            </div>
            <div class="message user-message">
                <p>User message content goes here</p>
            </div>
            
        </div>

   

        <div class="premadechat-wrapper-new">
            <div class="conversation-item-text-new">
                <p>Suggested message content </p>
            </div>
            <div class="conversation-item-text-new">
                <p>Suggested message content goes here</p>
            </div>
            <div class="conversation-item-text-new">
                <p>Suggested message content goes here</p>
            </div>
        </div>

        <div class="input-container">
            <input type="text" placeholder="Send Message ..."/>
            <button type="button">
                <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" fill="none" class="_svg_1cum9_1"><path d="M19.5656 12.5913C19.4092 12.9268 19.1426 13.1929 18.8233 13.3529L6.75036 19.4461C5.89387 19.8649 4.86032 19.5233 4.42563 18.6844C4.2343 18.2793 4.19549 17.822 4.32795 17.3955L5.64831 13.0972C5.7692 12.7037 6.13274 12.435 6.54448 12.435H11.1654C11.505 12.4337 11.7828 12.1592 11.7905 11.8101C11.7884 11.4663 11.514 11.1885 11.1654 11.1851H6.54788C6.13452 11.1851 5.76995 10.9144 5.65042 10.5187L4.34749 6.20514C4.07489 5.3055 4.58948 4.34594 5.50008 4.07643C5.92157 3.94393 6.37753 3.98415 6.76989 4.19362L18.8233 10.2868C19.6653 10.7168 20.0041 11.7517 19.5656 12.5913Z" fill="#000"></path></svg>
            </button>
        </div>
    </div>
</div>

    </div>
  );
}

export default WhatchatbotPlugin;
