import React, { useState, useEffect } from 'react';
import { CheckOutlined } from '@ant-design/icons';
import './PasswordValidation.css';
import { EyeOutlined, EyeInvisibleOutlined } from '@ant-design/icons';


const PasswordValidation = ({ password, setPassword, setValidations, validations }) => {
  const [showPassword, setShowPassword] = useState(false);
  // const [validations, setValidations] = useState({
  //   lowercase: false,
  //   uppercase: false,
  //   number: false,
  //   specialChar: false,
  //   length: false
  // });

  useEffect(() => {
    setValidations({
      lowercase: /[a-z]/.test(password),
      uppercase: /[A-Z]/.test(password),
      number: /[0-9]/.test(password),
      specialChar: /[!@#$%^&*(),.?":{}|<>]/.test(password),
      length: password.length >= 8
    });
  }, [password]);

  return (
    <div className="w-full">
      <div className="reset-input-container">
        <input
          type={showPassword ? 'text' : 'password'}
          value={password}
          onChange={(e) => setPassword(e.target.value)}
          className="reset-input"
          placeholder="Enter your password"
        />
        <div className="signup-icon" onClick={() => setShowPassword(!showPassword)}>
          {showPassword ? <EyeOutlined size={20} /> : <EyeInvisibleOutlined size={20} />}
        </div>
      </div>

      <div className="validation-container">
        <div className="space-y-2">
          <div className="validation-item">
            <div className={`validation-icon ${validations.lowercase ? 'valid' : 'invalid'}`}>
              {validations.lowercase && <CheckOutlined className="w-3 h-3 text-white" />}
            </div>
            <span className={`validation-text ${validations.lowercase ? 'valid' : 'invalid'}`}>
              Atleast 1 Lower-case
            </span>
          </div>

          <div className="validation-item">
            <div className={`validation-icon ${validations.uppercase ? 'valid' : 'invalid'}`}>
              {validations.uppercase && <CheckOutlined className="w-3 h-3 text-white" />}
            </div>
            <span className={`validation-text ${validations.uppercase ? 'valid' : 'invalid'}`}>
              Atleast 1 Upper-case
            </span>
          </div>

          <div className="validation-item">
            <div className={`validation-icon ${validations.number ? 'valid' : 'invalid'}`}>
              {validations.number && <CheckOutlined className="w-3 h-3 text-white" />}
            </div>
            <span className={`validation-text ${validations.number ? 'valid' : 'invalid'}`}>
              Atleast 1 Number
            </span>
          </div>

          <div className="validation-item">
            <div className={`validation-icon ${validations.specialChar ? 'valid' : 'invalid'}`}>
              {validations.specialChar && <CheckOutlined className="w-3 h-3 text-white" />}
            </div>
            <span className={`validation-text ${validations.specialChar ? 'valid' : 'invalid'}`}>
              Atleast 1 Special Character
            </span>
          </div>

          <div className="validation-item">
            <div className={`validation-icon ${validations.length ? 'valid' : 'invalid'}`}>
              {validations.length && <CheckOutlined className="w-3 h-3 text-white" />}
            </div>
            <span className={`validation-text ${validations.length ? 'valid' : 'invalid'}`}>
              More than 8 characters
            </span>
          </div>
        </div>
      </div>
    </div>
  );
};

export default PasswordValidation;
