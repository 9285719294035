import React, { useState, useEffect, useRef } from 'react';
import { Form, Avatar, Button, Input, Row, Col, message, Upload, Select } from 'antd';
import { UserOutlined } from '@ant-design/icons';
import { ROW_GUTTER } from '../../constants/ThemeConstant';
import Flex from '../components/shared-components/Flex';
import config from '../../config';
import { useNavigate } from "react-router-dom";
import languageData from '../../assets/data/language.data.json';
import { withTranslation, useTranslation } from 'react-i18next';
import axios from 'axios';



export function useNavigateFunction() {
    const navigate = useNavigate();
    return navigate;
}

const EditProfile = () => {
    const formRef = useRef(null);
    const navigate = useNavigateFunction();

    const UserInfo = JSON.parse(localStorage.getItem('UserObject'));
    // Determine if the mode is 'shopify'
    const shopify = UserInfo?.mode === 'shopify';

    const [userinfo, setUserinfo] = useState({});
    const [avatarUrl, setAvatarUrl] = useState('');
    const [name, setName] = useState('');
    const [email, setEmail] = useState('');
    const [username, setUsername] = useState('');
    const [first_name, setFirstName] = useState('');
    const [last_name, setLastName] = useState('');
    const [region, setRegion] = useState('');
    const [language, setLanguage] = useState('');
    const [city, setCity] = useState('');
    const [image, setImage] = useState('');
    const { Option } = Select;
    const [subscriptionData, setSubscriptionData] = useState(null);
    const [subscriptionStatus,setSubscriptionStatus]=useState('active')



    const { t } = useTranslation();
    const accountTranslations = t("accountSetting")

    const avatarEndpoint = 'https://www.mocky.io/v2/5cc8019d300000980a055e76';

    useEffect(() => {
        loadUserData();
    }, []);

    const loadUserData = async () => {
        try {
            let userdata = localStorage.getItem('Userdata');
            const userdataVarparsed = JSON.parse(userdata);
    
            const response = await fetch(`${config.apiUrl}user/CheckUserAuth`, {
                method: 'GET',
                headers: {
                    Authorization: `Token ${userdataVarparsed?.token}`,
                    'Content-Type': 'application/json',
                },
            });
    
            const data = await response.json();
            
            formRef.current.setFieldsValue({
                'name': data.name,
                'username': data.username,
                'email': data.email,
                'image': data.image,
                'last_name': data.last_name,
                'first_name': data.first_name,
                'language': data.language,
                'region': data.region,
            });
    
            setUserinfo(data);
            setImage(data.image);
    
            // Fetch subscription status
            const subscriptionResponse = await fetch(`${config.apiUrl}subscription-status/`, {
                method: 'GET',
                headers: {
                    Authorization: `Token ${userdataVarparsed?.token}`,
                    'Content-Type': 'application/json',
                },
            });
    
            const subscriptionData = await subscriptionResponse.json();
            setSubscriptionStatus(subscriptionData.subscriptionStatus);
            
            // Or any other fields you want to set
            setSubscriptionData(subscriptionData);
        } catch (error) {
            console.error('Error:', error);
        }
    };

    const onFinish = async (values) => {
        const key = 'updatable';
        message.loading({ content: 'Updating...', key });


        try {
            let userdata = localStorage.getItem('Userdata');
            const userdataVarparsed = JSON.parse(userdata);

            const response = await fetch(`${config.apiUrl}user/update-profile/`, {
                method: 'POST',
                headers: {
                    Authorization: `Token ${userdataVarparsed?.token}`,
                    'Content-Type': 'application/json',
                },
                body: JSON.stringify({
                    username: userinfo.username,
                    email:values.email,
                    first_name: values.first_name,
                    last_name: values.last_name,
                    region: values.region,
                    language: values.language,
                }),
            });

            if (response.ok) {
                setUserinfo(prevState => ({
                    ...prevState,
                    first_name: values.first_name,
                    email:values.email,
                    last_name: values.last_name,
                    region: values.region,
                    language: values.language,
                }));

                message.success({ content: 'Done!', key, duration: 2 });
            } else {
                console.error('Error updating user profile:', response.statusText);
                message.error({ content: 'Error updating profile', key, duration: 2 });
            }
        } catch (error) {
            console.error('Error:', error);
        }
    };

    const onFinishFailed = errorInfo => {
        console.log('Failed:', errorInfo);
    };



    const getBase64 = (img, callback) => {
        const reader = new FileReader();
        reader.addEventListener('load', () => callback(reader.result));
        reader.readAsDataURL(img);
    };




    return (
        <>

            <span className="text-black">{username}</span>
            <div className="mt-4">
                <Form
                    name="basicInformation"
                    layout="vertical"
                    initialValues={{
                        'name': name,
                        'username': username,
                        'email': email,
                        'first_name': first_name,
                        'last_name': last_name
                    }}
                    onFinish={onFinish}
                    onFinishFailed={onFinishFailed}
                    ref={formRef}
                >
                    <Row>
                        <Col xs={24} sm={24} md={24} lg={16}>
                            <Row gutter={ROW_GUTTER}>
                                <Col xs={24} sm={24} md={12}>
                                    <Form.Item
                                        label={accountTranslations.username}
                                        name="username"
                                    >
                                        <Input disabled={true} />
                                    </Form.Item>
                                </Col>
                                <Col xs={24} sm={24} md={12}>
                                    <Form.Item
                                        label="Email"
                                        name="email"
                                    >
                                        {userinfo.email? <Input  disabled={true}/>: <Input />}
                                       
                                    </Form.Item>
                                </Col>

                                <Col xs={24} sm={24} md={12}>
                                    <Form.Item
                                        label={accountTranslations.firstName}
                                        name="first_name"
                                        rules={[{
                                            required: true,
                                            type: 'text',
                                            message: 'This field is required'
                                        }]}
                                    >
                                        <Input />
                                    </Form.Item>
                                </Col>
                                <Col xs={24} sm={24} md={12}>
                                    <Form.Item
                                        label={accountTranslations.lastName}
                                        name="last_name"
                                        rules={[{
                                            required: true,
                                            type: 'text',
                                            message: 'This field is required'
                                        }]}
                                    >
                                        <Input />
                                    </Form.Item>
                                </Col>
                                <Col xs={24} sm={24} md={24}>
                                    <Form.Item
                                        label={accountTranslations.region}
                                        name="region"
                                    >
                                        <Input />
                                    </Form.Item>
                                </Col>
                            </Row>
                            <Button className='bg-[#7F28F8] text-white' htmlType="submit">
                                {accountTranslations.saveChange}
                            </Button>
                        </Col>
                    </Row>
                </Form>
            </div>
        </>
    );
};

export default EditProfile;
