import React from 'react';
import { Menu } from 'antd';
import { Link, useLocation } from 'react-router-dom';

const SettingOption = () => {
  const location = useLocation();
  const locationPath = location.pathname.split('/');
  const currentPath = locationPath[locationPath.length - 1];

 



  var UserInfo=JSON.parse(localStorage.getItem('UserObject'))
  console.log(UserInfo.mode)

  const showOnlyShopify = UserInfo?.mode ==='shopify'; 

  const menuOptions = [
    {
      key: 'Website',
      label: 'Website',
      icon: 'https://jawebstorage.us-iad-1.linodeobjects.com/images/Website_gambar-removebg-preview.png',
      path: '/integration/website',
    },
    {
      key: 'Wordpress',
      label: 'Wordpress',
      icon: 'https://jawebstorage.us-iad-1.linodeobjects.com/images/wordpress-icon.svg',
      path: '/integration/wordpress',
    },
    {
      key: 'Shopify',
      label: 'Shopify',
      icon: 'https://jawebstorage.us-iad-1.linodeobjects.com/images/shopify.svg',
      path: '/integration/shopify',
    },
    {
      key: 'WhatsApp',
      label: 'WhatsApp',
      icon: 'https://upload.wikimedia.org/wikipedia/commons/5/5e/WhatsApp_icon.png',
      path: '/integration/whatsapp',
    },
    {
      key: 'Zapier',
      label: 'Zapier',
      icon: 'https://www.good2gosoftware.com/wp-content/uploads/2023/02/2560px-Zapier_logo.svg_-2048x938.png',
      path: '/integration/zapier',
    },
    // Add more options as needed
  ];

  // Filter the menu options based on the control variable
  const filteredMenuOptions = showOnlyShopify
    ? menuOptions.filter(option => ['Shopify', 'WhatsApp','Zapier'].includes(option.key))
    : menuOptions.filter(option => option.key !== 'Shopify');

  return (
    <Menu mode="inline" selectedKeys={[currentPath]}>
      {filteredMenuOptions.map(option => (
        <Menu.Item key={option.key} icon={<img src={option.icon} alt={option.label} style={{ width: '1rem' }} />}>
          <Link to={option.path}>{option.label}</Link>
        </Menu.Item>
      ))}
    </Menu>
  );
};

export default SettingOption;
