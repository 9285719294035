

import React from 'react'

import CustomLayout from '../layouts/Customlayout';
import SettingOption from './Integration/Settingsoptions';
import { useTranslation } from 'react-i18next';

export default function Integration() {


  const { t } = useTranslation();
  const integrationTranslations = t("integration")

  return (

    <CustomLayout>

    <div >
   <div className='flex flex-col md:flex-row gap-6'>
              <div className='md:w-1/6'>
              <SettingOption/>
              </div>

                <div className='flex-1'>

                  <div className='mb-6'>
              <span className='text-2xl font-bold font-sans'>{ integrationTranslations.introduction}</span>
                  </div>

                  <div className='mb-6'>
                  <span>
                <span className='text-xl font-bold text-gray-500 font-sans'>Jaweb</span> <span className='text-gray-500 font-sans' >- { integrationTranslations.introductionNote }
                  </span>
                  </span>
                  </div>

                  <div className='mb-6'>
                    <span>
                <span className='text-xl font-bold text-gray-500 font-sans'>NOTE:</span> <span className='text-gray-500 font-sans'>{ integrationTranslations.introductionSubNote}</span>
                    </span>
                  </div>

                  <div  className='mb-4'>
                    <span className='text-2xl font-bold font-sans'>
                { integrationTranslations.supportedPlateforms}
                    </span>
                  </div>

                  <div className='flex flex-col gap-6'>
                      <div>
                <span className='text-gray-500 font-bold'>{ integrationTranslations.website}</span>
                      </div>

                      <div>
                <span className='text-gray-500 font-bold'>{ integrationTranslations.wordpress}</span>
                      </div>

                      <div>
                        <span className='text-gray-500 font-bold'>Shopify</span>
                      </div>
                      <div>
                <span className='text-gray-500 font-bold'>{ integrationTranslations.zapier}</span>
                      </div>
                  </div>

                </div>

    
            </div>


        

          



        </div>
        </CustomLayout>

  )
}