import React, { useState, useEffect, useRef } from 'react';
import OnboardingSlideshow from './OnboardingComponent/OnboardingSlideshow';
import colorJawebLogo from '../../assets/Onboarding/colorLogo.png';
import { DownOutlined, EyeOutlined, EyeInvisibleOutlined } from '@ant-design/icons';
import './SignupV2.css';
import axios from 'axios';
import config from '../../config';
import { message } from 'antd';

import { useStripe } from '@stripe/react-stripe-js';
import { useNavigate, useLocation } from 'react-router-dom';
import {LanguagesOptions, IndustriesOptions } from './LanguageOptions';
import shopifyLogo from '../../assets/Onboarding/shopifyLogo.svg';
import emailLogo from '../../assets/Onboarding/emailLogo.svg';
import leftArrow from '../../assets/arrows/LeftArrowIcon.svg';
import PasswordValidation from './OnboardingComponent/PasswordValidation';

export default function SignupV2({}) {
  const navigate = useNavigate();
  const location = useLocation(); // To get the token from URL

  const {MakeStepToZero} =  location.state || {};
  console.log(MakeStepToZero)

  
  const [currentStep, setCurrentStep] = useState(-1);

 

  useEffect(() => {
    const params = new URLSearchParams(location.search);
    const plan = params.get('plan'); // Get the plan parameter ('monthly' or 'yearly')
  
    // If plan is 'monthly' or 'yearly' or if MakeStepToZero is true, set the currentStep to 0
    if (plan === 'monthly' || plan === 'yearly' || MakeStepToZero) {
      setCurrentStep(0);
    }
  }, [location, MakeStepToZero]);


  const [otp, setOtp] = useState(['', '', '', '', '', '']);
  const [isOtpError, setIsOtpError] = useState(false);
  // const [isOtpVerified, setIsOtpVerified] = useState(false);
  const [generatedOtp, setGeneratedOtp] = useState('');

  const [timer, setTimer] = useState(60);
  const [windowWidth, setWindowWidth] = useState(window.innerWidth);
  
  const [formData, setFormData] = useState({
    companyName: '',
    website: '',
    revenue: '',
    language: '',
    industryType: '',
    email: '',
    username: '',
    password: '',
    confirmPassword: ''
  });

  const [formErrors, setFormErrors] = useState({
    companyName: '',
    website: '',
    revenue: '',
    language: '',
    industryType: '',
    email: '',
    username: '',
    password: '',
    confirmPassword: ''
  });

  const [showPassword, setShowPassword] = useState(false);
  const [showConfirmPassword, setShowConfirmPassword] = useState(false);

  const [isTokenValid, setIsTokenValid] = useState(false);
  const [disableBtn, setDisableBtn] = useState(false);
  const [validations, setValidations] = useState({
    lowercase: false,
    uppercase: false,
    number: false,
    specialChar: false,
    length: false
  });

  const stripe = useStripe();
  const inputRef = useRef();

  useEffect(() => {
    if (localStorage.getItem('token')) {
      navigate('/user/chatbot');
    }
  }, [])
  
    const setPassword = (password) => {
    setFormData((prevData) => ({
      ...prevData,
      password
    }));
  };


  // Parse the query parameters
  const params = new URLSearchParams(location.search);
  const plan = params.get('plan');  // This will be either 'monthly' or 'yearly'

  useEffect(() => {
    const handleResize = () => {
      setWindowWidth(window.innerWidth);
    };

    window.addEventListener('resize', handleResize);
    return () => {
      window.removeEventListener('resize', handleResize);
    };
  }, []);

  useEffect(() => {
    console.log(currentStep, '-----------------step');
    
    if (currentStep === 3) {
      const interval = setInterval(() => {
        setTimer((prevTimer) => (prevTimer > 0 ? prevTimer - 1 : 0));
      }, 1000);
      
      return () => clearInterval(interval);
    }
    
  }, [currentStep]);

  const validateField = (name, value) => {
    let error = '';
    if (!value.trim()) {
      error = `${name.charAt(0).toUpperCase() + name.slice(1).replace(/([A-Z])/g, ' $1')} is required`;
    }

    switch (name) {
      case 'email':
        if (value && !/\S+@\S+\.\S+/.test(value)) {
          error = 'Please enter a valid email address';
        }
        break;
      case 'website':
        if (value && !/^(https?:\/\/)?([\da-z.-]+)\.([a-z.]{2,6})([/\w .-]*)*\/?$/.test(value)) {
          error = 'Please enter a valid website URL';
        }
        break;
      case 'password':
        if (value && value.length < 6) {
          error = 'Password must be at least 6 characters long';
        }
        break;
      case 'confirmPassword':
        if (value !== formData.password) {
          error = 'Passwords do not match';
        }
        break;
    }

    return error;
  };

  const validateStep = (step) => {
    let errors = {};
    let isValid = true;

    switch (step) {
      case 0:
        ['companyName', 'website'].forEach(field => {
          const error = validateField(field, formData[field]);
          if (error) {
            errors[field] = error;
            isValid = false;
          }
        });
        break;
      case 1:
        ['language', 'industryType'].forEach(field => {
          const error = validateField(field, formData[field]);
          if (error) {
            errors[field] = error;
            isValid = false;
          }
        });
        break;
      case 2:
        const emailError = validateField('email', formData.email);
        if (emailError) {
          errors.email = emailError;
          isValid = false;
        }
        break;
      case 4:
        const usernameError = validateField('username', formData.username);
        if (usernameError) {
          errors.username = usernameError;
          isValid = false;
        }
        break;
      case 5:
        ['password', 'confirmPassword'].forEach(field => {
          const error = validateField(field, formData[field]);
          if (error) {
            errors[field] = error;
            isValid = false;
          }
        });
        break;
    }

    setFormErrors({ ...formErrors, ...errors });
    return isValid;
  };

  const handleInputChange = (e) => {
    const { name, value } = e.target;
    setFormData({
      ...formData,
      [name]: value
    });
    setFormErrors({
      ...formErrors,
      [name]: ''
    });
  };

  const handleOtpChange = (index, value) => {
    if (value.length <= 1 && /^\d*$/.test(value)) {
      const newOtp = [...otp];
      newOtp[index] = value;
      setOtp(newOtp);
      setIsOtpError(false);
      if (value && index < 5) {
        document.getElementById(`otp-${index + 1}`).focus();
      }
    }
  };

  const generateRandomNumberString = (length) => {
    let result = '';
    for (let i = 0; i < length; i++) {
      const randomDigit = Math.floor(Math.random() * 10);
      result += randomDigit;
    }
    return result;
  };

  const handleSendOtp = async () => {
    console.log(formData, '--dada');
    
    const otp = generateRandomNumberString(6);
    console.log(otp,'--otp');
    
    setGeneratedOtp(otp);    

    try {

      let tempEmail = formData.email
      console.log(tempEmail);
      
      const response = await axios.post(`${config.apiUrl}user/send-otp`, { email:tempEmail, otp, type: 'signup' });

      if (response.status === 200) {
        setDisableBtn(false)
        message.success('OTP sent successfully');
        setCurrentStep(currentStep + 1)

      } else {
        message.error('Failed to send OTP');
      }
      
    } catch (error) {
      if (error.name === 'Error' && error.errorFields) {
        message.error('Please fill out all required fields correctly.');
      } else {
        message.error('Error sending OTP');
      }
    }
  };


  const handleKeyDown = (index, event) => {
    if (event.key === 'Backspace' && otp[index] === '') {
      if (index > 0) {
        document.getElementById(`otp-${index - 1}`).focus();
      }
    }
  };

  const handleOtpVerification = (e) => {
    e.preventDefault();
    
    if (otp.join('') != generatedOtp) {
      setIsOtpError(true);
      return;
    }
    
    setIsOtpError(false);
    handleNextStep(e);
  };

  const handleResendOtp = async (e) => {
    e.preventDefault();
    setTimer(60);

    const otp = generateRandomNumberString(6);
    setGeneratedOtp(otp);
    console.log(otp,'----------------re otp');

    try {

      let tempEmail = formData.email
      console.log(tempEmail);
      
      const response = await axios.post(`${config.apiUrl}user/send-otp`, { email:tempEmail, otp, type: 'signup' });

      if (response.status === 200) {
        message.success('OTP sent successfully');
      } else {
        message.error('Failed to send OTP');
      }
    } catch (error) {
      if (error.name === 'Error' && error.errorFields) {
        message.error('Please fill out all required fields correctly.');
      } else {
        message.error('Error sending OTP');
      }
    }

  }

  const handleEmailUniqueness = (e) => {
    e.preventDefault();
    setDisableBtn(true)

      let tempEmail = formData.email
      console.log(tempEmail);
      
      const reqFormData = new FormData();
      reqFormData.append('email', tempEmail);
    
      axios.post(`${config.apiUrl}unique-email/`, reqFormData).then((res) => { 
        // setDisableBtn(false)
        handleSendOtp()

      }).catch(error => {
        setDisableBtn(false)

        message.error('Account with this email already exists.');
        
      });

  }

  const handleNextStep = (e) => {
    e.preventDefault();
    if (validateStep(currentStep)) {
      setCurrentStep(currentStep + 1);
    }
  };

  const handleSignup = (e) => {
    e.preventDefault();
    if (validateStep(5)) {
      console.log('Form submitted successfully:', formData);
      handleSignUp()
    }
  };



  // Function to extract the token from the URL query parameter
  const getTokenFromUrl = () => {
    const searchParams = new URLSearchParams(location.search);
    return searchParams.get('token');
  };

  useEffect(() => {
    const token = getTokenFromUrl();
    if (token) {
      validateToken(token);
    }
  }, [location]);

  // Function to validate the token with the backend
  const validateToken = async (token) => {
    try {
      const response = await axios.post(`${config.apiUrl}validate-invitation-token/`, { token });

      if (response.status === 200 && response.data.email) {
        setIsTokenValid(true);
        // setTokenEmail(response.data.email); // Pre-fill the email field
        // setEmail(response.data.email);
      }
    } catch (error) {
      console.error('Token validation failed:', error);
      message.error('Token has expired or is invalid');
      setIsTokenValid(false);
    }
  };

  const handleSignUp = async () => {
    const verificationCodeUser = generateRandomNumberString(4);
    const validationCheck = Object.values(validations).every(value => value === true);

    console.log(validationCheck,'---------------vali', validations);
    
    if (validationCheck) {

    let reqFormData = new FormData();
    reqFormData.append('username', formData.username);
    reqFormData.append('password', formData.password);
    reqFormData.append('company_type', formData.industryType);
    reqFormData.append('company_revenue', formData.revenue);
    reqFormData.append('language', formData.language);
    reqFormData.append('company_website', formData.website);

    if (!isTokenValid) {
      reqFormData.append('company_name', formData.companyName);
    }
    reqFormData.append('email', formData.email); // Pre-filled if token is valid
    reqFormData.append('otp', verificationCodeUser);
    // formData.dteam_id", generateRandomNumberString(10));
  
    try {
      const response = await axios.post(`${config.apiUrl}user/validate`, reqFormData, {
        headers: {
          'Content-Type': 'multipart/form-data',
        },
      });
  
      localStorage.setItem('username', formData.username);
      localStorage.setItem('password', formData.password);
      if (!isTokenValid) {
        localStorage.setItem('company_name', formData.companyName);
      }
      if (isTokenValid) {
        reqFormData.append('invitation', true);
      }
      localStorage.setItem('email', formData.email);
      localStorage.setItem('otp', verificationCodeUser);
      // localStorage.setItem('team_id', generateRandomNumberString(10));
  
      message.success({ content: "Valid Information", duration: 4 });
  
      localStorage.setItem('signupFormData', JSON.stringify([...reqFormData]));

      if (isTokenValid) {
        // If it's an invitation, redirect to the success page without payment
        navigate('/auth/payment/success');
      } else {
        // For normal signups, proceed with the Stripe payment
        setTimeout(async () => {
          try {
            const response = await fetch(`${config.apiUrl}payment/create-checkout/?user-name=${formData.username}&plan=${plan}`);
            const data = await response.json();
  
            if (!response.ok) {
              throw new Error(data.message || 'Failed to create payment intent');
            }
  
            const result = await stripe.redirectToCheckout({ sessionId: data.sessionId });
            if (result.error) {
              throw new Error(result.error.message);
            }
          } catch (error) {
            // setError(error.message);
          }
  
          // setSpin(false);
        }, 3000);
      }
  
    } catch (error) {
      if (error.response) {
        message.error({ content: "Invalid Values", duration: 4 });
    
        // setSpin(false);
      } else if (error.request) {
        console.error('No response received. Request:', error.request);
      } else {
        console.error('Error setting up the request:', error.message);
      }
    }
    } else {
        message.warning({ content: "Password validation failed", duration: 4 });
      
    }

  };

  const handleShopifySignup = () => {
    window.location.href = 'https://apps.shopify.com/jawebchatbot';
  };


  return (
    <div className="signup-page-container">
      {windowWidth >= 1000 && <OnboardingSlideshow />}
      <div style={{ flex: '1' }}>

        {currentStep === -1 && (
          <div className="signup-container">
            <img src={colorJawebLogo} alt="Jaweb logo" className="signup-logo" />

            <h1 className="signup-title">Do you have a shopify store to signup with?</h1>
            <p className="signup-subtitle">
              This will redirect you to the shopify signup flow.
            </p>
            
              <button className="signup-shopify-button" onClick={handleShopifySignup}>
                Signup with
                <img src={shopifyLogo} alt="Shopify icon" className="signup-shopify-icon" />
              </button>

              <button className="signup-shopify-button-2" onClick={()=>navigate('/auth/payment')}>
              Continue with
              <img src={emailLogo} alt="Shopify icon" className="signup-shopify-icon-2" />
                Email
            </button>
            <p className="signup-signup-text">
              Already have an account? <a href="/auth/login" className="login-signup-link">Login</a>
            </p>
          </div>
        )}


        {currentStep === 0 && (
          <div className="signup-container">
            <img src={leftArrow} style={{height:'50px', width:'30px', marginBottom:'50px', cursor:'pointer'}} onClick={()=>setCurrentStep(currentStep-1)}/>
            <img src={colorJawebLogo} alt="Jaweb logo" className="signup-logo" />
            <div className="signup-progress-bar">
              {[...Array(6)].map((_, index) => (
                <div
                  key={index}
                  className={`signup-progress-step ${index <= currentStep ? 'signup-progress-step-active' : ''}`}
                />
              ))}
            </div>
            <h1 className="signup-title">What's your company name?</h1>
            <p className="signup-subtitle">
              This will enable us to tailor our services to meet the specific needs of your business
            </p>
            <form onSubmit={handleNextStep}>
              <div className="signup-form-group">
                <label className="signup-label">Company Name*</label>
                <input
                  type="text"
                  name="companyName"
                  value={formData.companyName}
                  onChange={handleInputChange}
                  placeholder="Enter your Company Name"
                  className={`signup-input ${formErrors.companyName ? 'input-error' : ''}`}
                />
                {formErrors.companyName && (
                  <p className="error-message">{formErrors.companyName}</p>
                )}
              </div>
              <div className="signup-form-group">
                <label className="signup-label">Website*</label>
                <input
                  type="text"
                  name="website"
                  value={formData.website}
                  onChange={handleInputChange}
                  placeholder="Enter your website link"
                  className={`signup-input ${formErrors.website ? 'input-error' : ''}`}
                />
                {formErrors.website && (
                  <p className="error-message">{formErrors.website}</p>
                )}
              </div>
              <div className="signup-form-group">
                <label className="signup-label">Revenue* (optional)</label>
                <input
                  type="text"
                  name="revenue"
                  value={formData.revenue}
                  onChange={handleInputChange}
                  placeholder="Enter your company revenue"
                  className={`signup-input`}
                />

              </div>
              <button type="submit" className="signup-button">
                Next
              </button>
            </form>
          </div>
        )}

        {currentStep === 1 && (
          <div className="signup-container">
            <img src={leftArrow} style={{height:'50px', width:'30px', marginBottom:'50px', cursor:'pointer'}} onClick={()=>setCurrentStep(currentStep-1)}/>

            <img src={colorJawebLogo} alt="Jaweb logo" className="signup-logo" />
            <div className="signup-progress-bar">
              {[...Array(6)].map((_, index) => (
                <div
                  key={index}
                  className={`signup-progress-step ${index <= currentStep ? 'signup-progress-step-active' : ''}`}
                />
              ))}
            </div>
            <h1 className="signup-title">What language are your customers comfortable with?</h1>
            <p className="signup-subtitle">
              Understanding your language preference will allow us to create a better experience for you
            </p>
            <form onSubmit={handleNextStep}>
              <div className="signup-form-group">
                <label className="signup-label">Language*</label>
                <div className="signup-select-wrapper">
                  <select
                    name="language"
                    value={formData.language}
                    onChange={handleInputChange}
                    className={`signup-select ${formErrors.language ? 'input-error' : ''}`}
                  >
                    <option value="">Select language</option>

                    {
                      LanguagesOptions.map((lang, index) => (
                        <option value={lang.name}>{lang.name}</option>
                      ))
                    }

                  </select>
                  <div className="signup-select-icon">
                    <DownOutlined size={20} color="#666" />
                  </div>
                </div>
                {formErrors.language && (
                  <p className="error-message">{formErrors.language}</p>
                )}
              </div>
              <div className="signup-form-group">
                <label className="signup-label">Industry Type*</label>
                <div className="signup-select-wrapper">
                  <select
                    name="industryType"
                    value={formData.industryType}
                    onChange={handleInputChange}
                    className={`signup-select ${formErrors.industryType ? 'input-error' : ''}`}
                  >
                    <option value="">Select industry type</option>
                    {
                      IndustriesOptions.map((index) => (
                        <option value={index}>{index}</option>
                      ))
                    }

                  </select>
                  <div className="signup-select-icon">
                    <DownOutlined size={20} color="#666" />
                  </div>
                </div>
                {formErrors.industryType && (
                  <p className="error-message">{formErrors.industryType}</p>
                )}
              </div>
              <button type="submit" className="signup-button">
                Next
              </button>
            </form>
          </div>
        )}

        {currentStep === 2 && (
          <div className="signup-container">
            <img src={leftArrow} style={{height:'50px', width:'30px', marginBottom:'50px', cursor:'pointer'}} onClick={()=>setCurrentStep(currentStep-1)}/>

            <img src={colorJawebLogo} alt="Jaweb logo" className="signup-logo" />
            <div className="signup-progress-bar">
              {[...Array(6)].map((_, index) => (
                <div
                  key={index}
                  className={`signup-progress-step ${index <= currentStep ? 'signup-progress-step-active' : ''}`}
                />
              ))}
            </div>
            <h1 className="signup-title">What's your email address?</h1>
            <p className="signup-subtitle">
              This will be your registered address for all the essential communications
            </p>
            <form onSubmit={handleEmailUniqueness}>
              <div className="signup-form-group">
                <label className="signup-label">Email Address*</label>
                <input
                  type="email"
                  name="email"
                  value={formData.email}
                  onChange={handleInputChange}
                  placeholder="Enter your email address"
                  className={`signup-input ${formErrors.email ? 'input-error' : ''}`}
                />
                {formErrors.email && (
                  <p className="error-message">{formErrors.email}</p>
                )}
              </div>
              <button type="submit" className="signup-button" disabled={ disableBtn ? true : false}>
                {disableBtn ? 'Sending Otp' : 'Next'}
              </button>
            </form>
          </div>
        )}

        {currentStep === 3 && (
          <div className="signup-container">
            <img src={leftArrow} style={{height:'50px', width:'30px', marginBottom:'50px', cursor:'pointer'}} onClick={()=>setCurrentStep(currentStep-1)}/>

            <img src={colorJawebLogo} alt="Jaweb logo" className="signup-logo" />
            <div className="signup-progress-bar">
              {[...Array(6)].map((_, index) => (
                <div
                  key={index}
                  className={`signup-progress-step ${index < 4 ? 'signup-progress-step-active' : ''}`}
                />
              ))}
            </div>
            <h1 className="signup-title">Got our email?</h1>
            <p className="signup-subtitle">
              We've sent a One-Time-Password (OTP) to your email address
            </p>
            <form onSubmit={handleOtpVerification}>
              <p className="signup-timer-text">OTP</p>
              <div className="signup-otp-container">
                {otp.map((digit, index) => (
                  <input
                    key={index}
                    id={`otp-${index}`}
                    type="text"
                    maxLength="1"
                    className={`signup-otp-input ${isOtpError ? 'otp-input-signup-error' : ''}`}
                    value={digit}
                    onChange={(e) => handleOtpChange(index, e.target.value)}
                    onKeyDown={(e) => handleKeyDown(index, e)}
                    required
                  />
                ))}
              </div>
              
              {isOtpError && (
                <p className="signup-error-text">The OTP you've entered is invalid</p>
              )}
              <p className="signup-timer-text">{timer} sec</p>
              <p 
                className="signup-resend-link" 
                onClick={(e)=>(handleResendOtp(e))}
              >
                Didn't receive the code? Re-send OTP
              </p>
              <button 
                type="submit" 
                className="signup-button"
                disabled={otp.some(digit => !digit)}
              >
                Next
              </button>
            </form>
          </div>
        )}

        {currentStep === 4 && (
          <div className="signup-container">
            <img src={leftArrow} style={{height:'50px', width:'30px', marginBottom:'50px', cursor:'pointer'}} onClick={()=>setCurrentStep(currentStep-1)}/>

            <img src={colorJawebLogo} alt="Jaweb logo" className="signup-logo" />
            <div className="signup-progress-bar">
              {[...Array(6)].map((_, index) => (
                <div
                  key={index}
                  className={`signup-progress-step ${index <= currentStep ? 'signup-progress-step-active' : ''}`}
                />
              ))}
            </div>
            <h1 className="signup-title">Let's create a username for you?</h1>
            <p className="signup-subtitle">
              This will give you a unique identity, allowing us to better assist you in achieving your goals.
            </p>
            <form onSubmit={handleNextStep}>
              <div className="signup-form-group">
                <label className="signup-label">Username*</label>
                <input
                  type="text"
                  name="username"
                  value={formData.username}
                  onChange={handleInputChange}
                  placeholder="Create your username"
                  className={`signup-input ${formErrors.username ? 'input-error' : ''}`}
                />
                {formErrors.username && (
                  <p className="error-message">{formErrors.username}</p>
                )}
              </div>
              <button type="submit" className="signup-button">
                Next
              </button>
            </form>
          </div>
        )}

        {currentStep === 5 && (
          <div className="signup-container">
            <img src={leftArrow} style={{height:'50px', width:'30px', marginBottom:'50px', cursor:'pointer'}} onClick={()=>setCurrentStep(currentStep-1)}/>

            <img src={colorJawebLogo} alt="Jaweb logo" className="signup-logo" />
            <div className="signup-progress-bar">
              {[...Array(6)].map((_, index) => (
                <div
                  key={index}
                  className={`signup-progress-step ${index <= currentStep ? 'signup-progress-step-active' : ''}`}
                />
              ))}
            </div>
            <h1 className="signup-title">Let's protect your account</h1>
            <p className="signup-subtitle">
              Please create your password
            </p>
            <form onSubmit={handleSignup}>
              <div className="signup-form-group">
                <label className="signup-label">Password*</label>
                <div className="signup-input-wrapper">
                  {/* <input
                    type={showPassword ? 'text' : 'password'}
                    name="password"
                    value={formData.password}
                    onChange={handleInputChange}
                    className={`signup-input ${formErrors.password ? 'input-error' : ''}`}
                    placeholder="Enter your password"
                  />
                  <div className="signup-icon" onClick={() => setShowPassword(!showPassword)}>
                    {showPassword ? <EyeOutlined size={20} /> : <EyeInvisibleOutlined size={20} />}
                  </div> */}
                  <PasswordValidation password={formData.password} setPassword={setPassword} setValidations={setValidations} validations={validations}/>

                </div>
                {formErrors.password && (
                  <p className="error-message">{formErrors.password}</p>
                )}
              </div>
              <div className="signup-form-group">
                <label className="signup-label">Confirm Password*</label>
                <div className="signup-input-wrapper">
                  <input
                    type={showConfirmPassword ? 'text' : 'password'}
                    name="confirmPassword"
                    value={formData.confirmPassword}
                    onChange={handleInputChange}
                    className={`signup-input ${formErrors.confirmPassword ? 'input-error' : ''}`}
                    placeholder="Confirm your password"
                  />
                  <div className="signup-icon" onClick={() => setShowConfirmPassword(!showConfirmPassword)}>
                    {showConfirmPassword ? <EyeOutlined size={20} /> : <EyeInvisibleOutlined size={20} />}
                  </div>
                </div>
                {formErrors.confirmPassword && (
                  <p className="error-message">{formErrors.confirmPassword}</p>
                )}
              </div>
              <button 
                type="submit" 
                className="signup-button"
                disabled={!formData.password || !formData.confirmPassword}
              >
                Confirm
              </button>
            </form>
          </div>
        )}

        
      </div>
    </div>
  );
}