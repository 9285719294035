import axios from 'axios';
import React, {  useState } from 'react';
import { useNavigate,useParams } from 'react-router-dom';

import config from '../config';
import Spinner from './components/Spinner';
import { message } from 'antd';
import bg from '../../src/assets/svg/bg.png'


function Invitation() {
    const { invitation_id } = useParams();

  const [passwordMismatch, setPasswordMismatch] = useState(false);
  const [Filemax,setFilemax]=useState(false)
  const [spin,Setspin]=useState(false)

  const [UsernameError,setUsernameError]=useState(false)
  const [emailError,setEmailError]=useState(false)






  const [username, setUsername] = useState('');
  const [password, setPassword] = useState('');
  const [company,setCompany]=useState('');
  const [email,setEmail]=useState('');
  const [Phone,setPhone]=useState('');
  const [confirm_password,setPasswordConfirm]=useState('');
  const [error, setError] = useState("")
  const navigate = useNavigate();


  const handlePasswordChange = (e) => {
    setPassword(e.target.value);
    setPasswordMismatch(e.target.value !== confirm_password);
  };

  const handleConfirmPasswordChange = (e) => {
    setPasswordConfirm(e.target.value);
    setPasswordMismatch(e.target.value !== password);
  };


      function generateRandomNumberString(length) {
        let result = '';
      
        for (let i = 0; i < length; i++) {
          const randomDigit = Math.floor(Math.random() * 10); // Generate a random digit (0-9)
          result += randomDigit;
        }
      
        return result;
      }
      


      async function handlesignup() {

        const verificationcode_user=generateRandomNumberString(4)

        setError("");
        Setspin(true);
      
        let formData = new FormData();
      
        // Append the user data to the FormData
        formData.append('username', username);
        formData.append('password', password);
        formData.append('email', email);
        formData.append('otp',verificationcode_user);
        formData.append('invitation',invitation_id);



      
        try {
          // Send the image data to your Django API using Axios or another HTTP library
          const response = await axios.post(`${config.apiUrl}user/signup`, formData, {
            headers: {
              'Content-Type': 'multipart/form-data',
            },
          });
     
          // setSuccess(true)
          message.success({ content: "Account Created Successfully", duration: 4 });


          setTimeout(() => {
            
            navigate('/')
            Setspin(false)
       
          }, 3000);
    

        } catch (error) {
          // Handle the error
          if (error.response) {
            message.error({ content: "Invalid Values", duration: 4 });
            Setspin(false)
            const { errors } = error.response.data;
            const {status}=error.response.data
            console.log(status)
            if (errors){
                console.log()
            // Check if both username and email are present in the errors
            if (errors.username) {
              setUsernameError(true)

              
            }       
            if (errors.email) {
              setEmailError(true)

            }  
            else {
              // Handle other error cases
              console.error(errors);
            }

            console.error('Error response:', error.response.data);
            Setspin(false)

            }


          } else if (error.request) {
            // The request was made but no response was received
            console.error('No response received. Request:', error.request);
            Setspin(false)
          } else {
            // Something happened in setting up the request that triggered an Error
            console.error('Error setting up the request:', error.message);
            Setspin(false)
          }
        }

      }




  return (
    <div className="bg-[#F3E6FF] flex h-screen flex-1">

      <div className='hidden md:block'>
          <img className='h-screen' src={bg}/>
        </div>


      <div className='flex flex-col items-center flex-1 p-6'>

          <div>
            <img  className='h-[59.69px] w-[235.62px]' src='https://jawebstorage.us-iad-1.linodeobjects.com/images/Jaweb color logo.png'/>
          </div>
        
            


          <div>
              <span className='font-bold text-lg'>SIGN UP</span>
          </div>





            <div className="mb-2">
            <label for="phone" className="block text-sm font-semibold text-gray-800">
                Username
            </label>
            <input 
            name="company_name" 
            type="text" 
            placeholder='Username'
            value={username}
            onChange={(e) => setUsername(e.target.value)}

            className="block w-full px-6 pr-36 py-1 mt-1 text-black-700 bg-white border rounded-md focus:border-black-400 focus:ring-black-300 focus:outline-none focus:ring focus:ring-opacity-40"  required/>
            {UsernameError?<small id="passwordMismatch" className="text-red-500" >Username Already Taken</small>:<></>}

            </div>


            <div className="mb-2">
            <label for="phone" className="block text-sm font-semibold text-gray-800">
                Email
            </label>
            <input 
            name="Email" 
            type="email" 
            placeholder='olivia@orgainization.com'
            value={email}
            onChange={(e) => setEmail(e.target.value)}

            className="block w-full px-6 pr-36 py-1 mt-1 text-black-700 bg-white border rounded-md focus:border-black-400 focus:ring-black-300 focus:outline-none focus:ring focus:ring-opacity-40"  required/>
            {emailError?<small id="passwordMismatch" className="text-red-500" >Email is already connected to another account</small>:<></>}

            </div>


            <div className="mb-2">
            <label for="phone" className="block text-sm font-semibold text-gray-800">
                Password
            </label>
            <input 
            name="Password" 
            type="password" 
            placeholder='Password'
            value={password}
            onChange={handlePasswordChange}

            className="block w-full px-6 pr-36 py-1 mt-1 text-black-700 bg-white border rounded-md focus:border-black-400 focus:ring-black-300 focus:outline-none focus:ring focus:ring-opacity-40"  required/>
            </div>


            <div className="mb-2">
            <label for="phone" className="block text-sm font-semibold text-gray-800">
                Re-enter Password
            </label>
            <input 
            name="password-confirm" 
            type="password" 
            placeholder='Re-enter Password'
            value={confirm_password}
            onChange={handleConfirmPasswordChange}
            className="block w-full px-6 pr-36 py-1 mt-1 text-black-700 bg-white border rounded-md focus:border-black-400 focus:ring-black-300 focus:outline-none focus:ring focus:ring-opacity-40"  required/>
            {passwordMismatch?<small id="passwordMismatch" className="text-red-500" >Passwords do not match.</small>:<></>}

            </div>

            <div className="mb-2">
            <label for="phone" className="block text-sm font-semibold text-gray-800">
                Phone
            </label>
            <input 
            name="Phone" 
            type="text" 
            placeholder='Phone'
            value={Phone}
            onChange={(e) => setPhone(e.target.value)}
            className="block w-full px-6 pr-36 py-1 mt-1 text-black-700 bg-white border rounded-md focus:border-black-400 focus:ring-black-300 focus:outline-none focus:ring focus:ring-opacity-40"  required/>
            </div>



            <div className="mt-2">


            {spin?
              <Spinner/>
              :
              <button
                onClick={handlesignup}
                className="w-full px-36 py-2 tracking-wide text-white transition-colors duration-200 transform bg-gray-900 rounded-md hover:bg-gray-900 focus:outline-none focus:bg-gray-900"
                disabled={passwordMismatch || Filemax}
              >
                <span>Signup</span>
              </button>}
     

              <div className="relative flex items-center justify-center w-full mt-6 border border-t text-[#A7A7A7} px-2">
              <div className="absolute mx-6  text-[#A7A7A7]">Or</div>
             </div>

          <p className="mt-2 text-xs font-light text-center text-gray-700">
            Already Have an Account?{' '}
            <a className="font-medium text-purple-600 hover:underline" href="/">
              Login
            </a>
          </p>
    
            </div>

            <button class=" py-2 px-20 border flex gap-2 bg-white border-slate-200 rounded mt-2">
              <img class="w-6 h-6" src="https://www.svgrepo.com/show/475656/google-color.svg" loading="lazy" alt="google logo"/>
                <span>Signup with Google</span>
            </button>





      </div>

    </div>
  );
}

export default Invitation;
