import React, { useState, useEffect } from 'react';
import { Card, Table, Tag, Tooltip, message, Button, Popconfirm, Dropdown, Menu, Modal, Input, Checkbox } from 'antd';
import { EyeOutlined, DeleteOutlined, PlusCircleOutlined, SmallDashOutlined } from '@ant-design/icons';
import axios from 'axios';
import config from '../config';
import { useNavigate } from 'react-router-dom';
import Spinner from './components/Spinner';
import CustomLayout from '../layouts/Customlayout';
import CustomizationCheckbox from './teamCheckbox/CustomizationCheckbox';
import { useTranslation } from 'react-i18next';


export default function Users() {
  const navigate = useNavigate();
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [isLoading,setIsloading]=useState(false)
  const [TeamLead, setTeamLead] = useState('');
  const [Email, setEmail] = useState('');
  const [InvitaionId, setInvitationId] = useState('');
  const [spin, setSpin] = useState(false);
  const [Supports, setSupports] = useState([]);
  const token = localStorage.getItem('token'); // Retrieve the token from local storage

  const { t } = useTranslation();
  const teamsTranslations = t("teams")
  const navbarTranslations = t("navbar")

  useEffect(() => {
    loadUserData();
  }, []);

  const loadUserData = async () => {
    let userdata = localStorage.getItem('UserObject');
    const userdataVarParsed = JSON.parse(userdata);
    setInvitationId(userdataVarParsed?.team_id);
    setTeamLead(userdataVarParsed.email);

    setIsloading(true)

  
    try {
      const response = await axios.get(`${config.apiUrl}team/`, {
        headers: {
          Authorization: `Token ${token}`,
          'Content-Type': 'application/json',
        },
      });
      setSupports(response.data);
      setIsloading(false)
      // Set the team members based on the response
} catch (error) {
      console.error('Error fetching team data:', error);
    }
  };





  const handleAddition = async () => {
    setSpin(true);
  
    try {
      const response = await axios.post(`${config.apiUrl}team/`, {
        team_lead: TeamLead,
        invitation_id: InvitaionId,
        email: Email,
      }, {
        headers: {
          'Authorization': `Token ${token}`, // Include the authorization token
        },
      });
  
      message.success('Member added successfully');
      loadUserData()
      // setSupports([...Supports, response.data]);
      setIsModalOpen(false);
    } catch (error) {
      console.error('Error adding member:', error);
      message.error('Error adding member');
    } finally {
      setSpin(false);
    }
  };
  
  const handleDelete = async (email) => {
    try {
      await axios.delete(`${config.apiUrl}team/`, {
        params: { email},
        headers: {
          'Authorization': `Token ${token}`, // Include the authorization token
        },
      });
      message.success('Member deleted successfully');
      setSupports(Supports.filter((member) => member.email !== email));
    } catch (error) {
      console.error('Error deleting member:', error);
      message.error('Error deleting member');
    }
  };
  
  const handleMenuClick = async (e, email) => {
    try {

      setIsloading(true)
      
      const newPermission = e.key;
      await axios.put(`${config.apiUrl}team/`, {
        email,
        permission: newPermission,
      }, {
        headers: {
          'Authorization': `Token ${token}`, // Include the authorization token
        },
      });
      setIsloading(false)
      message.success('User permission updated successfully');
      loadUserData();  // Refresh the table data
    } catch (error) {
      console.error('Error updating user permission:', error);
      message.error('Error updating user permission');
    }
  };

  const menu = (email) => (
    <Menu onClick={(e) => handleMenuClick(e, email)}>
      <Menu.Item key="Super User">Super User</Menu.Item>
      <Menu.Item key="User">Marketing User</Menu.Item>
      <Menu.Item key="Integration">Integration User</Menu.Item> 
    </Menu>
  );

  const tableColumns = [
    {
      title: teamsTranslations.email,
      dataIndex: 'email',
      render: (email) => (
        <div className="d-flex text-left">
          <span>{email}</span>
        </div>
      ),
    },
    {
      title: () => <div className="text-left">{ teamsTranslations.status}</div>,
      key: 'created',
      dataIndex: 'created',
      render: (_, record) => (
        <div className="text-left">
          <Tag color={record.created ? 'cyan' : 'volcano'}>
            {record.created ? 'Created' : 'Pending'}
          </Tag>
        </div>
      ),
    },
    {
      title: () => <div className="text-left">{ teamsTranslations.permission}</div>,
      key: 'team_permission',
      dataIndex: 'team_permission',

  render: (_, record) => {
    let permissionText;
    let tagColor;

    switch (record.team_permission) {
      case 'User':
        permissionText = 'Marketing User';
        tagColor = 'blue';
        break;
      case 'Integration':
        permissionText = 'Integration User';
        tagColor = 'green';
        break;
      case 'Super User':
        permissionText = 'Super User';
        tagColor = 'gold';
        break;
      default:
        permissionText = 'Unknown Permission';
        tagColor = 'grey';
    }

    return (
      <div className="text-left">
        <Tag color={tagColor}>
          {permissionText}
        </Tag>
      </div>
    );
  },
    },

{
  title: () => <div className="text-left">{ navbarTranslations.customization}</div>,
      key: 'customization',
      dataIndex: 'customization',
      render: (_, record) => (
      <CustomizationCheckbox team_permission={record.team_permission === 'Super User' || record.team_permission === 'Integration'} />
      ),
    },
{
  title: () => <div className="text-left">{ navbarTranslations.leads}</div>,
      key: 'leads',
      dataIndex: 'leads',
      render: (_, record) => (
        <div className="text-left">
          <CustomizationCheckbox team_permission={record.team_permission === 'Super User' || record.team_permission === 'User'} />
        </div>
      ),
    },
{
  title: () => <div className="text-left">{ navbarTranslations.message}</div>,
      key: 'message',
      dataIndex: 'message',
      render: (_, record) => (
        <div className="text-left">
          <CustomizationCheckbox team_permission={record.team_permission === 'Super User' || record.team_permission === 'Integration'} />
        </div>
      ),
    },
{
  title: () => <div className="text-left">{navbarTranslations.integration}</div>,
      key: 'integration',
      dataIndex: 'integration',
      render: (_, record) => (
        <div className="text-left">
          <CustomizationCheckbox team_permission={record.team_permission === 'Super User' || record.team_permission === 'Integration'} />
        </div>
      ),
    },
{
  title: () => <div className="text-left">{navbarTranslations.accSetting}</div>,
      key: 'account',
      dataIndex: 'account',
      render: (_, record) => (
        <div className="text-left">
          <CustomizationCheckbox team_permission={record.team_permission === 'Super User' || record.team_permission === 'User' || record.team_permission === 'Integration'} />
        </div>
      ),
    },
{
  title: () => <div className="text-left">{navbarTranslations.botTraining}</div>,
      key: 'bot_training',
      dataIndex: 'bot_training',
      render: (_, record) => (
        <div className="text-left">
          <CustomizationCheckbox team_permission={record.team_permission === 'Super User' || record.team_permission === 'Integration'} />
        </div>
      ),
    },
{
  title: () => <div className="text-left">{navbarTranslations.help}</div>,
      key: 'help',
      dataIndex: 'help',
      render: (_, record) => (
        <div className="text-left">
          <CustomizationCheckbox team_permission={record.team_permission === 'Super User' || record.team_permission === 'Integration'} />
        </div>
      ),
    },


    {
      title: () => <div className="text-left">{ teamsTranslations.actoin}</div>,
      key: 'action',
      dataIndex: 'action',
      render: (_, record) => (
        <div className="text-left flex gap-2">
          <Popconfirm
            title="Are you sure to delete this user?"
            onConfirm={() => handleDelete(record.email)}
            okText="Yes"
            cancelText="No"
          >
            <DeleteOutlined className="text-2xl text-red-500" />
          </Popconfirm>
          <Dropdown overlay={menu(record.email)} trigger={['click']}>
            <SmallDashOutlined className="text-2xl" />
          </Dropdown>
        </div>
      ),
    },
  ];

  const openModal = () => {
    setIsModalOpen(true);
  };

  const closeModal = () => {
    setIsModalOpen(false);
    setEmail(''); // Clear email input when closing the modal
  };

  return (
    <CustomLayout>
      <Card bodyStyle={{ padding: '0px' }}>
        <div className="table-responsive">
          <Table loading={isLoading} columns={tableColumns} dataSource={Supports} rowKey="email" scroll={{ x: 'max-content' }} />
        </div>
      </Card>

      <div className="mt-4">
        <div className="mt-4 flex justify-end">
         
            <Button
              type="primary"
              className='bg-purple-600'
              icon={<PlusCircleOutlined />}
              onClick={openModal}
              disabled={spin}
            >
            { teamsTranslations.inviteMember}
            </Button>

        </div>
      </div>

      <Modal
        title="Invite Member"
        visible={isModalOpen}
        onCancel={closeModal}
        footer={[
          <Button key="cancel" onClick={closeModal}>
            Cancel
          </Button>,
          <Button
            key="submit"
            type="primary"
            loading={spin}
            onClick={handleAddition}
          >
            Add Member
          </Button>,
        ]}
        className="responsive-modal"
      >
        <div className="mb-4">
          <label className="block text-gray-700 text-sm font-bold mb-2" htmlFor="email">
            Email
          </label>
          <Input
            type="email"
            placeholder="Enter member's email"
            value={Email}
            onChange={(e) => setEmail(e.target.value)}
          />
        </div>
      </Modal>
      <style jsx global>{`
      .container {
        max-width: 1200px;
      }
      .responsive-modal .ant-modal {
        max-width: 90%;
        width: auto;
      }
      @media (max-width: 768px) {
        .responsive-modal .ant-modal {
          width: 100%;
        }
      }
    `}</style>
    </CustomLayout>
  );
}
