import React, { useState } from 'react';
import { useNavigate, useLocation } from "react-router-dom";
import { Form, message, Input, Space, Button } from 'antd';
import { LockOutlined, MailOutlined } from '@ant-design/icons';
import bg from '../../../src/assets/svg/bg.png';
import axios from 'axios';
import Warning from '../components/Warning';
import config from '../../config';

function PasswordReset() {
  const navigate = useNavigate();
  const location = useLocation();
  const [passwordMismatch, setPasswordMismatch] = useState(false);
  const [email, setEmail] = useState('');
  const [password, setPassword] = useState('');
  const [spin, setSpin] = useState(false);
  const [failed, setFailed] = useState(false);
  const [failedMessage, setFailedMessage] = useState('');
  const [otpSent, setOtpSent] = useState(false);
  const [otp, setOtp] = useState('');
  const [emailError, setEmailError] = useState(false);
  const [confirmPassword, setPasswordConfirm] = useState('');
  const [generatedOtp, setGeneratedOtp] = useState('');
  const [otpVerified, setOtpVerified] = useState(false);

  const onChange = (text) => {
    if (text === generatedOtp) {
      setOtpVerified(true);
      message.success('OTP verified successfully');
    } else {
      setOtpVerified(false);
    }
  };

  const sharedProps = {
    onChange,
  };

  const generateRandomNumberString = (length) => {
    let result = '';
    for (let i = 0; i < length; i++) {
      const randomDigit = Math.floor(Math.random() * 10);
      result += randomDigit;
    }
    return result;
  };

  const handlePasswordChange = (e) => {
    setPassword(e.target.value);
    setPasswordMismatch(e.target.value !== confirmPassword);
  };

  const handleConfirmPasswordChange = (e) => {
    setPasswordConfirm(e.target.value);
    setPasswordMismatch(e.target.value !== password);
  };

  const handleSendOtp = async () => {
    const otp = generateRandomNumberString(6);
    setGeneratedOtp(otp);

    try {
      const response = await axios.post(`${config.apiUrl}user/send-otp`, { "email": email, "otp": otp,"type":"reset" });

      if (response.status === 200) {
        message.success('OTP sent successfully');

        setOtpSent(true);
        setOtpVerified(false)
      } else {
        message.error('Failed to send OTP');
      }
    } catch (error) {
      message.error('Error sending OTP');
    }
  };

  const handlePasswordReset = async () => {
    try {
      const response = await axios.post(`${config.apiUrl}user/passwordreset`, { email, "new_password":password });

      if (response.status === 200) {
        message.success('Password reset successfully');
        navigate('/auth/login');
      } else {
        message.error('Failed to reset password');
      }
    } catch (error) {
      message.error('Error resetting password');
    }
  };

  return (
    <div className="bg-[#F3E6FF] flex h-screen flex-1">
      <div className='md:block'>
        <img className='h-screen' alt='background' src={bg} />
      </div>

      <div className='flex flex-col items-center flex-1 p-6 mt-32'>
        {failed && <Warning message={failedMessage} />}

        <div>
          <img alt='jaweb-bg' className='h-[59.69px] w-[235.62px]' src='https://jawebstorage.us-iad-1.linodeobjects.com/images/Jaweb color logo.png' />
        </div>

        <div>
          <span className='font-bold text-lg'>PASSWORD RESET</span>
        </div>

        <Form
          name="password_reset"
          layout="vertical"
          style={{ width: '50%' }}
          onFinish={handleSendOtp}
        >
          <Form.Item
            name="email"
            label="Email"
            rules={[
              {
                required: true,
                message: 'Please enter your email',
              },
              {
                type: 'email',
                message: 'Please enter a valid email',
              },
            ]}
          >
            <Space.Compact style={{ width: '100%' }}>
              <Input
                style={{ width: '100%' }}
                placeholder="Email"
                value={email}
                disable={otpVerified}
                onChange={(e) => setEmail(e.target.value)}
                prefix={<MailOutlined />}
              />
              <Button style={{background:'#7F28F8',color:'white'}} htmlType="submit">Send Code</Button>
            </Space.Compact>
          </Form.Item>

          {otpSent && (
            <Form.Item
              name="otp"
              label="Enter OTP"
              rules={[
                {
                  required: true,
                  message: 'Please enter the OTP',
                },
              ]}
            >
                <Input.OTP    value={otp} disabled={otpVerified}   {...sharedProps}/>        
            </Form.Item>
          )}

          {emailError && <small id="emailError" className="text-red-500">Email is already connected to another account</small>}

          {otpVerified && (
            <>
              <Form.Item
                name="password"
                label="Password"
                rules={[
                  {
                    required: true,
                    message: 'Please enter your password',
                  },
                ]}
              >
                <Input.Password
                  style={{ width: '100%' }}
                  placeholder="Password"
                  value={password}
                  onChange={handlePasswordChange}
                  prefix={<LockOutlined />}
                  type='password'
                />
              </Form.Item>

              <Form.Item
                name="confirm_password"
                label="Re-enter Password"
                rules={[
                  {
                    required: true,
                    message: 'Please re-enter your password',
                  },
                  {
                    validator: (_, value) =>
                      value === password ? Promise.resolve() : Promise.reject(new Error('Passwords do not match')),
                  },
                ]}
              >
                <Input.Password
                  style={{ width: '100%' }}
                  placeholder="Re-enter Password"
                  value={confirmPassword}
                  onChange={handleConfirmPasswordChange}
                  prefix={<LockOutlined />}
                  type='password'
                />
              </Form.Item>

              <Button loading={true} style={{background:'#7F28F8',color:'white'}} type="primary" onClick={handlePasswordReset} disabled={passwordMismatch}>
                Reset Password
              </Button>
            </>
          )}
        </Form>

        <div className="mb-2 relative w-[50%]">
          <div className='flex items-start'>
            <p className="mt-1 text-xs font-light text-center text-gray-700">
              Remembered?{' '}
              <a className="font-medium text-purple-600 hover:underline" href="/auth/login">
                Login
              </a>
            </p>
          </div>
        </div>
      </div>
    </div>
  );
}

export default PasswordReset;
