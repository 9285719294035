import React, { useState, useEffect } from 'react';
import colorJawebLogo from '../../../assets/Onboarding/colorLogo.png';
import shopifyLogo from '../../../assets/Onboarding/shopifyLogo.svg';
import { EyeOutlined, EyeInvisibleOutlined } from '@ant-design/icons';
import './PasswordLogin.css'
import { useAuth } from '../../../hooks/AuthProvider';
import leftArrow from '../../../assets/arrows/LeftArrowIcon.svg'

const PasswordLoginComponent = ({ username, password, setPassword, HandleLoginWithShopify, setIsEmailScreen }) => {
  const [isPasswordVisible, setIsPasswordVisible] = useState(false);
  const [errorMessage, setErrorMessage] = useState('');
  const [loader, setLoader] = useState(false);

  const { loginAction, error } = useAuth();

  const handleLoginBtn = async () => {
    setLoader(true);
    try {
      await loginAction({ username, password });
    } catch (error) {
      console.log(error);
    } finally {
      setLoader(false);
    }
  };  

  const handleLogin = () => {
    setErrorMessage('');

    if (!password) {
      setErrorMessage('Password is required.');
      return;
    }
    if (password.length < 6) {
      setErrorMessage('Password must be at least 6 characters long.');
      return;
    }

    handleLoginBtn();
  };


  return (
    <div className="login-container">
      <img src={leftArrow} style={{height:'50px', width:'30px', marginBottom:'50px', cursor:'pointer'}} onClick={()=>setIsEmailScreen(true)}/>
      <img src={colorJawebLogo} alt="Jaweb logo" className="login-logo" />
      <h1 className="login-heading">What’s your password?</h1>
      <p className="login-subheading">Please provide your password to authenticate that it’s you</p>
      <div style={{ margin: '40px 0px' }}>
        <label className="login-input-label">Password*</label>
        <div className="login-input-container">
          <input
            type={isPasswordVisible ? 'text' : 'password'}
            placeholder="Enter your password"
            className={`login-input ${errorMessage ? 'error' : ''}`}
            value={password}
            onChange={(e) => setPassword(e.target.value)}
          />
          <div className="login-password-toggle" onClick={() => setIsPasswordVisible(!isPasswordVisible)}>
            {isPasswordVisible ? <EyeOutlined size={20} /> : <EyeInvisibleOutlined size={20} />}
          </div>
        </div>
        {errorMessage && <div className="error-message">{errorMessage}</div>}
        <div className="login-forgot-password">
          <a href="/auth/passwordReset" className="login-signup-link">Forgot Password?</a>
        </div>
      </div>
      <div style={{ margin: '40px 0px' }}>
        <button className="login-button" onClick={handleLogin} disabled={loader}>
          Login    
        </button>
      </div>
      <p className="login-signup-text">
        Don't have an account? <a href="/auth/payment" className="login-signup-link">Sign Up</a>
      </p>
      <button className="login-shopify-button" onClick={()=>HandleLoginWithShopify()}>
        Login with
        <img src={shopifyLogo} alt="Shopify icon" className="login-shopify-icon" />
      </button>
    </div>
  );
};

export default PasswordLoginComponent;
