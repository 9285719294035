import React, { useState, useEffect } from "react";
import { useTranslation } from "react-i18next";
import { DownOutlined } from '@ant-design/icons';
import './Css/LanguageDropdown.css';

const LanguageSelector = ({ setSelectedLang }) => {
  const [isOpen, setIsOpen] = useState(false);
  const { i18n } = useTranslation();
  
  const languages = [
    { code: 'en', name: 'English' },
    { code: 'ar', name: 'Arabic' },
    { code: 'es', name: 'Spanish' },
    { code: 'fr', name: 'French' },
    { code: 'de', name: 'German' },
    { code: 'it', name: 'Italian' },
  ];

  const changeLanguage = (lng) => {
    i18n.changeLanguage(lng);
    setSelectedLang(lng);
    sessionStorage.setItem('selectedLang', lng);
    setIsOpen(false);
  };

  useEffect(() => {
    document.body.dir = i18n.dir();
  }, [i18n, i18n.language]);

  const getCurrentLanguageName = () => {
    const currentLang = languages.find(
      lang => lang.code === localStorage.getItem('i18nextLng')
    );
    return currentLang?.name || 'English';
  };

  return (
    <div className="language-dropdown-relative language-dropdown-w-32">
      <button
        onClick={() => setIsOpen(!isOpen)}
        className="language-dropdown-button"
        aria-haspopup="true"
        aria-expanded={isOpen}
      >
        <span>{getCurrentLanguageName()}</span>
        <DownOutlined
          className={`language-dropdown-icon ${isOpen ? 'language-dropdown-rotate-180' : ''}`}
        />
      </button>

      {isOpen && (
        <div 
          className="language-dropdown-menu"
          role="menu"
        >
          {languages.map((language) => (
            <button
              key={language.code}
              onClick={() => changeLanguage(language.code)}
              className={`language-dropdown-menu-item ${language.code === localStorage.getItem('i18nextLng') ? 'language-dropdown-selected' : ''}`}
              role="menuitem"

            >
              {language.name}
            </button>
          ))}
        </div>
      )}
    </div>
  );
};

export default LanguageSelector;
