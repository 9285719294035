import React, { Component } from 'react';
import { Avatar, Drawer, Divider } from 'antd';
import { 
    MobileOutlined, 
    MailOutlined, 
    UserOutlined, 
    CompassOutlined,
    CalendarOutlined,
    GlobalOutlined
} from '@ant-design/icons';
import dayjs from 'dayjs';

export class UserView extends Component {
    formatDate = (date) => {
        return date ? dayjs(date).format('MMMM D, YYYY, h:mm A') : 'N/A';
    };

    render() {
        const { data, visible, close } = this.props;

        return (
            <Drawer
                width={350}
                placement="right"
                onClose={close}
                closable={false}
                open={visible}
            >
                {/* Profile Section */}
                <div className="text-center mt-4">
                    <Avatar 
                        size={90} 
                        src={data?.avatar || "data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAAOEAAADhCAMAAAAJbSJIAAAAclBMVEX///8AAAAvLy9xcXHi4uJhYWH7+/vx8fHc3NzCwsL09PTNzc3m5uY6OjrKysqHh4erq6sZGRlXV1fU1NSxsbG6urqlpaXr6+tcXFwdHR0ODg6QkJBGRkaDg4OcnJxra2t6enozMzOWlpYnJydCQkJQUFBU9OwrAAAFXUlEQVR4nO2cbVviOhCGCWKxFZD3IrACov//L54F5NimM2kSITNe+9wf17Sb52oyrwmdDgAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAA"} 
                    />
                    <h2 className="mt-3 mb-0">{data?.name || 'Guest'}</h2>
                    <p className="text-muted">{data?.role || 'N/A'}</p>
                </div>
                
                <Divider dashed />

                {/* Account Details Section */}
                <div className="px-3">
                    <h6 className="text-muted text-uppercase mb-3">Account Details</h6>
                    <p>
                        <UserOutlined />
                        <span className="ml-3 text-dark">ID: {data?.id || 'N/A'}</span>
                    </p>
                    <p>
                        <CalendarOutlined />
                        <span className="ml-3 text-dark">
                            Opened: {this.formatDate(data?.date)}
                        </span>
                    </p>
                    <p>
                        <GlobalOutlined />
                        <span className="ml-3 text-dark">{data?.country || '-'}</span>
                    </p>
                </div>

                <Divider dashed />

                {/* Contact Details Section */}
                <div className="px-3 mt-4">
                    <h6 className="text-muted text-uppercase mb-3">Contact</h6>
                    <p>
                        <MobileOutlined />
                        <span className="ml-3 text-dark">{data?.phone || 'N/A'}</span>
                    </p>
                    <p>
                        <MailOutlined />
                        <span className="ml-3 text-dark">{data?.mail || 'N/A'}</span>
                    </p>
                </div>
            </Drawer>
        );
    }
}

export default UserView;
